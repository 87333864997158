/**
 * @author mrunalmane
 * Created on 22 July, 2024
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { withReducer } from "store/withReducer";

// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GuideErrorsDetailState } from "store/Reducers/GuideInsights/guideErrorsDetail";
import guideErrorsDetailReducer from "store/Reducers/GuideInsights/guideErrorsDetail";
import * as guideErrorsDetailAction from "store/Actions/index";
import { CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";



import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import EventSource from 'components/EventSource/EventSource';


import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import CommonTable from "components/Table/CommonTable";
import Maintenance from "components/Maintenance/Maintenance";
import { useErrorBoundary } from 'react-error-boundary';

import SectionFilters from "components/Layouts/SectionFilters";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import { CommonUtils } from "utils/CommonUtils";
import qs from 'querystring';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import { ChartColors } from "utils/GlobalConstants";
import Loader from "components/Loader/Loader";
import { getCallExportExcel } from "utils/ApiCallActions";
import CommonModal from "components/CommonModal/CommonModal";
import "./GuideErrorsDetails.scss";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";

interface CurrentState {
    guideErrorsDetail: GuideErrorsDetailState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}

const GuideErrorsDetail: React.FC = (props: any) => {
    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const { guide_id } = useParams();
    const guideId = guide_id;

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const [guideTitle, setGuideTitle] = useState('');

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0],
        showMaintenance_flag: false
    });

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: ""
    });

    const [request_uuid, setUuid] = useState({
        value: ''
    })


    const [guideErrorsChart, setGuideErrorsChart] = useState({
        chartData: [],
        chartDataFetched: false,
        api: [],
        statsDataFetched: false
    })

    const [guideErrorsSummaryTable, setGuideErrorsSummaryTable] = useState({
        guideErrorsFetched: false,
        searchQuery: '',
        sortKey: 'step_title',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'asc'
        },
        sortValue: 'asc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true
    });

    const [parameter, setParameter] = useState({
        paramter: "",
        // source: "",
    });

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    let linechart_height = 400;

    const [errorModal, setErrorModal] = useState({
        data: [],
        modalOpen: false,
        dataFetched: false,
        stepID: 0,
        errorMsg: '',
        type: ''
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(1, 1, 1, true, false);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.guideErrors;
        if (insightsLbls) {
            document.title = insightsLbls.guideErrors;
        }
    }, [insightsLbls])

    useEffect(() => {
        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceGuideErrors,
            };
        });
    }, []);


    // UseEffect for date, app, filters change
    useEffect(() => {
        try {
            window.scrollTo(0, 0);

            let queryParams = {
                app_id: fetched_details.commonComponentData.appsData.appId,
                app_code: fetched_details.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
                event_source: fetched_details?.commonComponentData?.eventSource.eventSourceGuideErrors
            }

            let component_path: any[] = location.pathname.split('/');
            component_path = component_path.filter(function (el) {
                return el != '';
            });

            navigate({
                pathname: "/guide-insights/guide-errors-detail/" + component_path[component_path.length - 1] + '/',
                search: qs.stringify(queryParams),
            });

            if (fetched_details?.commonComponentData.datesData.source === 'component' &&
                fetched_details?.commonComponentData.datesData?.url.includes('guide-errors-detail')) {
                // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)



                setFilterValues((prevState: any) => {
                    return {
                        ...prevState,
                        eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceTaskList,
                    };
                });

                enableExportPdf(0, false);
                enableExportPdf(1, false);


                setGuideErrorsChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartData: [],
                        chartDataFetchedDataFetched: false
                    };
                });


                setGuideErrorsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideErrorsFetched: false,
                        firstLoad: true,
                        searchQuery: '',
                        sortKey: 'step_title',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'asc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'asc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                    }
                })
                getGuideErrorChartData();
                getGuideErrorsSummary();


            }
        } catch (error) {
            showBoundary(error)
        }
    }, [
        fetched_details?.commonComponentData?.eventSource.eventSourceGuideErrors,
        fetched_details.commonComponentData.datesData])
    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const getGuideErrorChartData = (apiErrorFlag?: string) => {
        try {
            let params: any;
            params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_id": fetched_details?.commonComponentData.appsData.appId,
                "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceGuideErrors,
                'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                'guide_id': guideId,
                'filter': fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',

            };

            guideErrorsDetailAction.getGuideErrorsDetailData(
                params,
                "GUIDE_ERRORS_CHART_DATA",
                ActionTypes.SET_GUIDE_ERRORS_CHART_DATA,
                apiErrorFlag ?? ''
            ).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }
    }

    const getGuideErrorsSummary = (apiErrorFlag?: string) => {
        try {
            let params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_id": fetched_details?.commonComponentData.appsData.appId,
                'sort_by': guideErrorsSummaryTable.sortKey,
                'order': guideErrorsSummaryTable.sortValue,
                'search_text': encodeURIComponent(guideErrorsSummaryTable.searchQuery),
                'page_index': guideErrorsSummaryTable.page,
                'page_size': guideErrorsSummaryTable.limit,
                "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceGuideErrors,
                'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                'guide_id': guideId,
                'filter': fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',

            };

            guideErrorsDetailAction.getGuideErrorsDetailData(
                params,
                "GUIDE_ERRORS_DETAIL_SUMMARY",
                ActionTypes.SET_GUIDE_ERRORS_DETAIL_SUMMARY,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));

        } catch (error) {
            showBoundary(error)
        }
    }


    const exportExcel = (reqUUID, data: any) => {
        try {
            let params: any;

            params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                'sort_by': guideErrorsSummaryTable.sortKey,
                'order': guideErrorsSummaryTable.sortValue,
                'search_text': encodeURIComponent(guideErrorsSummaryTable.searchQuery),
                'page_index': guideErrorsSummaryTable.page,
                'page_size': guideErrorsSummaryTable.limit,
                'request_uuid': reqUUID,
                "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceGuideErrors,
                'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            }

            getCallExportExcel(params, 'GUIDE_ERRORS_SUMMARY', data);
        } catch (error) {
            showBoundary(error)
        }
    }

    const getErrorDetailsData = (stepId: number, apiErrorFlag?: string,) => {
            const params = {
                "time_zone": timeZone,
                "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceGuideErrors,
                'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
                "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
                'guide_id': guideId,
                'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
                'app_id': fetched_details?.commonComponentData.appsData.appId,
                'step_id': stepId
            };

            guideErrorsDetailAction.getGuideErrorsDetailData(
                params,
                "GUIDE_ANALYTICS_ERROR_DETAILS",
                ActionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS,
                ''
            ).then((res: any) => dispatch(res));
    }

    //  Chart Data
    useEffect(() => {
        try {
            if (
                fetched_details.guideErrorsDetail.guideErrorsChartData.data !== undefined &&
                fetched_details.guideErrorsDetail.guideErrorsChartData.result === "success"
            ) {
                setGuideErrorsChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                        chartData: fetched_details.guideErrorsDetail.guideErrorsChartData.data.graphData
                    }
                })

                apiCatchError(0, 0);
                enableExportPdf(0, true);
            } else if (fetched_details.guideErrorsDetail.guideErrorsChartData.result === 'retry') {
                getGuideErrorChartData('retry');
            } else if (fetched_details.guideErrorsDetail.guideErrorsChartData.result === "error") {
                setGuideErrorsChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                        chartData: []
                    }
                })
                apiCatchError(0, 1);
                enableExportPdf(0, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideErrorsDetail.guideErrorsChartData.data]);


    // Summary Data
    useEffect(() => {
        try {
            if (
                fetched_details?.guideErrorsDetail?.guideErrorsSummary?.data !== undefined &&
                fetched_details?.guideErrorsDetail?.guideErrorsSummary?.result === "success"
            ) {


                setGuideErrorsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideErrorsFetched: true,
                    }
                })

                setGuideTitle(fetched_details?.guideErrorsDetail?.guideErrorsSummary?.data.guideTitle);

                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details?.guideErrorsDetail?.guideErrorsSummary?.result === 'retry') {
                getGuideErrorsSummary('retry');
            } else if (fetched_details?.guideErrorsDetail?.guideErrorsSummary?.result === "error") {
                setGuideErrorsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideErrorsFetched: true,
                    }
                })
                apiCatchError(1, 1);
                enableExportPdf(1, false);

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.guideErrorsDetail?.guideErrorsSummary?.data]);


    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    
    useEffect(() => {
        try {
            if (
                fetched_details?.guideErrorsDetail?.guideErrorData?.data !== undefined &&
                fetched_details?.guideErrorsDetail?.guideErrorData?.result === "success"
            ) {
                setErrorModal((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        data: fetched_details?.guideErrorsDetail?.guideErrorData?.data?.stepsData
                    }
                });

                apiCatchError(2, 0);

            } else if (fetched_details?.guideErrorsDetail?.guideErrorData?.result === "retry") {
                getErrorDetailsData(errorModal.stepID, 'retry') 
            } else if (fetched_details?.guideErrorsDetail?.guideErrorData?.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details?.guideErrorsDetail?.guideErrorData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setErrorModal((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        errorMsg: errorMsg,
                        data: []
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.guideErrorsDetail?.guideErrorData])


    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            if (fetched_details?.commonComponentData?.datesData?.url?.includes('guide-errors-detail') && fetched_details?.guideErrorsDetail?.guideErrorsSummary?.data) {
                enableExportPdf(1, false);
                getGuideErrorsSummary();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideErrorsSummaryTable.sortKey,
    guideErrorsSummaryTable.sortValue,
    guideErrorsSummaryTable.page,
    guideErrorsSummaryTable.searchQuery,
    guideErrorsSummaryTable.limit]);

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : 0
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setGuideErrorsChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartData: [],
                        chartDataFetched: false
                    };
                });
                getGuideErrorChartData();
            } else if (optn === 1) {
                setGuideErrorsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideErrorsFetched: false,
                        searchQuery: '',
                        sortKey: 'step_title',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'asc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'asc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true
                    };
                });

                getGuideErrorsSummary();
            }
        } catch (error) {
            showBoundary(error)
        }
    };


    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "sortColumn":
                    setGuideErrorsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setGuideErrorsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setGuideErrorsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":
                    setGuideErrorsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(request_uuid.value, data)
                    break;

                case "errorDetails":
                    setErrorModal((prevState: any) => {
                        return {
                            ...prevState,
                            modalOpen: true,
                            dataFetched: false,
                            stepID: data.stepID,
                        }
                    })
                    getErrorDetailsData(data.stepID)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideErrorsSummaryTable]);

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData?.datesData.fdate,
            tdate: fetched_details?.commonComponentData?.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource?.eventSourceGuideErrors,
            state: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
        }

        navigate({
            pathname: `/guide-insights/guide-errors/`,
            search: decodeURIComponent(qs.stringify(queryParams))
        })
    }

    const closeErrorDataModal = () => {
        try {
            setErrorModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    return (
        <section className="demo guideErrorsDetail width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['guideErrors']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['guideErrorsDetails']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'}></Datepicker>
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={guideTitle} svgImage={"guideErrors"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Guide Errors Details"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Guide Analytics Detail"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={insightsLbls.guideStepErrors}>

                {/* <div className="displayInlineFlex">
                    <span className="marginLeft-10 font12">{insightsLbls['totalSteps']}:- {guideAnalyticsDetailSummaryTable.tableTotal_count}</span>
                </div> */}
            </UserInfoSection>

            <div id="SearchSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.guideStepErrors} refreshClass={""}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls['refresh']}
                    //         onClick={() => refreshSections(0)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.guideStepErrorsActivity,
                                    "childern": [
                                        {
                                            "key": insightsLbls.guideErrorOccurrences + ":",
                                            "value": insightsLbls.guideErrorOccurrencesValue
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {guideErrorsChart.chartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    guideErrorsChart.chartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <LineChart
                                            chartEntities={['guide_step_errors']}
                                            optionEntities={[
                                                {
                                                    key: insightsLbls.guideError,
                                                    color: ChartColors.color4,
                                                    associatedDataPoint: "guide_step_errors",
                                                    area: true
                                                }
                                            ]}
                                            chartData={fetched_details.guideErrorsDetail.guideErrorsChartData?.data?.graphData}
                                            value_type={"count"}
                                            height={linechart_height}
                                            margin={lineChartMargin}
                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                            refId="guideStepErrors">
                                        </LineChart>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent minHeight401">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>



                {
                    // <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls['refresh']}
                    //         onClick={() => refreshSections(1)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                        <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.stepErrorSummary,
                                    "tooltipText": insightsLbls.stepErrorSummary,
                                    "childern": [
                                        {
                                            "key": insightsLbls.stepTitle + ":",
                                            "value": insightsLbls.nameOfSTep
                                        },
                                        {
                                            "key": insightsLbls.latestStartTimestamp + ":",
                                            "value": insightsLbls.latestStartTimestampValue
                                        },
                                        {
                                            "key": insightsLbls.stepErrorCounts + ":",
                                            "value": insightsLbls.stepErrorCountsValue
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                guideErrorsSummaryTable.guideErrorsFetched ? (
                                    errorCount.errorCount[1] === 0 ? (
                                        <div className="commonSummaryTable guideErrorsDetailTable">
                                            <CommonTable tableId="1"
                                                data={fetched_details.guideErrorsDetail.guideErrorsSummary.data}
                                                currentPage={guideErrorsSummaryTable.page}
                                                barColors=""
                                                linkPresent={false}
                                                showExport={false}
                                                showSearch={true}
                                                showEntries={true}
                                                statusFailAction={'guideErrorsDetail'}
                                                defaultSort={guideErrorsSummaryTable.defaultSort}
                                                tablePagination={guideErrorsSummaryTable.tablePagination}
                                                tableEvent={handleTableEvents}
                                                firstTimeloadFlag={fetched_details.guideErrorsDetail.guideErrorsSummary.firstTimeload}
                                                modalTable={false}
                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[1] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>

                    </CardContainer>
                </div>

            </div>


            <CommonModal
                modalState={errorModal.modalOpen}
                dispatchModalState={closeErrorDataModal}
                modalTitle={'Step Error Details'}
                size={'md'}
                modalDialogClass={''}>

                {errorModal.dataFetched ? (
                    errorCount.errorCount[2] === 0 ? (
                        errorModal.data.length === 0 ? (
                            <div className="noDataText-font textCenter-absolute">
                                {insightsLbls.noRecordFound}
                            </div>
                        ) : (
                            <div className="stepErrorDetails">
                                {errorModal.data.map((item: any) => {
                                    return <div key={item.playSequenceID} className="padding15 errorCard">
                                        <p className="font14 font600 marginBottom-0">{item.errorReason}</p>
                                        <p className="font14 marginBottom-0">{item.timestamp}</p>
                                    </div>
                                })}
                            </div>
                        )
                    ) : (
                        <h5 className="errorCss card-heading font-weight-400 text-center">
                            {errorModal.errorMsg}
                        </h5>
                    )
                ) : (
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                        <div className="displayFlex alignCenter margin-0-auto">
                            <Loader></Loader>
                        </div>
                    </div>
                )}

            </CommonModal>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}


export default withRouter(withReducer("guideErrorsDetail", guideErrorsDetailReducer)(GuideErrorsDetail));


