// @author: Mrunal Mane
// Modified on: 2024-05-23
// Description: Export PDF file name added


// @author: Mrunal Mane
// Modified on: 2024-06-13
// Description: COnditions added on initial load to avoid multiple api calls

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
// SCSS Imports
import "./TaskListDetail.scss";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import SectionHeader from "components/Layouts/SectionHeader";
import { CommonComponentState, LanguageState } from "store/Reducers";
import { InsightLabels } from "labels";
import * as taskListActions from "store/Actions/index";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { useErrorBoundary } from 'react-error-boundary';
import SectionFilters from "components/Layouts/SectionFilters";
import Datepicker from "components/Calender/Datepicker";
import { CommonUtils } from "utils/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import CardContainer from "components/Layouts/CardContainer";
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import { ChartColors, RetryApi } from "utils/GlobalConstants";
import Loader from "components/Loader/Loader";
import taskListReducer, { TaskListState } from "store/Reducers/GuideInsights/taskList";
import { withReducer } from "store/withReducer";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CommonTable from "components/Table/CommonTable";
import qs from "querystring";
import { getCall, getCallExportExcel } from "utils/ApiCallActions";
import CommonModal from "components/CommonModal/CommonModal";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import { v4 as uuidv4 } from 'uuid';
import UserInfoSection from "components/Layouts/UserInfoSection";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

interface CurrentState {
	taskList: TaskListState,
	commonComponentData: CommonComponentState,
	languageData: LanguageState
}

const TaskListDetail: React.FC = (props: any) => {
	const componentRef = useRef() as React.RefObject<HTMLDivElement>;
	const { showBoundary } = useErrorBoundary();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
	let fetched_details = useSelector((state: CurrentState) => state);

	const dateZone: any = new Date().toString();
	const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
	const { task_list_id } = useParams();
	let isPiTracking = localStorage.getItem("PI-TRACKING") == '1';

	let lineChartMargin = {
		top: "50",
		right: "50",
		bottom: "50",
		left: "75",
	}
	let linechart_height = 400;

	const [taskListDetailGraph, setTaskListDetailGraph] = useState({
		taskListDetailGraphFetched: false,
		isError: false
	})

	const [taskListDetailGuidePlay, setTaskListDetailGuidePlay] = useState({
		taskListDetailGuidePlayFetched: false,
		isError: false,
		searchQuery: '',
		sortKey: 'guide_plays',
		filterLabels: [],
		page: 1,
		showExport: true,
		defaultSort: {
			'sortColumn_index': 1,
			'sortValue': 'desc'
		},
		sortValue: 'desc',
		limit: 5,
		tablePagination: {
			offset: 0,
			data: [],
			perPage: 5,
			currentPage: 0
		},
		firstLoad: true
	});

	const [taskListDetailUserEngagementSummary, setTaskListDetailUserEngagementSummary] = useState({
		taskListDetailUserEngagementSummaryFetched: false,
		isError: false,
		searchQuery: '',
		sortKey: 'task_list_plays',
		filterLabels: [],
		page: 1,
		showExport: true,
		defaultSort: {
			'sortColumn_index': 2,
			'sortValue': 'desc'
		},
		sortValue: 'desc',
		limit: 5,
		tablePagination: {
			offset: 0,
			data: [],
			perPage: 5,
			currentPage: 0
		},
		firstLoad: true
	});

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [taskListDetailUserGuidePlay, setTaskListDetailUserGuidePlay] = useState({
		data: [],
		userDetailFetched: false,
		isError: false,
		errorMsg: ""
	});

	const [request_uuid, setUuid] = useState({
		value: ''
	})

	const getTaskListDetailGraph = (apiErrorFlag?: string) => {
		try {
			let params = {
				start_date: fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
				end_date: fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
				time_zone: timeZone,
				app_code: fetched_details?.commonComponentData.appsData.appCode,
				task_list_id: task_list_id
			};
			taskListActions.getTaskListDetailData(
				params,
				"TASK_LIST_DETAIL_GRAPH",
				ActionTypes.SET_TASK_LIST_DETAIL_GRAPH,
				apiErrorFlag ?? '',
			).then((res: any) => dispatch(res));
		} catch (error) {
			showBoundary(error)
		}
	}

	const getTaskListDetailGuidePlay = (apiErrorFlag?: string) => {
		try {
			let params = {
				start_date: fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
				end_date: fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
				time_zone: timeZone,
				app_code: fetched_details?.commonComponentData.appsData.appCode,
				sort_by: taskListDetailGuidePlay.sortKey,
				order: taskListDetailGuidePlay.sortValue,
				search_text: encodeURIComponent(taskListDetailGuidePlay.searchQuery),
				page_index: taskListDetailGuidePlay.page,
				page_size: taskListDetailGuidePlay.limit,
				task_list_id: task_list_id
			};
			taskListActions.getTaskListDetailData(
				params,
				"TASK_LIST_DETAIL_GUIDE_PLAY",
				ActionTypes.SET_TASK_LIST_DETAIL_GUIDE_PLAY,
				apiErrorFlag ?? '',
			).then((res: any) => dispatch(res));
		} catch (error) {
			showBoundary(error)
		}
	}

	const getTaskListDetailUserEngagementSummary = (apiErrorFlag?: string) => {
		try {
			let params = {
				start_date: fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
				end_date: fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
				time_zone: timeZone,
				app_code: fetched_details?.commonComponentData.appsData.appCode,
				sort_by: taskListDetailUserEngagementSummary.sortKey,
				order: taskListDetailUserEngagementSummary.sortValue,
				search_text: encodeURIComponent(taskListDetailUserEngagementSummary.searchQuery),
				page_index: taskListDetailUserEngagementSummary.page,
				page_size: taskListDetailUserEngagementSummary.limit,
				task_list_id: task_list_id
			};
			taskListActions.getTaskListDetailData(
				params,
				"TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY",
				ActionTypes.SET_TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY,
				apiErrorFlag ?? '',
			).then((res: any) => dispatch(res));
		} catch (error) {
			showBoundary(error)
		}
	}

	const getUserData = (userId, apiErrorFlag) => {
		try {
			let params = {
				start_date: fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
				end_date: fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
				time_zone: timeZone,
				app_code: fetched_details?.commonComponentData.appsData.appCode,
				task_list_id: task_list_id,
				user_id: userId
			};
			getCall(params, 'TASK_LIST_DETAIL_USER_GUIDE_PLAY', apiErrorFlag ?? '').then((data: any) => {
				if (data?.result === 'success') {
					setTaskListDetailUserGuidePlay((prevState) => {
						return {
							...prevState,
							data: data.data,
							userDetailFetched: true,
							isError: false,
							errorMsg: ""
						}
					})
				} else if (data.result === 'retry') {
					setTimeout(() => {
						getUserData(userId, 'retry')
					}, RetryApi.TIMEOUT)
				}
				else if (data?.result === "error") {
					setTaskListDetailUserGuidePlay((prevState) => {
						return {
							...prevState,
							userDetailFetched: true,
							isError: true,
							errorMsg: data.message
						}
					})
				}
			})
		} catch (error) {
			showBoundary(error)
		}
	}

	useEffect(() => {
		let queryParams: any = {
			app_id: fetched_details?.commonComponentData.appsData.appId,
			app_code: fetched_details?.commonComponentData.appsData.appCode,
			fdate: fetched_details?.commonComponentData?.datesData?.fdate,
			tdate: fetched_details?.commonComponentData?.datesData?.realTime_tdate,
		}

		let component_path: any[] = location.pathname.split('/');
		component_path = component_path.filter(function (el) {
			return el != '';
		});

		navigate({
			pathname: "/guide-insights/task-list-detail/" + component_path[component_path.length - 1] + '/',
			search: qs.stringify(queryParams),
		});

		if (fetched_details?.commonComponentData?.datesData.url?.includes('task-list-detail')) {

			const reqUUID = uuidv4();
			getTaskListDetailGraph(reqUUID);
			getTaskListDetailGuidePlay(reqUUID);
			if (isPiTracking) {
				getTaskListDetailUserEngagementSummary(reqUUID);
			}

			setUuid((prevState: any) => {
				return {
					...prevState,
					value: reqUUID
				}
			})
		}


	}, [fetched_details.commonComponentData.datesData]);

	useEffect(() => {
		if (fetched_details.languageData.languageData) {
			setInsightsLbls(fetched_details.languageData.languageData);
			window.scrollTo(0, 0);
			CommonUtils.LeftPanelHighlight(1, 4, 0, true, false);
		}
	}, [fetched_details.languageData.languageData])

	useEffect(() => {
		try {
			let iterableObj = fetched_details?.taskList?.taskListDetailGraphData;
			if (iterableObj && iterableObj.result === "success") {
				let data = iterableObj.data;
				if (data) {
					setTaskListDetailGraph((prevState: any) => {
						return {
							...prevState,
							taskListDetailGraphFetched: true,
							isError: false
						};
					});
				}
			} else if (iterableObj.result === "retry") {
				getTaskListDetailGraph('retry');
			} else if (iterableObj.result === "error") {
				setTaskListDetailGraph((prevState: any) => {
					return {
						...prevState,
						taskListDetailGraphFetched: true,
						isError: true
					};
				});
			}
		} catch {

		}
	}, [fetched_details?.taskList?.taskListDetailGraphData])

	useEffect(() => {
		try {
			let iterableObj = fetched_details?.taskList?.taskListDetailGuidePlayData;
			if (iterableObj && iterableObj.result === "success") {
				let data = iterableObj.data;
				if (data) {
					setTaskListDetailGuidePlay((prevState: any) => {
						return {
							...prevState,
							taskListDetailGuidePlayFetched: true,
							isError: false
						};
					});
				}
			} else if (iterableObj.result === "retry") {
				getTaskListDetailGuidePlay('retry');
			} else if (iterableObj.result === "error") {
				setTaskListDetailGuidePlay((prevState: any) => {
					return {
						...prevState,
						taskListDetailGuidePlayFetched: true,
						isError: true
					};
				});
			}
		} catch (error) {
			showBoundary(error)
		}
	}, [fetched_details?.taskList?.taskListDetailGuidePlayData])

	useEffect(() => {
		try {
			let iterableObj = fetched_details?.taskList?.taskListDetailUserEngagementSummaryData;
			if (iterableObj && iterableObj.result === "success") {
				let data = iterableObj.data;
				if (data) {
					setTaskListDetailUserEngagementSummary((prevState: any) => {
						return {
							...prevState,
							taskListDetailUserEngagementSummaryFetched: true,
							isError: false
						};
					});
				}
			} else if (iterableObj.result === "retry") {
				getTaskListDetailUserEngagementSummary('retry');
			} else if (iterableObj.result === "error") {
				setTaskListDetailUserEngagementSummary((prevState: any) => {
					return {
						...prevState,
						taskListDetailUserEngagementSummaryFetched: true,
						isError: true
					};
				});
			}
		} catch {

		}
	}, [fetched_details?.taskList?.taskListDetailUserEngagementSummaryData])

	useEffect(() => {
		try {
			if (fetched_details?.commonComponentData.datesData?.url?.includes('task-list-detail') && request_uuid.value) {
				getTaskListDetailGuidePlay();
			}
		} catch (error) {
			showBoundary(error)
		}

	}, [taskListDetailGuidePlay.sortKey,
	taskListDetailGuidePlay.sortValue,
	taskListDetailGuidePlay.defaultSort,
	taskListDetailGuidePlay.page,
	taskListDetailGuidePlay.searchQuery,
	taskListDetailGuidePlay.limit]);

	useEffect(() => {
		try {
			if (fetched_details?.commonComponentData.datesData?.url?.includes('task-list-detail') && request_uuid.value) {
				getTaskListDetailUserEngagementSummary();
			}
		} catch (error) {
			showBoundary(error)
		}

	}, [taskListDetailUserEngagementSummary.sortKey,
	taskListDetailUserEngagementSummary.sortValue,
	taskListDetailUserEngagementSummary.defaultSort,
	taskListDetailUserEngagementSummary.page,
	taskListDetailUserEngagementSummary.searchQuery,
	taskListDetailUserEngagementSummary.limit]);

	const refreshSections = (optn: number) => {
		try {
			switch (optn) {
				case 0:
					setTaskListDetailGraph((prevState) => {
						return {
							...prevState,
							taskListDetailGraphFetched: false
						}
					})
					getTaskListDetailGraph();
					break;
				case 1:
					setTaskListDetailGuidePlay((prevState) => {
						return {
							...prevState,
							taskListDetailGuidePlayFetched: false
						}
					})
					getTaskListDetailGuidePlay();
					break;
				case 2:
					setTaskListDetailUserEngagementSummary((prevState) => {
						return {
							...prevState,
							taskListDetailUserEngagementSummaryFetched: false
						}
					})
					getTaskListDetailUserEngagementSummary();
					break;
				default:
					break;
			}
		} catch (error) {
			showBoundary(error);
		}
	}

	const exportExcel = (data: any) => {
		try {
			let params = {
				start_date: fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
				end_date: fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
				time_zone: timeZone,
				app_code: fetched_details?.commonComponentData.appsData.appCode,
				sort_by: taskListDetailUserEngagementSummary.sortKey,
				order: taskListDetailUserEngagementSummary.sortValue,
				search_text: encodeURIComponent(taskListDetailUserEngagementSummary.searchQuery),
				page_index: taskListDetailUserEngagementSummary.page,
				page_size: taskListDetailUserEngagementSummary.limit,
				task_list_id: task_list_id
			};
			getCallExportExcel(params, 'TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY', data);
		} catch (error) {
			showBoundary(error)
		}

	}

	const openModal = (userId) => {
		setIsModalOpen(true);
		getUserData(userId, '');
	}

	const closeModal = () => {
		setIsModalOpen(false);
		setTaskListDetailUserGuidePlay({
			data: [],
			userDetailFetched: false,
			isError: false,
			errorMsg: ""
		});
	}

	const handleTableEvents = useCallback((option: string, data: any) => {
		try {
			switch (option) {
				case "sortColumn":
					setTaskListDetailGuidePlay((prevState) => {
						return {
							...prevState,
							sortKey: data.sortKey,
							sortValue: data.sortValue,
							page: 1,
							defaultSort: {
								sortColumn_index: data.index,
								sortValue: data.sortValue,
							},
							firstLoad: false
						}
					});

					break;

				case "changePage":
					setTaskListDetailGuidePlay((prevState) => {
						return {
							...prevState,
							page: data + 1,
							firstLoad: false
						}
					});
					break;

				case "searchTxt":
					setTaskListDetailGuidePlay((prevState) => {
						return {
							...prevState,
							page: 1,
							searchQuery: data,
							firstLoad: false
						}
					});
					break;

				case "changeEntries":
					setTaskListDetailGuidePlay((prevState: any) => {
						return {
							...prevState,
							page: 1,
							limit: data,
							tablePagination: {
								...prevState.tablePagination,
								perPage: data
							},
							firstLoad: false
						}
					});
					break;
			}
		} catch (error) {
			showBoundary(error)
		}

	}, [taskListDetailGuidePlay]);

	const handleTableEventsUserEngagement = useCallback((option: string, data: any) => {
		try {
			switch (option) {
				case "routeToDetail":
					openModal(data["userID"]);
					break;
				case "sortColumn":
					setTaskListDetailUserEngagementSummary((prevState) => {
						return {
							...prevState,
							sortKey: data.sortKey,
							sortValue: data.sortValue,
							page: 1,
							defaultSort: {
								sortColumn_index: data.index,
								sortValue: data.sortValue,
							},
							firstLoad: false
						}
					});
					break;

				case "changePage":
					setTaskListDetailUserEngagementSummary((prevState) => {
						return {
							...prevState,
							page: data + 1,
							firstLoad: false
						}
					});
					break;

				case "searchTxt":
					setTaskListDetailUserEngagementSummary((prevState) => {
						return {
							...prevState,
							page: 1,
							searchQuery: data,
							firstLoad: false
						}
					});

					break;

				case "changeEntries":
					setTaskListDetailUserEngagementSummary((prevState: any) => {
						return {
							...prevState,
							page: 1,
							limit: data,
							tablePagination: {
								...prevState.tablePagination,
								perPage: data
							},
							firstLoad: false
						}
					});
					break;

				case "exportExcel":
					exportExcel(data)
					break;
			}
		} catch (error) {
			showBoundary(error)
		}

	}, [taskListDetailUserEngagementSummary]);

	const navigateToMainPage = () => {
		let queryParams = {
			app_id: fetched_details.commonComponentData.appsData.appId,
			app_code: fetched_details.commonComponentData.appsData.appCode,
			fdate: fetched_details?.commonComponentData.datesData.fdate,
			tdate: fetched_details?.commonComponentData.datesData.tdate,
			env: fetched_details?.commonComponentData?.envFilter?.envCode,
		}

		navigate({
			pathname: `/guide-insights/task-list/`,
			search: qs.stringify(queryParams)
		})
	}

	const getDateInFormat = (date) => {
		return date ? date.replace("|", ",") : date;
	}

	const getCard = (guideTitle: any, guidePlayCompleted: any, guidePlayCompletedTime: any) => {
		return (
			<div className="inner-card">
				<div className="inner-card-title">
					{guideTitle}
				</div>
				<div className="inner-card-body">
					<span className="font600">{insightsLbls.completionStatus}:</span> {guidePlayCompleted}
					<span className="floatRight"><span className="font600">{insightsLbls.completionTime}:</span> {getDateInFormat(guidePlayCompletedTime)}</span>
				</div>
			</div>
		)
	}

	const getGuideInfoTemplate = () => {
		let template: any[] = [];
		(taskListDetailUserGuidePlay.data["guidePlays"] as []).map((guideData) => {
			template.push(getCard(guideData["guideTitle"], guideData["guidePlayCompleted"], guideData["guidePlayCompletedTime"]));
		})

		if (template.length != 0) {
			return template;
		} else {
			return <div className="noDataFound"><p>{insightsLbls.noDataFound}</p></div>
		}
	}

	const isExportPDFDisabled = () => {
		return !(taskListDetailGraph.taskListDetailGraphFetched && !taskListDetailGraph.isError &&
			taskListDetailGuidePlay.taskListDetailGuidePlayFetched && !taskListDetailGuidePlay.isError &&
			(!isPiTracking ||
				(isPiTracking && taskListDetailUserEngagementSummary.taskListDetailUserEngagementSummaryFetched && !taskListDetailUserEngagementSummary.isError)))
	}

	return (
		<section className="demo taskListDetail width100" ref={componentRef}>
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12">
					<SectionHeader>
						<li>
							<span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls.taskList}</span>
							<i className="fa fa-angle-right" aria-hidden="true"></i>
						</li>
						<li className="active">{insightsLbls.taskListDetail}</li>
					</SectionHeader>
				</div>

				<SectionFilters>
					<Datepicker source={'component'} />
				</SectionFilters>
			</div>

			{/* <SectionButtons sectionTitle={taskListDetailGuidePlay.taskListDetailGuidePlayFetched && !taskListDetailGuidePlay.isError
            && fetched_details?.taskList?.taskListDetailGuidePlayData?.data?.taskListTitle} svgImage={"guides"} className={'marginTop-50'}>
            <ExportToPdf
               componentRef={componentRef}
               source={"Task List Detail"}
               widthDividedBy={12}
               disabled={isExportPDFDisabled()}
            ></ExportToPdf>
         </SectionButtons> */}


			<div className="marginTop-60 exportBtn-div">
				<Dropdown as={ButtonGroup}>
					<Button variant="info">{insightsLbls.export}</Button>
					<Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
					<Dropdown.Menu className="super-colors">
						<Dropdown.Item eventKey="1" >
							<ExportToPdf
								componentRef={componentRef}
								source={"Task List Detail"}
								widthDividedBy={12}
								disabled={isExportPDFDisabled()}
							></ExportToPdf>
						</Dropdown.Item>

					</Dropdown.Menu>
				</Dropdown>
			</div>

			<UserInfoSection
				startDate={fetched_details?.commonComponentData?.datesData.fdate}
				endDate={fetched_details?.commonComponentData?.datesData.tdate}
				messageText={taskListDetailGuidePlay.taskListDetailGuidePlayFetched && !taskListDetailGuidePlay.isError
					&& fetched_details?.taskList?.taskListDetailGuidePlayData?.data?.taskListTitle}>
			</UserInfoSection>

			<div id="taskListDetailSection">
				{
					// <SectionRefresh sectionTitle={insightsLbls.taskListActivity} refreshClass={"marginTop-30"}>
					// 	<i className="fa fa-refresh pull-right cursorPointer row displayFlex"
					// 		aria-hidden="true"
					// 		title={insightsLbls['refresh']}
					// 		onClick={() => refreshSections(0)}></i>
					// </SectionRefresh>
				}
				<div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant">
					<CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
						div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
						div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
						<ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
							<SectionTooltip tooltipText={
								{
									"subTitle": insightsLbls.taskListActivity,
									"tooltipText": insightsLbls.taskListActivity,
									"childern": [
										{
											"key": insightsLbls.totalPlayed + ":",
											"value": insightsLbls.totalTaskListPlayedMsgTooltip
										},
										{
											"key": insightsLbls.completed + ":",
											"value": insightsLbls.completedTaskListTooptip
										}
									]
								}
							} placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
						</ContainerTooltip>
						<div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
							{taskListDetailGraph.taskListDetailGraphFetched ? (
								!taskListDetailGraph.isError ? (
									fetched_details.taskList.taskListDetailGraphData.data.graphData.length === 0 ? (
										<div className="noDataText-font textCenter-absolute">
											{insightsLbls.noRecordFound}
										</div>
									) : (
										<LineChart
											chartEntities={['task_list_plays', 'task_list_completed']}
											optionEntities={[
												{
													key: insightsLbls.totalPlayed,
													color: ChartColors.color1,
													associatedDataPoint: "task_list_plays",
													area: true
												},
												{
													key: insightsLbls.completed,
													color: ChartColors.color2,
													associatedDataPoint: "task_list_completed",
													area: true
												}
											]}
											chartData={fetched_details.taskList.taskListDetailGraphData.data.graphData}
											value_type={"count"}
											height={linechart_height}
											margin={lineChartMargin}
											yAxisLabel={insightsLbls.yAxisLabelCount}
											refId="taskList">
										</LineChart>
									)
								) : (
									<h5 className="errorCss card-heading font-weight-400 text-center">
										{insightsLbls.serviceNotAvailable}
									</h5>
								)
							) : (
								<div className="loaderHeight width100 displayFlex height100-percent minHeight401">
									<div className="displayFlex alignCenter margin-0-auto">
										<Loader></Loader>
									</div>
								</div>
							)}
						</div>
					</CardContainer>
				</div>

				{
					// <SectionRefresh sectionTitle={insightsLbls.taskListGuidePlay} refreshClass={"marginTop-30"}>
					// 	<i className="fa fa-refresh pull-right cursorPointer row displayFlex"
					// 		aria-hidden="true"
					// 		title={insightsLbls['refresh']}
					// 		onClick={() => refreshSections(1)}></i>
					// </SectionRefresh>
				}
				<div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
					<CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
						div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
						div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
						<ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
							<SectionTooltip tooltipText={
								{
									"subTitle": insightsLbls.taskListGuidePlay,
									"tooltipText": insightsLbls.taskListGuidePlay,
									"childern": [
										{
											"key": insightsLbls.guideName + ":",
											"value": insightsLbls.nameOfGuide
										},
										{
											"key": insightsLbls.guidePlays + ":",
											"value": insightsLbls.guidePlayTaskListTooltip
										}
									]
								}
							} placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
						</ContainerTooltip>
						<div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
							{
								taskListDetailGuidePlay.taskListDetailGuidePlayFetched ? (
									!taskListDetailGuidePlay.isError ? (
										<div className=" commonSummaryTable">
											<CommonTable tableId="1"
												data={fetched_details.taskList.taskListDetailGuidePlayData.data}
												currentPage={taskListDetailGuidePlay.page}
												barColors=""
												linkPresent={false}
												showExport={false}
												showSearch={true}
												showEntries={true}
												defaultSort={taskListDetailGuidePlay.defaultSort}
												tablePagination={taskListDetailGuidePlay.tablePagination}
												tableEvent={handleTableEvents}
												firstTimeloadFlag={fetched_details.taskList.taskListDetailGuidePlayData.firstTimeload}
												modalTable={false}
											/>
										</div>
									)
										: (
											<h5 className="errorCss card-heading font-weight-400 text-center">
												{insightsLbls.serviceNotAvailable}
											</h5>
										))
									: (
										<div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
											<div className="displayFlex alignCenter margin-0-auto">
												<Loader></Loader>
											</div>
										</div>
									)
							}
						</div>
					</CardContainer>
				</div>
				{
					// <SectionRefresh sectionTitle={insightsLbls.userEngagementSummary} refreshClass={"marginTop-30"}>
					// 	<i className="fa fa-refresh pull-right cursorPointer row displayFlex"
					// 		aria-hidden="true"
					// 		title={insightsLbls['refresh']}
					// 		onClick={() => refreshSections(2)}></i>
					// </SectionRefresh>
				}
				<div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
					<CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
						div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
						div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
						<ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
							<SectionTooltip tooltipText={
								{
									"subTitle": insightsLbls.activity,
									"tooltipText": insightsLbls.userEngagementDetailSummary,
									"childern": [
										{
											"key": insightsLbls.userName + ":",
											"value": insightsLbls.userNameTooltip
										},
										{
											"key": insightsLbls.userEmail + ":",
											"value": insightsLbls.userEmailTooltip
										},
										{
											"key": insightsLbls.taskListInteracted + ":",
											"value": insightsLbls.taskListInteractedTooltip
										},
										{
											"key": insightsLbls.taskListCompleted + ":",
											"value": insightsLbls.taskListCompletedTooltip
										}
									]
								}
							} placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
						</ContainerTooltip>
						<div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
							{
								taskListDetailUserEngagementSummary.taskListDetailUserEngagementSummaryFetched ? (
									!taskListDetailUserEngagementSummary.isError ? (
										<div className="commonSummaryTable">
											<CommonTable tableId="1"
												data={fetched_details.taskList.taskListDetailUserEngagementSummaryData.data}
												currentPage={taskListDetailUserEngagementSummary.page}
												barColors=""
												linkPresent={true}
												showExport={true}
												showSearch={true}
												showEntries={true}
												defaultSort={taskListDetailUserEngagementSummary.defaultSort}
												tablePagination={taskListDetailUserEngagementSummary.tablePagination}
												tableEvent={handleTableEventsUserEngagement}
												firstTimeloadFlag={fetched_details.taskList.taskListDetailUserEngagementSummaryData.firstTimeload}
												modalTable={true}
											/>
										</div>
									)
										: (
											<h5 className="errorCss card-heading font-weight-400 text-center">
												{insightsLbls.serviceNotAvailable}
											</h5>
										))
									: (
										<div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
											<div className="displayFlex alignCenter margin-0-auto">
												<Loader></Loader>
											</div>
										</div>
									)
							}
						</div>
					</CardContainer>
				</div>
			</div>
			<CommonModal
				modalState={isModalOpen}
				dispatchModalState={closeModal}
				modalTitle={insightsLbls.userActivity}
				size={'lg'}>
				<>
					<div className="col-sm-12 marginTop-20 overflowX chartdiv search_section user-engagement-modal">
						{
							taskListDetailUserGuidePlay.userDetailFetched ? (
								!taskListDetailUserGuidePlay.isError ? (
									<div className="custom-modal-body">
										<div className="userDetailModalContainer">
											<div className="font600 font13">{taskListDetailUserGuidePlay.data["userFullName"]}</div>
											<div className="text-secondary font12">{taskListDetailUserGuidePlay.data["userEmail"]}</div>
											<div className="font12">
												<span className="">{insightsLbls.lastUpdated}:&nbsp;</span>
												<span className="font600">{getDateInFormat(taskListDetailUserGuidePlay.data["taskCompletionTime"])}</span>
											</div>
										</div>
										<div className={'card open'}>
											<div className="card-title" id="toggleButton">{insightsLbls.viewGuides}</div>
											<div className="card-content" id="cardContent">
												{getGuideInfoTemplate()}
											</div>
										</div>
									</div>
								)
									:
									<h5 className="errorCss card-heading font-weight-400 text-center">
										{taskListDetailUserGuidePlay.errorMsg}
									</h5>)
								: (
									<div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
										<div className="displayFlex alignCenter margin-0-auto">
											<Loader></Loader>
										</div>
									</div>
								)
						}
					</div>
				</>
			</CommonModal>

		</section>
	)
}

export default withRouter(withReducer("taskList", taskListReducer)(TaskListDetail));

