import React, { useState, useEffect, useCallback } from 'react';
import { Form, FormGroup, FormControl, FormLabel, Button, FormText, DropdownButton, Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import withRouter from "hoc/withRouter";

import { toast } from 'react-toastify';


import { useDispatch, useSelector } from 'react-redux';
import { AppsState, DashboardsState, StatusFilterState, DatesState, EventSourceState, LanguageState } from 'store/Reducers';
import { setDashboards } from "store/Actions";
import { postCall, deleteCall, getCall } from "utils/ApiCallActions";


import { CommonUtils } from "utils/CommonUtils";
import { ApiRelativePaths, RetryApi } from "utils/GlobalConstants";


import CommonModal from '../CommonModal';
import { TooltipState } from 'store/Reducers/GuideInsights/tooltips';
import { useErrorBoundary } from 'react-error-boundary';

import './CustomDashboardModal.scss';




import { InsightLabels } from 'labels';
import Icon from 'components/Icon/Icon';

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    eventSource: EventSourceState,
    statusFilter: StatusFilterState,
    tooltips: TooltipState,
    dashboards: DashboardsState,
    languageData: LanguageState
}

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

const CustomDashboardModal = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();



    let dash_number = 0;
    const dispatch = useDispatch();

    let fetched_details = useSelector((state: CurrentState) => state);
    let fetched_dashboard_details = useSelector((state: CurrentState) => state.dashboards);

    let [handleCreateBtn, setHandleCreateBtn] = useState(false);
    const [customDashboardModal, setCustomDashboardModal] = useState({
        modalOpen: false,
        applyDisabled: false,
        btnDisabled: false,
        dasboardList_flag: false,
        inputDuplicate_flag: false,
        chartAdded_flag: false,
        addedErrorIndex: 0,
    })

    const [errorCount, setErrorCount] = useState({
        errorCount: [0]
    })

    const [dashboardState, setDashboardState] = useState([])

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        optn: 0,
        id: ''
    })

    const [createDashboard, setCreateDashboard] = useState({
        flag: false
    })

    let inputMatch_flag = false;
    // let chartAdded_flag = false;

    const [dashboardSelected_icon, setDashboardSelected_icon] = useState('-1');


    const [dashboardImage, setDashboardImage] = useState({
        clickFlag: false,
        path: 'dashboardIcon-1',
        imagePath: [],
    })

    const [dashboardNameVal, setDashboardNameVal] = useState('');


    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])


    const setDashboardIcon = (image: string, type?: string) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        const elem = document.getElementById('dashboard_name') as HTMLInputElement;
        if (elem) {
            setTimeout(() => {
                setDashboardNameVal(elem.value)
            }, 0);
        }

        if (image) {
            let imgName = image.split('dashboardIcon-')
            let imagePath: any = [];

            // set Image
            imagePath = <div className={`dashboardIcon-${imgName[1]} icon-box`}>
                <Icon className="dashboardIcon-div" img={"img/custom_dashboard/icon" + imgName[1] + ".svg"} /></div>;
            setDashboardImage((prevState: any) => {
                return {
                    ...prevState,
                    path: image,
                    clickFlag: true,
                    imagePath: imagePath
                }
            })
            return imagePath;
            // if (type === 'get') {
            //     setDashboardImage((prevState: any) => {
            //         return {
            //             ...prevState,
            //             path: image,
            //         }
            //     })
            //     return imagePath;
            // } else {
            //     setDashboardImage((prevState: any) => {
            //         return {
            //             ...prevState,
            //             path: image,
            //             clickFlag: true,
            //             imagePath: imagePath
            //         }
            //     })
            // }
        }

    }

    const openModal = (apiErrorFlag?: string) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        setCustomDashboardModal((prevState: any) => {
            return {
                ...prevState,
                modalOpen: true,
                flag: false,
                chartAdded_flag: false,
                addedErrorIndex: 0

            }
        })

        let param_val = {};
        getCall(param_val, "CREATE_DASHBOARD", apiErrorFlag ?? '').then((data: any) => {
            if (data.result === 'success') {
                const dashboardData: any = data.data;
                const dummyData: any = [];
                for (let i = 0; i < dashboardData.length; i++) {
                    dummyData[i] = {
                        ['dashboard' + (i + 1)]: {
                            active: true,
                            name: dashboardData[i].profileName,
                            profileID: dashboardData[i].profileID,
                            isDefault: dashboardData[i].isDefault,
                            dashboardIcon: dashboardData[i].dashboardIcon,
                            profileDetails: dashboardData[i].profileDetails
                        }
                    }
                    if (dashboardData[i].isDefault) {
                        setDashboardSelected_icon('dashboardCheck_icon' + i)
                    }
                }
                dispatch(setDashboards(dummyData))
            } else if (data.result === 'retry') {
                setTimeout(() => {
                    openModal('retry')
                }, RetryApi.TIMEOUT)
            }
        })
    }



    // First time call
    const CreateDashboardForm = () => {

        const [formValues, setFormValues] = useState({
            dashboardName: dashboardNameVal,
        })

        const getValidationState = () => {
            try {
                let length = formValues.dashboardName.length;
                if (length > 0 && length <= 20 && formValues.dashboardName.trim() !== "") return 'success';
                if (length > 0 && length <= 20 && formValues.dashboardName.trim() === "") return 'error';
                if (length > 20) return 'error';
                return null;
            } catch (error) {
                showBoundary(error)
            }

        };

        const handleChange = (e: any) => {
            try {
                e.preventDefault();
                let dashboardName = e.target.value

                for (let i = 0; i < fetched_dashboard_details?.data.length; i++) {
                    if (fetched_dashboard_details?.data[i]['dashboard' + `${i + 1}`].name === e.target.value) {
                        inputMatch_flag = true;
                    } else {
                        inputMatch_flag = false;

                    }
                }

                setFormValues((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardName: e.target.value,
                    };
                });
            } catch (error) {
                showBoundary(error)
            }

        };

        const onSubmit = (e: any) => {
            try {
                setHandleCreateBtn(true);
                e.preventDefault();
                let params = {};
                const dummyData: any = [];
                let customFlag = 0;

                let dashboardData: any = [];

                getCall(params, "CREATE_DASHBOARD", '').then((data1: any) => {
                    if (data1.result === 'success') {

                        let dashboardData = data1.data;

                        const dummyData = {
                            profileID: '',
                            profileName: formValues.dashboardName,
                            isDefault: false,
                            dashboardNumber: data1.data.length + 1,
                            isDashboard: true,
                            dashboardIcon: dashboardImage.path,
                            profileDetails: []
                        };

                        dashboardData.push(dummyData);

                        postCall(dashboardData, "CREATE_DASHBOARD").then((data2: any) => {
                            if (data2.result === "success") {

                                setDashboardNameVal('');


                                for (let itr0 = 0; itr0 < data2.data.length; itr0++) {
                                    if (dashboardData.length < 3) {
                                        dashboardData[itr0]['profileID'] = data2.data[itr0].profileID;
                                        dashboardData[itr0]['profileDetails'] = data2.data[itr0].profileDetails;
                                    }
                                }
                                setDashboardState(dashboardData);


                                let dData = []
                                for (let itr0 = 0; itr0 < data2.data.length; itr0++) {
                                    if (data2.data.length < 3) {
                                        dData.push({
                                            ['dashboard' + (itr0 + 1)]: {
                                                active: true,
                                                name: data2.data[itr0].profileName,
                                                profileID: data2.data[itr0].profileID,
                                                isDefault: data2.data[itr0].isDefault,
                                                dashboardIcon: data2.data[itr0].dashboardIcon,
                                                profileDetails: data2.data[itr0].profileDetails
                                            }
                                        })
                                    }
                                }
                                dispatch(setDashboards(dData))

                                toast.success(insightsLbls.successfullyAdded, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });

                                setCustomDashboardModal((prevState: any) => ({
                                    ...prevState,
                                    btnDisabled: dashboardData.length <= 2 ? false : true,
                                    chartAdded_flag: false,
                                    addedErrorIndex: 0
                                }));

                                setHandleCreateBtn(false);
                            } else {
                                console.error('postCall failed:', data2.message);
                            }
                        }).catch((error: any) => {
                            console.error('Error in postCall:', error);
                        });
                    } else {
                        console.error('getCall failed:', data1.message);
                    }
                }).catch((error: any) => {
                    console.error('Error in getCall:', error);
                });


            } catch (error) {
                setHandleCreateBtn(false);
                showBoundary(error);
            }
        };

        return (
            <div className="padding20">
                <Form>
                    <FormGroup
                    // validationState={getValidationState()}
                    >
                        <FormLabel className="marginLeft-0">{insightsLbls.dashboardName}:- {dashboardImage.clickFlag}</FormLabel>

                        <div className='displayFlex alignCenter'>
                            {/* <Dropdown autoClose={true}>
                                <Dropdown.Toggle variant="" id="dropdown-autoclose-true" className='dashboardIcon-btn' disabled={fetched_dashboard_details?.data?.length <= 2 ? false : true}>
                                    {dashboardImage.clickFlag
                                        ? (fetched_dashboard_details?.data?.length <= 2 ? dashboardImage.imagePath : insightsLbls.selectIcon)
                                        : insightsLbls.selectIcon
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {[...Array(15)].map((elementInArray, index) => (
                                        <Dropdown.Item onClick={() => setDashboardIcon('dashboardIcon-' + (index + 1), 'set')}>
                                            <div className={`dashboardIcon-${index + 1} icon-box`}>
                                                <Icon className="dashboardIcon-div" img={"img/custom_dashboard/icon" + (index + 1) + ".svg"} />
                                            </div>
                                        </Dropdown.Item>)
                                    )}
                                </Dropdown.Menu>
                            </Dropdown> */}

                            <FormControl
                                type="text"
                                value={formValues.dashboardName}
                                placeholder={insightsLbls.enterText}
                                onChange={handleChange}
                                autoComplete="off"
                                disabled={fetched_dashboard_details?.data?.length <= 2 ? false : true}
                                id='dashboard_name'
                            />
                        </div>
                    </FormGroup>

                    {inputMatch_flag && (<FormText className="helpBlock displayBlock" >{insightsLbls.duplicateDashboardName}</FormText>)}
                    {/* disabled={handleCreateBtn || formValues.dashboardName.length === 0 || formValues.dashboardName.length > 20 || formValues.dashboardName.trim() === "" ? true : fetched_dashboard_details?.data?.length <= 2 ? inputMatch_flag : true} */}
                    <Button type="submit" onClick={onSubmit} className="addDashboard-btn marginTop-10">{insightsLbls.create}</Button>
                </Form>
                <FormControl.Feedback />
                {customDashboardModal.btnDisabled && (<FormText className="helpBlock" >{insightsLbls.canAddUpto3Dashboards}</FormText>)}
                {formValues.dashboardName.length > 20 && (<FormText className="helpBlock" >{insightsLbls.dashboardName20Chars}</FormText>)}
                {formValues.dashboardName.length > 0 && formValues.dashboardName.trim() === "" && (<FormText className="helpBlock" > {insightsLbls.pleaseEnterValidName}</FormText>)}

            </div>
        )

    };

    const addToDashboard = (e: any, dashboardNumber: number, dashboardName: string, profileID: string, isDefault: boolean, dashboardIcon: string) => {
        // e.preventDefault();

        dash_number = dashboardNumber;
        let addedFlag = 0;
        for (let i = 0; i < fetched_dashboard_details.data.length; i++) {
            if (fetched_dashboard_details.data[i]['dashboard' + `${i + 1}`].name === dashboardName) {
                for (let j = 0; j < fetched_dashboard_details.data[i]['dashboard' + `${i + 1}`].profileDetails.length; j++) {
                    for (let k = 0; k < fetched_dashboard_details.data[i]['dashboard' + `${i + 1}`].profileDetails[j].api.length; k++) {
                        if (fetched_dashboard_details.data[i]['dashboard' + `${i + 1}`].profileDetails[j].api[k] === props.api[k]
                            && fetched_dashboard_details.data[i]['dashboard' + `${i + 1}`].profileDetails[j].appCode === fetched_details.apps.appCode) {
                            addedFlag = 1;
                            break;
                        }
                    }
                }
            }
        }

        let dummyProfile_data = fetched_dashboard_details.data[dashboardNumber - 1]['dashboard' + `${dashboardNumber}`].profileDetails


        const profile_details = [{
            appTitle: fetched_details.apps.appName,
            appCode: fetched_details.apps.appCode,
            appID: fetched_details.apps.appId,
            eventSourceFilter: props.eventSource,
            guideStatusFilter: props.guideStatus,
            segmentsGroup: props.segmentsGroup,
            tooltipFilter: fetched_details.tooltips?.tooltipList,
            api: props.api,
            chartType: props.chartType,
            sectionHeader: props.sectionHeader,
            sectionTooltip: props.sectionTooltip,
            sectionType: props.sectionType,
            chartIndex: dummyProfile_data.length,
            guide_filter: props.guide_filter,
            event_filter: props.event_filter,
            tooltip_filter: props.tooltip_filter,
            segment_filter: props.segment_filter,
            component: props.component,
            tooltip_guideIds: props.tooltip_guideIds,
            chartID:''
        }]

        if (addedFlag === 0) {
            let path = ApiRelativePaths.CREATE_DASHBOARD;
            let params = {};
            let paramObj = {};
            let a = CommonUtils.URLGenerator(params, path, paramObj);

            let d_dummy = [];

            for (let itr0 = 0; itr0 < fetched_dashboard_details.data.length; itr0++) {
                const dashboardKey = 'dashboard' + (itr0 + 1);
                const currentDashboard = fetched_dashboard_details.data[itr0][dashboardKey];

                d_dummy.push({
                    profileID: currentDashboard['profileID'],
                    profileName: currentDashboard['name'],
                    isDefault: currentDashboard['isDefault'],
                    dashboardNumber: itr0 + 1,
                    dashboardIcon: currentDashboard['dashboardIcon'],
                    isDashboard: false,
                    profileDetails:
                        profileID === currentDashboard['profileID']
                            ? currentDashboard['profileDetails'] && currentDashboard['profileDetails'].length > 0
                                ? [...currentDashboard['profileDetails'], ...profile_details]
                                : profile_details
                            : currentDashboard['profileDetails']
                });
            }

            postCall(d_dummy, "CREATE_DASHBOARD").then((data: any) => {
                if (data.result === "success") {

                    toast.success(insightsLbls.successfullyAdded, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    let param_val = {};
                    getCall(param_val, "CREATE_DASHBOARD", '').then((data: any) => {
                        if (data.result === 'success') {
                            let dData = []
                            for (let itr0 = 0; itr0 < data.data.length; itr0++) {
                                if (data.data.length < 3) {
                                    dData.push({
                                        ['dashboard' + (itr0 + 1)]: {
                                            active: true,
                                            name: data.data[itr0].profileName,
                                            profileID: data.data[itr0].profileID,
                                            isDefault: data.data[itr0].isDefault,
                                            dashboardIcon: data.data[itr0].dashboardIcon,
                                            profileDetails: data.data[itr0].profileDetails
                                        }
                                    })
                                }
                            }
                            dispatch(setDashboards(dData))
                        }
                    })

                    closeDashboardModal();
                }
            });

            setCustomDashboardModal((prevState: any) => {
                return {
                    ...prevState,
                    chartAdded_flag: false,
                    addedErrorIndex: 0

                }
            })

        } else {
            setCustomDashboardModal((prevState: any) => {
                return {
                    ...prevState,
                    chartAdded_flag: true,
                    addedErrorIndex: dash_number
                }
            })
        }
    };




    const closeDashboardModal = () => {
        setCustomDashboardModal((prevState: any) => {
            return {
                ...prevState,
                applyDisabled: true,
                modalOpen: false

            }
        })
        setCreateDashboard((prevState: any) => {
            return {
                ...prevState,
                flag: false
            }
        })
    }

    const open_deleteDashboard = (optn: number, id: string) => {
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                optn: optn,
                id: id,
                open: true
            }
        })
    }



    const closeDeleteModal = () => {
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })

    }

    const deleteDashboard = () => {

        let params = {
            profileID: deleteModal.id,
        };

        deleteCall(params, "CREATE_DASHBOARD", "").then((data: any) => {
            if (data.result === "success") {
                closeDeleteModal();

                toast.error(insightsLbls.successfullyDeleted, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                let param_val = {};
                getCall(param_val, "CREATE_DASHBOARD", '').then((data: any) => {
                    if (data.result === 'success') {
                        const dashboardData: any = data.data;
                        const dummyData: any = [];
                        for (let i = 0; i < dashboardData.length; i++) {
                            dummyData[i] = {
                                ['dashboard' + (i + 1)]: {
                                    active: true,
                                    name: dashboardData[i].profileName,
                                    profileID: dashboardData[i].profileID,
                                    isDefault: dashboardData[i].isDefault,
                                    dashboardIcon: dashboardData[i].dashboardIcon,
                                    profileDetails: dashboardData[i].profileDetails
                                }
                            }
                            if (dashboardData[i].isDefault) {
                                setDashboardSelected_icon('dashboardCheck_icon' + i)
                            }
                        }
                        setDashboardState(dummyData);
                        dispatch(setDashboards(dummyData))
                    }
                })

                setDashboardImage((prevState: any) => {
                    return {
                        ...prevState,
                        clickFlag: false,
                        path: 'dashboardIcon-1',
                        imagePath: []
                    }
                })
            }
        });


    }

    useEffect(() => {

        setCustomDashboardModal((prevState: any) => {
            return {
                ...prevState,
                // btnDisabled: dashboardState.length <= 2 ? false : true
                btnDisabled: fetched_dashboard_details.data?.length <= 2 ? false : true
            }
        })
    }, [fetched_dashboard_details.data])



    const onDefaultOptionChange = (event: any, id: number, dashboardName: string, dashboardNumber: number, dashboardIcon: string) => {
        if (event.target.checked) {
            for (let i = 0; i < fetched_dashboard_details.data.length; i++) {
                setTimeout(() => {
                    const elem = document.getElementById('dashboardCheck' + i) as HTMLInputElement;
                    if (elem) {
                        elem.checked = false;
                    }
                }, 0);
            }

            setTimeout(() => {
                const elem = document.getElementById('dashboardCheck' + (dashboardNumber - 1)) as HTMLInputElement;
                if (elem) {
                    elem.checked = true;
                }
            }, 0);
        }

        const data = {
            profileID: id,
            profileName: dashboardName,
            isDefault: event.target.checked,
            dashboardNumber: dashboardNumber,
            isDashboard: false,
            dashboardIcon: dashboardIcon,
            profileDetails: fetched_dashboard_details?.data[dashboardNumber - 1]['dashboard' + `${dashboardNumber}`].profileDetails
        };

        let path = ApiRelativePaths.CREATE_DASHBOARD;
        let params = {};
        let paramObj = {};
        let a = CommonUtils.URLGenerator(params, path, paramObj);

        postCall(data, "CREATE_DASHBOARD").then((data: any) => {
            if (data.result === "success") {
                let dashDummy_data: any = [];
                for (let i = 0; i < data.data.length; i++) {
                    dashDummy_data.push({
                        ['dashboard' + (i + 1)]: {
                            active: true,
                            name: data.data[i].profileName,
                            profileID: data.data[i].profileID,
                            isDefault: data.data[i].isDefault,
                            dashboardIcon: data.data[i].dashboardIcon,
                            profileDetails: data.data[i].profileDetails
                        }
                    })
                }

                setDashboardState(dashDummy_data);
                dispatch(setDashboards(dashDummy_data));

                toast.success(insightsLbls.successfullyUpdated, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const createNewDashbboard = () => {
        setDashboardImage((prevState: any) => {
            return {
                ...prevState,
                clickFlag: false,
                path: 'dashboardIcon-1',
                imagePath: []
            }
        })
        if (fetched_dashboard_details.data.length < 3) {
            setCreateDashboard((prevState: any) => {
                return {
                    ...prevState,
                    flag: true
                }
            })
        }
    }

    const getListDashboardImage = useCallback((image: string) => {
        let imgName = image.split('dashboardIcon-')
        let imagePath: any = [];
        return <div className={`dashboardIcon-${imgName[1]} icon-box`}>
            <Icon className="dashboardIcon-div" img={"img/custom_dashboard/icon" + imgName[1] + ".svg"} /></div>;
    }, []);

    return (
        <React.Fragment>

            <Icon img={"img/pin_chart.svg"} className="dashboardIcon-div" onClick={() => openModal()} />

            {/* <i
                className="fa fa-thumb-tack pinToDashboard bookmarkMargin cursorPointer margin0 floatRight"
                aria-hidden="true"
                onClick={() => openModal()}
            >
            </i> */}
            <CommonModal
                modalState={customDashboardModal.modalOpen}
                dispatchModalState={closeDashboardModal}
                size={'lg'}
                modalTitle={insightsLbls.pinToDashboard}
                okBtnState={customDashboardModal.applyDisabled}
                footerDisabled={true}>
                <>


                    <div className="existingDashboardContainer paddingLR-20 marginTop-30">
                        {fetched_dashboard_details.data?.length > 0 && (<div className="font16">{insightsLbls.chooseDahsboard}</div>)}


                        <div className="paddingTB-8">
                            {fetched_dashboard_details?.data?.map((data: any, index: number) => {
                                const keyVal = Object.keys(data)[0];

                                // const [`dashIcon_${index}`] = 
                                // const dashIcon = setDashboardIcon(data[keyVal]['dashboardIcon'])

                                setTimeout(() => {
                                    const elem = document.getElementById('dashboardCheck' + index) as HTMLInputElement;
                                    if (elem) {
                                        elem.checked = data[keyVal]['isDefault']
                                    }
                                }, 100);


                                return (
                                    <>
                                        {data[keyVal]['active'] &&
                                            (
                                                <>
                                                    <div className="dashboardNameContainer positionRelative">

                                                        {/* <div>{getListDashboardImage(data[keyVal]['dashboardIcon'])}</div> */}
                                                        <div className="dashboardTitle">{data[keyVal]['name']}</div>
                                                        <div className="buttonContainer">
                                                            <Button
                                                                type="submit"
                                                                onClick={(e) => addToDashboard(e, (index + 1), data[keyVal]['name'], data[keyVal]['profileID'], data[keyVal]['isDefault'], data[keyVal]['dashboardIcon'])}
                                                                className='addDashboard-btn displayFlex alignCenter'>
                                                                <Icon className="pinIcon" img={'img/pinIcon.svg'} />
                                                                <div>{insightsLbls.save}</div>
                                                            </Button>
                                                        </div>
                                                        <div className="marginLeft-30 marginTop25 custom-control custom-checkbox mr-sm-2 check-container">
                                                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                                                <input type="checkbox"
                                                                    className="custom-control-input chk checkbox"
                                                                    id={"dashboardCheck" + index}
                                                                    name="chk-box"
                                                                    value="delete"
                                                                    onClick={(event) => onDefaultOptionChange(event, data[keyVal]['profileID'], data[keyVal]['name'], (index + 1), data[keyVal]['dashboardIcon'])} />
                                                                <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
                                                                <Icon className={`${dashboardSelected_icon}` === ('dashboardCheck_icon' + index) ? 'dashboardDefault-icon dashboradIcon' : 'dashboradIcon'} img={'img/dashboardIcon.svg'} onClick={() => setDashboardSelected_icon("dashboardCheck_icon" + index)} />
                                                            </label>
                                                        </div>

                                                        <Icon className="deleteDashboardIcon" onClick={() => open_deleteDashboard(index, data[keyVal]['profileID'])} img={'img/custom_dashboard/delete.svg'} />

                                                        {/* <i className="fa fa fa-trash-o deleteDashboardIcon" aria-hidden="true" ></i> */}

                                                    </div>
                                                    {(customDashboardModal.chartAdded_flag) &&
                                                        <>
                                                            {customDashboardModal.addedErrorIndex === (index + 1) && (<FormText className="helpBlock">{insightsLbls.chartsAlreadyAdded} {data[keyVal]['name']}.</FormText>)}

                                                        </>
                                                    }
                                                </>
                                            )
                                        }
                                    </>

                                );
                            })}
                        </div>


                        {fetched_dashboard_details.data?.length === 0 &&
                            (
                                <div className="warningContainer">
                                    <div>{insightsLbls.createNewDashboardMsg}</div>
                                </div>
                            )
                        }
                    </div>

                    {fetched_dashboard_details.data?.length > 0 &&
                        (<div className="col-sm-12 col-md-12 col-lg-12 displayFlex aligntCenter">
                            <span className="col-sm-6 col-md-6 col-lg-6 hrSpan"></span>

                            <span className="col-sm-6 col-md-6 col-lg-6 hrSpan"></span>
                        </div>)
                    }


                    <div className="createDashboardContainer paddingLR-20 paddingTop-20">
                        {!createDashboard.flag && (<div className={fetched_dashboard_details.data.length < 3 ? ("createNew paddingLR-5") : ("createNewDisabled paddingLR-5")}
                            onClick={() => createNewDashbboard()}>
                            <Icon className="addDashboardIcon paddingLR-5" img={'img/custom_dashboard/add.svg'} />
                            {insightsLbls.createNewDashboard}
                        </div>)}

                        {createDashboard.flag && (<CreateDashboardForm></CreateDashboardForm>)}

                    </div>
                </>
            </CommonModal>


            <CommonModal
                modalState={deleteModal.open}
                dispatchModalState={closeDeleteModal}
                modalTitle={insightsLbls.deleteDashboard}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                modalDialogClass={''}
                dispatchModalOk={() => deleteDashboard()}
                dispatchModalCancel={closeDeleteModal}
                okBtn={insightsLbls.delete}
                cancelBtn={insightsLbls.cancel}
            >
                <div className="deleteChart">
                    <p>{insightsLbls.allDashboardDataDeleted}</p>
                    <p> {insightsLbls.wantToDeleteDashboard}</p>

                </div>
            </CommonModal>
        </React.Fragment>
    )
};

export default withRouter(CustomDashboardModal);