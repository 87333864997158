import React, { useRef, useState, useEffect } from "react";
import NVD3Chart from "react-nvd3";

import * as d3 from 'd3';
import $ from 'jquery'
import { CommonUtils } from "utils/CommonUtils";
import { useErrorBoundary } from 'react-error-boundary';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { InsightLabels } from "labels";
import { LanguageState } from "store/Reducers";
import { useSelector } from "react-redux";
interface CurrentState {
    languageData: LanguageState
}
const Piechart = (props: any) => {
    const PieChartRef = useRef(null);
    let fetched_details = useSelector((state: CurrentState) => state);
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    const [renderData, setRenderData] = useState({
        chart: []
    })


    const aggregateData = (chartData) => {
        let aggregatedCount = chartData.reduce((acc, { counts }) => {
            Object.entries(counts).forEach(([key, value]) => {
                acc[key] = (acc[key] || 0) + parseInt(value as string);
            })
            return acc;
        }, {})
        return aggregatedCount;
    }

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            let tempChartData = [];
            if (props.chartData.length > 0) {
                let tempCountData = props.aggRequired ? aggregateData(props.chartData) : props.chartData[0].counts;
                tempChartData = Object.keys(tempCountData).filter(key => props.chartEntities.includes(key)).map(key => {
                    let optionEntityObj = props.optionEntities.find(obj => (obj.associatedDataPoint === key) || (obj.key === key))
                    if (optionEntityObj) {
                        if (optionEntityObj.color) {
                            return { name: optionEntityObj.key, value: tempCountData[key], associatedDataPoint: optionEntityObj.associatedDataPoint || key, fill: optionEntityObj.color }
                        }
                        else {
                            return { name: optionEntityObj.key, value: tempCountData[key], associatedDataPoint: optionEntityObj.associatedDataPoint || key }
                        }
                    }
                })
                setRenderData((prevState) => {
                    return {
                        ...prevState,
                        chart: tempChartData
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [props])


    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            payload,
            percent,
            name,
            value,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    <tspan x={cx} dy="-0.5em">{payload.name}</tspan>
                    <tspan x={cx} dy="1.2em">{`${insightsLbls.count} - ${value}`}</tspan>
                    <tspan x={cx} dy="2.4em">{`(${(percent * 100).toFixed(2)}%)`}</tspan>
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        );
    };

    const [activeState, setActiveState] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveState(index);
    };

    const getTemplate = () => {
        if (renderData.chart.every(item => item.value === 0)) {
            return <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                <div className="displayFlex alignCenter margin-0-auto">
                    <div className="noDataText-font textCenter-absolute">
                        {insightsLbls.noRecordFound}
                    </div>
                </div>
            </div>
        } else {
            return <div id={props.refId} style={{ width: '100%', height: props.height }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400} margin={{ top: 20, right: 30, left: 0, bottom: 5 }} style={{ outline: 'none' }} >
                        <Pie
                            activeIndex={activeState}
                            activeShape={renderActiveShape}
                            data={renderData.chart}
                            cx={"50%"}
                            cy={"50%"}
                            innerRadius={60}
                            outerRadius={80}
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                            style={{ outline: 'none' }}
                        />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        }
    }

    return getTemplate();
};
export default React.memo(Piechart);