import * as actionTypes from '../../Actions/ActionTypes';


export interface DashboardState {
    dashboardGuideMeChartData: any,
    dashboardShowMeChartData: any,
    dashboardTestMeChartData: any,
    dashboardDoItForMeChartData: any,
    dashboardTooltipChartData: any,
    dashboardPushnotificationChartData: any,
    dashboardBeaconChartData: any,
    dashboardSurveyChartData: any,
    dashboardValidationChartData: any,
    dashboardPowerhtmlChartData: any,
    dashboardDucttapeChartData: any,
    dashboardGuidancevalidationChartData: any,
    dashboardGuidanceChartData: any,


    dashboardGuideTutorialsChartData: any,
    dashboardGuidesTableData: any,
    dashboardGuideSummaryTableData: any,
    dashboardTooltipSummaryTableData: any,
    dashboardWorkflowChartData: any,
    dashboardWorkflowsTableData: any,
    dashboardSurveysChartData: any,
    dashboardSurveysTableData: any
    dashboardCountriesChartData: any
    dashboardGuidesChartData: any
    dashboardGuidesDonutChartData: any
    dashboardAppsSummary: any,

    dashboardTotalGuideCount: any,
    dashboardUserEngagementData: any,

}

const initialState = {
    dashboardGuideMeChartData: [],
    dashboardShowMeChartData: [],
    dashboardTestMeChartData: [],
    dashboardDoItForMeChartData: [],
    dashboardTooltipChartData: [],
    dashboardBeaconChartData: [],
    dashboardPushnotificationChartData: [],
    dashboardSurveyChartData: [],
    dashboardValidationChartData: [],
    dashboardPowerhtmlChartData: [],
    dashboardDucttapeChartData: [],
    dashboardGuidancevalidationChartData: [],
    dashboardGuidanceChartData: [],
    dashboardGuideTutorialsChartData: [],
    dashboardGuidesTableData: [],
    dashboardGuideSummaryTableData: [],
    dashboardTooltipSummaryTableData: [],
    dashboardWorkflowChartData: [],
    dashboardWorkflowsTableData: [],
    dashboardSurveysChartData: [],
    dashboardSurveysTableData: [],
    dashboardCountriesChartData: [],
    dashboardGuidesChartData: [],
    dashboardGuidesDonutChartData: [],
    dashboardUserEngagementData: [],
    dashboardAppsSummary: [],

    dashboardTotalGuideCount: [],
};

const dashboardReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case actionTypes.SET_DASHBOARD_GUIDEME_CHART_DATA:
            return {
                ...state,
                dashboardGuideMeChartData: action.dashboardGuideMeChartData,
            };

        case actionTypes.SET_DASHBOARD_SHOWME_CHART_DATA:
            return {
                ...state,
                dashboardShowMeChartData: action.dashboardShowMeChartData,
            };

        case actionTypes.SET_DASHBOARD_TESTME_CHART_DATA:
            return {
                ...state,
                dashboardTestMeChartData: action.dashboardTestMeChartData,
            };

        case actionTypes.SET_DASHBOARD_DO_IT_FOR_ME_CHART_DATA:
            return {
                ...state,
                dashboardDoItForMeChartData: action.dashboardDoItForMeChartData,
            };

        case actionTypes.SET_DASHBOARD_GUIDE_TUTORIALS_CHART_DATA:
            return {
                ...state,
                dashboardGuideTutorialsChartData: action.dashboardGuideTutorialsChartData,
            };

        case actionTypes.SET_DASHBOARD_TOOLTIP_CHART_DATA:
            return {
                ...state,
                dashboardTooltipChartData: action.dashboardTooltipChartData,
            };

        case actionTypes.SET_DASHBOARD_PUSHNOTIFICATION_CHART_DATA:
            return {
                ...state,
                dashboardPushnotificationChartData: action.dashboardPushnotificationChartData,
            };

        case actionTypes.SET_DASHBOARD_BEACON_CHART_DATA:
            return {
                ...state,
                dashboardBeaconChartData: action.dashboardBeaconChartData,
            };

        case actionTypes.SET_DASHBOARD_SURVEY_CHART_DATA:
            return {
                ...state,
                dashboardSurveyChartData: action.dashboardSurveyChartData,
            };

        case actionTypes.SET_DASHBOARD_VALIDATION_CHART_DATA:
            return {
                ...state,
                dashboardValidationChartData: action.dashboardValidationChartData,
            };

        case actionTypes.SET_DASHBOARD_POWERHTML_CHART_DATA:
            return {
                ...state,
                dashboardPowerhtmlChartData: action.dashboardPowerhtmlChartData,
            };

        case actionTypes.SET_DASHBOARD_DUCTTAPE_CHART_DATA:
            return {
                ...state,
                dashboardDucttapeChartData: action.dashboardDucttapeChartData,
            };

        case actionTypes.SET_DASHBOARD_GUIDANCEVALIDATION_CHART_DATA:
            return {
                ...state,
                dashboardGuidancevalidationChartData: action.dashboardGuidancevalidationChartData,
            };

        case actionTypes.SET_DASHBOARD_GUIDANCE_CHART_DATA:
            return {
                ...state,
                dashboardGuidanceChartData: action.dashboardGuidanceChartData,
            };

  

        case actionTypes.SET_DASHBOARD_GUIDES_TABLE_DATA:
            return {
                ...state,
                dashboardGuidesTableData: action.dashboardGuidesTableData,
            };

        case actionTypes.SET_DASHBOARD_GUIDE_SUMMARY_TABLE_DATA:
            return {
                ...state,
                dashboardGuideSummaryTableData: action.dashboardGuideSummaryTableData,
            };

        case actionTypes.SET_DASHBOARD_TOOLTIP_SUMMARY_TABLE_DATA:
            return {
                ...state,
                dashboardTooltipSummaryTableData: action.dashboardTooltipSummaryTableData,
            };

        case actionTypes.SET_DASHBOARD_WORKFLOWS_CHART_DATA:
            return {
                ...state,
                dashboardWorkflowChartData: action.dashboardWorkflowChartData,
            };

        case actionTypes.SET_DASHBOARD_WORKFLOWS_TABLE_DATA:
            return {
                ...state,
                dashboardWorkflowsTableData: action.dashboardWorkflowsTableData,
            };

        case actionTypes.SET_DASHBOARD_SURVEYS_TABLE_DATA:
            return {
                ...state,
                dashboardSurveysTableData: action.dashboardSurveysTableData,
            };
        case actionTypes.SET_DASHBOARD_CONTRIES_CHART_DATA:
            return {
                ...state,
                dashboardCountriesChartData: action.dashboardCountriesChartData,
            };
        case actionTypes.SET_DASHBOARD_GUIDES_CHART_DATA:
            return {
                ...state,
                dashboardGuidesChartData: action.dashboardGuidesChartData,
            };
        case actionTypes.SET_DASHBOARD_GUIDES_DONUT_CHART_DATA:
            return {
                ...state,
                dashboardGuidesDonutChartData: action.dashboardGuidesDonutChartData,
            };
        case actionTypes.SET_DASHBOARD_USER_ENGAGEMENT:
            return {
                ...state,
                dashboardUserEngagementData: action.dashboardUserEngagementData,
            };

        case actionTypes.SET_DASHBOARD_APPS_SUMMARY:
            return {
                ...state,
                dashboardAppsSummary: action.dashboardAppsSummary,
            };

        case actionTypes.SET_DASHBOARD_GUIDE_COUNT:
            return {
                ...state,
                dashboardTotalGuideCount: action.dashboardTotalGuideCount,
            };


        default:
            return state;
    }
};

export default dashboardReducer;
