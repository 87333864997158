import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppsState, DatesState, EventSourceState, LanguageState } from "store/Reducers";

import { TooltipFilterState } from "store/Reducers";
import { getCall } from "utils/ApiCallActions";
import { setTooltipFilter } from "store/Actions";

import Loader from "components/Loader/Loader";
import CommonModal from "../CommonModal";

import "./TooltipModal.scss";
import { RetryApi } from "utils/GlobalConstants";
import { useLocation } from "react-router-dom";
import { useErrorBoundary } from 'react-error-boundary';

// import insightsLabels from "utils/stringConstants/trans_utils";
import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from "labels";
// let insightsLbls = insightsLabels;

interface CurrentState {
	apps: AppsState;
	dates: DatesState;
	eventSource: EventSourceState;
	tooltipList: TooltipFilterState;
	languageData: LanguageState
}

const TooltipModal = (props: any) => {

	const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
	const { showBoundary } = useErrorBoundary();

	let fetched_details = useSelector((state: CurrentState) => state.dates);
	let fetched_details_language = useSelector((state: CurrentState) => state.languageData);

	let fetched_app_details = useSelector((state: CurrentState) => state.apps);
	let fetched_tooltip_details = useSelector(
		(state: CurrentState) => state.tooltipList
	);

	const [isTooltipTop, setToolTipPosition] = useState(false);

	const dispatch = useDispatch();
	const location = useLocation();

	const [tooltipModal, setTooltipModal] = useState({
		modalOpen: false,
		listData: [],
		listFetched: false,
		okDisabled: false,
		btnText:
			props.fromComponent === "tooltip"
				? "All"
				: props.defaultSelected.length - 1 <= 0
					? "All"
					: props.defaultSelected.length - 1,
		clearFlag: false,
		checkList: [],
		firstLoad: true,
		selectedList: [],
		selectCount: 0,
	});

	const [errorCount, setErrorCount] = useState({
		errorCount: [0],
	});

	const openModal = () => {
		try {
			setTooltipModal((prevState: any) => {
				return {
					...prevState,
					modalOpen: true,
					checkList: [],
					selectCount: tooltipModal.btnText,
					listFetched: false,
				};
			});

			getTooltipList();
		} catch (error) {
			showBoundary(error)
		}
	};

	const onSearchText = (searchInput: any) => {
		try {
			setTooltipModal((prevState: any) => {
				return {
					...prevState,
					listFetched: false,
					okDisabled: true,
				}
			})
			getTooltipList(searchInput.target.value);
		} catch (error) {
			showBoundary(error)
		}

	};

	const getTooltipList = (searchInput?: any, apiErrorFlag?: string) => {
		try {
			const params = {
				app_code: props.fromComponent === "customDashboard" ? props.appCode : fetched_app_details.appCode,
				search_text: encodeURIComponent(searchInput ? searchInput : ""),
				page_index: 1,
				page_size: 99999,
				exclude_weekends: fetched_details.excludeFlag,
			};

			getCall(params, "TOOLTIP_LIST", apiErrorFlag ?? "").then((res: any) => {
				if (res.result === "success") {
					setTooltipModal((prevState: any) => {
						return {
							...prevState,
							listFetched: true,
							listData: res?.data?.data,
							okDisabled: res?.data?.data?.length === 0 ? true : false,
						};
					});

					// dispatch(setTooltipFilter(urlData['tooltipList']));
					// props.dispatchGuidesList(urlData['tooltipList']);

					apiCatchError(0, 0);
				} else if (res.result === "retry") {
					setTimeout(() => {
						// getTooltipList('', 'retry')
					}, RetryApi.TIMEOUT);
				} else if (res.result === "error") {
					setTooltipModal((prevState: any) => {
						return {
							...prevState,
							listFetched: true,
							listData: {},
						};
					});
					apiCatchError(0, 1);
				}
			});
		} catch (error) {
			showBoundary(error)
		}

	};
	const closeModal = () => {
		try {
			setTooltipModal((prevState: any) => {
				return {
					...prevState,
					modalOpen: false,
					selectCount: tooltipModal.btnText,
					clearFlag: false,
				};
			});
		} catch (error) {
			showBoundary(error)
		}

	};

	useEffect(() => {
		if (fetched_details_language.languageData) {
			setInsightsLbls(fetched_details_language.languageData);
		}

	}, [fetched_details_language.languageData])

	useEffect(() => {
		try {
			if (tooltipModal.listData.length > 0 && tooltipModal.firstLoad) {
				let selected_list: any = [];
				const checkboxes: any = document.getElementsByClassName("tooltipChk");

				if (checkboxes) {
					if (
						props.fromComponent === "tooltip" ||
						(props.defaultSelected.length - 1 <= 0 &&
							props.fromComponent === "customDashboard")
					) {
						let urlData: any = {};
						const query = new URLSearchParams(location.search);
						query.forEach((v, k) => {
							urlData[k] = v;
						});

						// if(urlData['tooltipList'].length > 0) {
						//     const tooltipIds:any [] = urlData['tooltipList'].split('&guide_id=');
						//     const checkboxes: any = document.getElementsByClassName('tooltipChk');
						//     if (checkboxes) {
						//         for (var i = 0; i < checkboxes.length; i++) {
						//             if (checkboxes[i].type === 'checkbox') {
						//                 checkboxes[i].checked = false;

						//                 const elem = document.getElementById("label_" + checkboxes[i].id);
						//                 if (elem) {
						//                     elem.style.backgroundColor = '#ffffff';
						//                 }
						//             }
						//         }
						//     }

						//     let selected_list: any = [];

						//     for(let itr0 = 1; itr0 < tooltipIds.length; itr0++) {
						//         const inputElem = document.getElementById(tooltipIds[itr0]) as HTMLInputElement;
						//         const label_Elem = document.getElementById("label_" + tooltipIds[itr0]);

						//         if (inputElem && label_Elem) {
						//             inputElem.checked = true;
						//             label_Elem.style.backgroundColor = '#f3f8ff';
						//             selected_list.push(+tooltipIds[itr0]);
						//         }
						//     }

						//     setTooltipModal((prevState: any) => {
						//         return {
						//             ...prevState,
						//             checkList: selected_list,
						//             selectedList: selected_list,
						//             selectCount: selected_list.length
						//         }
						//     })
						// }
						// else {
						for (var i = 0; i < checkboxes.length; i++) {
							if (checkboxes[i].type === "checkbox") {
								checkboxes[i].checked = true;
								const elem = document.getElementById("label_" + checkboxes[i].id);
								if (elem) {
									elem.style.backgroundColor = "#f3f8ff";
									selected_list.push(+checkboxes[i].id);
								}
							}
						}

						// If modal is loaded from customDashboard and all tooltip filters are added then mark all checked and dispetched the list for api calls
						if (
							props?.defaultSelected?.length - 1 <= 0 &&
							props.fromComponent === "customDashboard"
						) {
							let guide_ids = "";
							for (let i = 0; i < tooltipModal.listData.length; i++) {
								guide_ids = guide_ids.concat(
									"&guide_id=" + tooltipModal.listData[i]["guide_id"]
								);
							}

							dispatch(setTooltipFilter(guide_ids));
							props.dispatchGuidesList(guide_ids);
						}
						// }
					} else {
						// If chart is attached with some selected tooltip filter options
						if (props.defaultSelected.length - 1 > 1) {
							defaultSelectedTooltip();
						}
					}

					setTooltipModal((prevState: any) => {
						return {
							...prevState,
							checkList: selected_list,
							selectedList: selected_list,
							selectCount: selected_list.length,
						};
					});
				}
			} else if (tooltipModal.listData.length > 0 && !tooltipModal.firstLoad && !tooltipModal.clearFlag) {
				let data: number[] = [
					...tooltipModal.selectedList,
					...tooltipModal.checkList,
				];
				for (let i = 0; i < data.length; i++) {
					const label_elem = document.getElementById("label_" + data[i]);
					const input_elem = document.getElementById(
						data[i].toString()
					) as HTMLInputElement;

					if (label_elem && input_elem) {
						label_elem.style.backgroundColor = "#f3f8ff";
						input_elem.checked = true;
					}
				}
			}
		} catch (error) {
			showBoundary(error)
		}

	}, [tooltipModal.firstLoad, tooltipModal.listData, fetched_details_language.languageData]);

	const defaultSelectedTooltip = () => {
		try {
			let selected_list: any = [];

			setTimeout(() => {
				if (tooltipModal.firstLoad) {
					for (var i = 0; i < props.defaultSelected.length; i++) {
						if (props.defaultSelected.length) {
							const inputElem = document.getElementById(
								props.defaultSelected[i]
							) as HTMLInputElement;
							const label_Elem = document.getElementById(
								"label_" + props.defaultSelected[i]
							);

							if (inputElem && label_Elem) {
								inputElem.checked = true;
								label_Elem.style.backgroundColor = "#f3f8ff";
								selected_list.push(+props.defaultSelected[i]);
							}
						}
					}

					setTooltipModal((prevState: any) => {
						return {
							...prevState,
							checkList: selected_list,
							selectedList: selected_list,
							selectCount: selected_list.length,
						};
					});

					let guide_ids = "";
					for (var i = 0; i < props.defaultSelected.length; i++) {
						if (props.defaultSelected[i].length) {
							guide_ids = guide_ids.concat(
								"&guide_id=" + props.defaultSelected[i]
							);
						}
					}

					dispatch(setTooltipFilter(guide_ids));
					props.dispatchGuidesList(guide_ids);
				} else {
					let data: number[] = [
						...tooltipModal.selectedList,
						...tooltipModal.checkList,
					];
					for (let i = 0; i < data.length; i++) {
						const label_elem = document.getElementById("label_" + data[i]);
						const input_elem = document.getElementById(
							data[i].toString()
						) as HTMLInputElement;

						if (label_elem && input_elem) {
							label_elem.style.backgroundColor = "#f3f8ff";
							input_elem.checked = true;
						}
					}
				}
			}, 100);
		} catch (error) {
			showBoundary(error)
		}

	};

	const applyGuideList = () => {
		try {
			let selected_list: any = [];
			const checkboxes: any = document.getElementsByClassName("tooltipChk");
			if (checkboxes) {
				let guide_ids: string = "";
				for (var i = 0; i < checkboxes.length; i++) {
					if (checkboxes[i].checked) {
						if (!isNaN(+checkboxes[i].id)) {
							selected_list.push(+checkboxes[i].id);
							guide_ids = guide_ids.concat("&guide_id=" + checkboxes[i].id);
						}
					}
				}

				// if (
				// 	JSON.stringify(selected_list) !==
				// 	JSON.stringify(tooltipModal.selectedList)
				// 	) {	
				setTooltipModal((prevState: any) => {
					return {
						...prevState,
						firstLoad: false,
						btnText: selected_list.length,
						selectedList: [...selected_list],
						selectCount: selected_list.length,
					};
				});

				dispatch(setTooltipFilter(guide_ids));
				props.dispatchGuidesList(guide_ids);
				// }
			}
		} catch (error) {
			showBoundary(error)
		}

	};

	const apiCatchError = (index: number, option: number) => {
		try {
			setErrorCount((prevState: any) => {
				return {
					...prevState,
					errorCount: errorCount.errorCount.map((item: any, i: any) =>
						i === index ? option : item
					),
				};
			});
		} catch (error) {
			showBoundary(error)
		}

	};

	const clearTooltips = () => {
		try {
			setTimeout(() => {
				const checkboxes: any = document.getElementsByClassName("tooltipChk");
				if (checkboxes) {
					for (var i = 0; i < checkboxes.length; i++) {
						if (checkboxes[i].type === "checkbox") {
							checkboxes[i].checked = false;

							const elem = document.getElementById("label_" + checkboxes[i].id);
							if (elem) {
								elem.style.backgroundColor = "#ffffff";
							}
						}
					}
				}
			}, 100);

			setTooltipModal((prevState: any) => {
				return {
					...prevState,
					okDisabled: true,
					clearFlag: true,
					firstLoad: false,
					selectCount: 0,
				};
			});
		} catch (error) {
			showBoundary(error)
		}

	};

	useEffect(() => {
		setTooltipModal((prevState: any) => {
			return {
				...prevState,
				okDisabled: tooltipModal.checkList.length > 0 ? false : true,
				//clearFlag: tooltipModal.checkList.length > 0 ? false : true,
			};
		});
	}, [tooltipModal.checkList]);

	useEffect(() => {
		try {
			let urlData: any = {};
			const query = new URLSearchParams(location.search);
			query.forEach((v, k) => {
				urlData[k] = v;
			});

			if (urlData["tooltipList"]) {
				const tooltipIds: any[] = urlData["tooltipList"].split("&guide_id=");
				let btnText = tooltipIds.length - 1 <= 0 ? "All" : tooltipIds.length - 1;

				setTooltipModal((prevState: any) => {
					return {
						...prevState,
						btnText: btnText,
						selectCount: tooltipIds.length - 1,
					};
				});

				dispatch(setTooltipFilter(urlData["tooltipList"]));
				props.dispatchGuidesList(urlData["tooltipList"]);
			} else {
				dispatch(setTooltipFilter(""));
				props.dispatchGuidesList("");
			}
		} catch (error) {
			showBoundary(error)
		}

	}, []);

	const selectGuide = (event: any, guideId: any) => {
		try {
			const label_element = document.getElementById(
				"label_" + guideId
			) as HTMLLabelElement;
			const input_element = document.getElementById(
				guideId.toString()
			) as HTMLInputElement;

			if (label_element && input_element) {
				if (event.target.checked) {
					label_element.style.backgroundColor = "#f3f8ff";
					input_element.checked = true;

					setTooltipModal((prevState: any) => {
						return {
							...prevState,
							okDisabled: false,
							firstLoad: false,
						};
					});

					setTooltipModal((prevState: any) => {
						return {
							...prevState,
							checkList: [...tooltipModal.checkList, guideId],
							selectCount: tooltipModal.selectCount + 1,
						};
					});
				} else {
					label_element.style.backgroundColor = "#ffffff";
					input_element.checked = false;

					let data: number[] = tooltipModal.checkList;

					const index = data.indexOf(guideId);
					if (index > -1) {
						data.splice(index, 1);
					}

					setTooltipModal((prevState: any) => {
						return {
							...prevState,
							checkList: data,
							okDisabled: data.length > 0 ? false : true,
							selectCount: tooltipModal.selectCount - 1,
						};
					});
				}
			}
		} catch (error) {
			showBoundary(error)
		}

	};

	const getTooltipFilterVal = (value: string) => {
		try {
			if (value === 'All' || value === 'all') {
				return `Tooltips (${insightsLbls.all})`
			} else {
				return `Tooltips (${value})`
			}
		} catch (error) {
			showBoundary(error)
		}
	}

	return (
		<div className="tooltipModal-container">
			<div className="marginRight-5">
				<div className="tooltipFilterTitle">{insightsLbls.tooltipFilterTitle}</div>

				<button
					onClick={() => openModal()}
					className="source-but btn displayFlex"
					type="button"

				>
					<i className="fa fa-filter marginTop-4 marginRight-10"></i>
					{(getTooltipFilterVal(tooltipModal.btnText.toString()))}
				</button>
			</div>


			<CommonModal
				modalState={tooltipModal.modalOpen}
				dispatchModalState={closeModal}
				dispatchModalOk={applyGuideList}
				dispatchModalCancel={clearTooltips}
				modalTitle={insightsLbls.tooltipList}
				okBtn={insightsLbls.apply}
				cancelBtn={insightsLbls.clearAll}
				okBtnState={tooltipModal.selectCount === 0}
			>
				<>
					<div className="col-lg-12 paddingLR-30 searchBox inline-block">
						<p className="pull-right search-field-wrapper floatRT marginBottom0">
							<input
								type="text"
								className="positionRelative right15 searchIcon form-control round-input margin10"
								aria-label="Search"
								placeholder={insightsLbls.search}
								autoComplete="off"
								onChange={(event) => onSearchText(event)}
							/>
						</p>
					</div>
					<div className="tooltipList">
						{tooltipModal.listFetched ? (
							errorCount.errorCount[0] === 0 ? (
								tooltipModal.listData.length === 0 ? (
									<div className="noDataText-font textCenter-absolute">
										{insightsLbls.noRecordFound}
									</div>
								) : (
									<>
										{tooltipModal.listData.map((data: any, index: number) => {
											return (
												<label
													className={`check-container guideListCard stpesLabel displayBlock alignCenter ${data.is_deleted === 1 ? "deletedToolTipLabel" : ""
														}`}
													key={data.guide_id}
													id={"label_" + `${data.guide_id}`}
												>
													<input
														type="checkbox"
														className="chk checkbox tooltipChk"
														name="chk"
														id={data.guide_id}
														value={data.guide_id}
														onClick={(event) =>
															selectGuide(event, data.guide_id)
														}
													/>
													<span className="checkmark checkmarkBox-color displayInlineBlock"></span>
													<span
														className={`toolTipTitleOverflow " ${data.is_deleted === 1 ? "_85Width" : "_100Width"
															}`}
														onMouseEnter={(event) =>
															setToolTipPosition(
																event.clientY > window.innerHeight * 0.5
															)
														}
													>
														{data.guide_title}
														<span
															className={
																isTooltipTop
																	? "tooltiptextTop"
																	: "tooltiptextBottom"
															}
														>
															{data.guide_title}
														</span>
													</span>
													{data.is_deleted === 1 ? (
														<span className="_16Width stepDeleted">
															{"(" + insightsLbls.deleted + ")"}
														</span>
													) : null}
												</label>
											);
										})}
									</>
								)
							) : (
								<h5 className="errorCss card-heading font-weight-400 text-center">
									{insightsLbls.serviceNotAvailable}
								</h5>
							)
						) : (
							<div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight300">
								<div className="displayFlex alignCenter margin-0-auto">
									<Loader></Loader>
								</div>
							</div>
						)}
					</div>
				</>
			</CommonModal>
		</div>
	);
};

export default React.memo(TooltipModal);