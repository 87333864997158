if (GmXt === undefined) var GmXt = {};
GmXt.playerLbls = {};
GmXt.playerLbls.fr_CA = {
    "yAxisLabelCount": "Nombre",
    "playedCount": "Nombre joué",
    "completedCount": "Nombre achevé",
    "expandViews": "Développer les vues",
    "collapseViews": "Réduire les vues",
    "expand": "Développer",
    "collapse": "Réduire",
    "totalSteps": "Nombre total d'étapes",
    "slideshowStats": "Statistiques du diaporama",
    "videoStats": "État de la vidéo",
    "view": "Afficher",
    "deleted": "Supprimé",
    "published": "Publié",
    "unpublished": "Non publié",
    "unknown": "Inconnu",
    "allUsers": "Tous les utilisateurs",
    "download": "Télécharger",
    "viewOnline": "Afficher en ligne",
    "or": "Ou",
    "cioDashboardSubTitle": "Activité dans toutes les applications",
    "cioDashboardModalTitle": "Exporter les données",
    "play": "Lire",
    "totalPlayed": "Nombre total de lectures",
    "uniquePlayed": "Lecture unique",
    "completed": "Terminé",
    "pass": "Réussir",
    "fail": "Échec",
    "panel": "Panneau",
    "withAudio": "Avec audio",
    "withouAudio": "Sans audio",
    "linkedTable": "Tableau lié",
    "showGuideWithActivities": "Afficher les Guides avec les activités",
    "submitted": "Soumis",
    "cancelled": "Annulé",
    "hideDeleted": "Masquer ce qui est supprimé",
    "totalPlayCount": "ShowMe le nombre total de lectures",
    "uniquePlayCount": "ShowMe le nombre de lectures uniques",
    "testMeTotalPlayCount": "TestMe le nombre total de lectures",
    "testMeUniquePlayCount": "TestMe le nombre de lectures uniques",
    "passCount": "Test Me Nombre total de réussites",
    "failCount": "Test Me Nombre total d'échecs",
    "searchCount": "Nombre de fois où la recherche a été effectuée.",
    "actionCount": "Nombre de fois qu'une action a été effectuée lorsque les résultats de la recherche ont été affichés.",
    "scheduledPlay": "Lecture programmée",
    "manualPlay": "Lecture manuelle",
    "playedBy": "Joué par",
    "runType": "Type d'exécution",
    "result": "Résultat",
    "status": "État",
    "srNo": "N° de série",
    "guideTitle": "Titre du Guide",
    "description": "Description",
    "moreInfo": "Plus d’informations",
    "jobPrioritisedSuccessMsg": "Emploi priorisé avec succès!!",
    "apiCacheDeletedMsg": "Cache de l'API supprimé avec succès.",
    "guideCreated": "Guides créés",
    "guideModified": "Guides modifiés",
    "exportJobs": "Exporter les emplois",
    "searchOrganization": "Rechercher une organisation",
    "showing": "Affichage",
    "exportJobsList": "Exporter la liste des emplois",
    "organization": "Organisation",
    "requestTime": "Heure de la demande",
    "exportJobStatus": "Exporter l'état de l'emploi",
    "pending": "En attente",
    "priortizeJob": "Prioriser l'emploi",
    "priortized": "Priorisé.",
    "inProcess": "En cours",
    "complete": "Terminer",
    "pendingEventsCount": "Nombre d'événements en attente",
    "deleteCache": "Supprimer le cache",
    "priortizeEventsForOrg": "Prioriser les événements pour cette organisation.",
    "tokenCancelledMsg": "Opération annulée en raison d'une nouvelle demande.",
    "selectAll": "Sélectionner tout",
    "application": "Application",
    "appList": "Liste des applications",
    "close": "Fermer",
    "searchApp": "Rechercher une application",
    "totalApps": "Nombre total d'applications",
    "active": "actif",
    "deletedApp": "Application supprimée",
    "operatingSystem": "Système d'exploitation",
    "screenResolution": "Résolution d'écran",
    "webBrowser": "Navigateur Web",
    "name": "Nom",
    "version": "Version",
    "count": "Nombre",
    "visitors": "Visiteurs",
    "includeWeekend": "Inclure les week-ends",
    "last7Days": "Les 7 derniers jours",
    "last30Days": "Les 30 derniers jours",
    "last60Days": "Les 60 derniers jours",
    "last90Days": "Les 90 derniers jours",
    "last180Days": "Les 180 derniers jours",
    "last365Days": "Les 365 derniers jours",
    "lastCalendarYear": "Dernière année civile",
    "customDate": "Date personnalisée",
    "weekendsIncluded": "Week-ends inclus",
    "weekendsExcluded": "Week-ends exclus",
    "withRealTimeData": "Avec des données en temps réel",
    "realTimeDataNotAvailable": "Aucune donnée en temps réel n'est disponible",
    "includeRealTimeData": "Inclure les données en temps réel",
    "excludeWeekends": "Exclure les week-ends",
    "from": "De",
    "to": "Au",
    "invalidStartDate": "Date de début non valide",
    "invalidEndDate": "Date de fin non valide",
    "selectDateRangeWithin12mmonths": "Sélectionnez une plage de dates dans les 12 mois",
    "successfullyAdded": "Ajout réussi!",
    "dashboardName": "Nom d'Dashboard",
    "duplicateDashboardName": "Nom de tableau de bord en double",
    "canAddUpto3Dashboards": "Vous pouvez ajouter jusqu'à 3 tableaux de bord.",
    "dashboardName20Chars": "Le nom du Dashboard ne doit pas comporter plus de 20 caractères",
    "pleaseEnterValidName": "Veuillez saisir un nom valide.",
    "successfullyDeleted": "Supprimé avec succès!",
    "successfullyUpdated": "Mis à jour avec succès!",
    "chartsAlreadyAdded": "Graphique déjà ajouté dans",
    "createNewDashboardMsg": "Vous n'avez pas de tableaux de bord personnalisés, veuillez créer un nouveau Dashboard.",
    "createNewDashboard": "Créer un nouveau Dashboard",
    "allDashboardDataDeleted": "Tous les données du Dashboard seront supprimées.",
    "wantToDeleteDashboard": "Voulez-vous supprimer ce tableau de bord?",
    "save": "Enregistrer",
    "requestSent": "Demande envoyée!",
    "requestCompleteTime": "Heure de fin de la demande",
    "jobStatus": "État de l'emploi",
    "type": "Type",
    "surveyType": "Type de Survey",
    "surveyName": "Nom d'Survey",
    "stepName": "Nom de l'étape",
    "selectFilter": "Sélectionner le filtre",
    "publicURL": "URL publique",
    "filter": "Filtre",
    "filter1Placeholder": "Filtre 1 (nom du Guide, nom de l'étape, nom du Tooltip)",
    "filter2Placeholder": "Filtre 2 (nom de l'étape)",
    "tooltipList": "Liste de Tooltip",
    "clearAll": "Clear All",
    "tooltipPlay": "Lecture du Tooltip",
    "played": "Lu",
    "lastPlayedOn": "Lu pour la dernière fois",
    "activityDetails": "Détails d'activité",
    "exportExcel": "Exporter au format Excel",
    "downloading": "Téléchargement en cours...",
    "exportPDF": "Exporter au format PDF",
    "editFunnel": "Modifier l'entonnoir",
    "clickHereToCreateFunnel": "Cliquez ici pour créer un entonnoir",
    "step": "Étape",
    "funnelStepsInOrder": "Étapes de l'entonnoir dans l'ordre",
    "funnelStepsMsg": "Veuillez sélectionner 3 étapes au minimum et 7 étapes au maximum pour afficher l'entonnoir.",
    "funnelStepsStatsMsg": "* Les statistiques des guides liés peuvent être consultées sur leurs pages de détails respectives.",
    "max7Steps": "7 étapes au maximum peuvent être sélectionnées.",
    "clearList": "Effacer la liste",
    "submit": "Soumettre",
    "playedVsCompleted": "Lu contre Terminé",
    "deleteChartMsg": "Voulez-vous supprimer ce graphique?",
    "youAreHere": "Vous êtes ici",
    "successfullyRenamed": "Renommé avec succès!",
    "dashboardName20CharsErrorMsg": "*Le nom du Dashboard ne peut pas dépasser 20 caractères",
    "dashboardNameEmptyErrorMsg": "*Le nom du Dashboard ne peut pas être vide",
    "maintenanceInProgress": "Maintenance en cours.",
    "pleaseContactUs": "Veuillez nous contacter via",
    "backToList": "Retour à la liste",
    "title": "Titre",
    "filterTitle": "Titre du filtre",
    "max100chars": "100 caractères maximum autorisés",
    "filterDescription": "Description du filtre",
    "selectOrganizations": "Sélectionner des organisations",
    "deleteFilter": "Supprimer le filtre",
    "deleteFilterMsg": "Voulez-vous supprimer ce filtre?",
    "switchOrganization": "Changer d'organisation",
    "ofPages": "DE PAGES",
    "ofPagesViews": "DE VUES PAR PAGES",
    "untaggedURLS": "URL NON MARQUÉES",
    "taggedURLS": "URL MARQUÉES",
    "singleSelect": "Sélection unique",
    "multiSelect": "Multi-sélection",
    "text": "Texte",
    "range": "Plage",
    "rating": "Classement",
    "yesNo": "Oui-Non",
    "URLHostname": "Nom d'hôte de l'URL",
    "URLPath": "Chemin de l'URL",
    "URLParameters": "Paramètres de l'URL",
    "URLHash": "Hash d'URL",
    "equals": "Égal à",
    "notEquals": "Non égal à",
    "contains": "Contient",
    "notContains": "Ne contient pas",
    "startsWith": "Commence par",
    "similarTo": "Similaire à",
    "endsWith": "Se termine par",
    "commonMsg": "Erreur : chemin invalide, uniquement des caractères alphanumériques et des caractères spéciaux (~,=,<,>,/,* uniquement) sont autorisés, les espaces ne sont pas autorisés dans la chaîne de chemin",
    "slashMsg": "Erreur : le chemin commencera toujours par une barre oblique",
    "angularBracketMsg": "Erreur : utilisez des crochets angulaires ouverts et fermés (<>) contenant une chaîne",
    "tilEqAngularMsg": "Erreur : il est impossible d'avoir <> et ~= ensemble après une barre oblique",
    "tilEqTogetMsg": "Erreur : il est impossible d'avoir ~ ou = seul dans le chemin, ~ sera toujours suivi de =",
    "starAloneMsg": "Erreur : il est impossible d'avoir quoi que ce soit dans les barres obliques s'il y a une étoile (*) déjà présente, une seule étoile (*) sera toujours placée entre deux barres obliques",
    "twoStarMsg": "Erreur : il est impossible d'avoir quoi que ce soit après ** (double étoile) dans la chaîne de chemin",
    "noDoubleSlashMsg": "Erreur : les doubles barres obliques ne sont pas autorisées dans la chaîne de chemin",
    "onlyHyphenUnderMsg": "Erreur : seuls _ et - sont autorisés entre crochets (<>)",
    "noHyphenUnderMsg": "Erreur : _ et - ne sont autorisés qu'entre crochets (<>)",
    "filedRequired": "Erreur : ce champ est obligatoire",
    "hashMsg": "Erreur : hash d'URL non valide",
    "paramaterAlphAndSpecialChar": "Erreur : paramètre non valide, & suivi de caractères alphanumériques et les caractères spéciaux (-, _) sont autorisés",
    "parameterStartMsg": "Erreur : paramètre non valide, il commencera toujours par un? et ne peut pas comporter de? suivi de = dans le paramètre",
    "parameterAllowedMsg": "Erreur : paramètre non valide, seuls les caractères alphanumériques et les caractères spéciaux (-, _, =, &) sont autorisés",
    "urlTypeSelected": "Le type est déjà sélectionné. Veuillez sélectionner n'importe quel autre type.",
    "errorHostnameAlreadyExists": "Erreur : le nom d'hôte existe déjà",
    "errorValueAlreadyExsts": "Erreur : la valeur existe déjà",
    "URLHostnameMsg": "Le nom d'hôte de l'URL est hérité du paramètre de l'application. Et est la règle par défaut pour toutes les balises et les flux de travail d'une application",
    "and": "ET",
    "add": "Ajouter",
    "addGroup": "Ajouter un groupe",
    "searchStep": "Rechercher une étape",
    "searchUser": "Rechercher un utilisateur",
    "selectGuide": "Sélectionner un guide",
    "segmentFilter": "Filtre de segments",
    "selectSegments": "Sélectionner les segments",
    "summary": "Récapitulatif",
    "edit": "Éditer",
    "delete": "Supprimer",
    "bestTime": "Meilleur temps",
    "avgTime": "Temps intermédiaire",
    "worstTime": "Pire temps",
    "tagPage": "Attribuer un tag à la Page",
    "label": "Label",
    "cancel": "Annuler",
    "deletePageTag": "Supprimer le tag de la Page",
    "deleteConfirmation": "Voulez-vous vraiment supprimer le tag de cette Page?",
    "confirm": "Confirmer",
    "create": "Créer",
    "tagged": "Étiquetée",
    "untagged": "Non marqué",
    "domainMessage": "L'application n'a actuellement pas de domaine ajouté. Veuillez ajouter un domaine pour créer ou marquer la Page.",
    "workflowType": "Type de Workflow",
    "dynamicWorkflowPlayCount": "Nombre de lectures de Workflow dynamique",
    "guideWorkflowPlayCount": "Nombre de lectures de Workflow de Guide",
    "uniqueWorkflowPlayCount": "Nombre de lectures de Workflow unique",
    "workflowDetails": "Détails sur Workflow",
    "workflowPlayCounts": "Nombre de lectures des workflows",
    "positive": "Positif",
    "neutral": "Neutre",
    "negative": "Négatif",
    "surveyDetails": "Détails sur Survey",
    "copied": "Copié!",
    "allowView": "Autoriser la vue anonyme",
    "linkExpiry": "Le lien expire",
    "change": "Modifier",
    "ok": "Ok",
    "showList": "Afficher la liste",
    "surveyTitle": "Titre du Survey",
    "markGlobal": "Marquer comme global",
    "availableAllAppsInAcc": "Disponible dans toutes les applications du compte",
    "mandatoryForExtSurvey": "Obligatoire pour quitter l'Survey",
    "enterDiffQuestType": "Veuillez saisir une question différente du même type",
    "linkDiffPage": "Impossible de lier la même page pour la question. Veuillez lier une autre page.",
    "linkQuest": "LIER LES QUESTIONS",
    "addOption": "Ajouter une option",
    "analyzer": "Analyseur",
    "must3CharLong": "Le titre doit comporter au moins 3 caractères.",
    "threeStarRating": "Notation 3 étoiles",
    "addPage": "Ajouter un Page",
    "mandatory1Analyzer": "Cela ne peut pas être désélectionné. Une question de l'analyseur est obligatoire.",
    "changeAnalyzerQuest": "Voulez-vous vraiment modifier la question de l'analyseur?",
    "set1QuestAsAnalyzer": "(Remarque : Une seule question peut être définie en tant que question de l'analyseur.)",
    "conditionOfMoreThan3": "Cela ne peut pas être sélectionné comme question de branchement si le nombre d'options est supérieur à 3.",
    "noteAboutoptionsReduction": "(Remarque : resélectionnez à nouveau après avoir réduit le nombre total d'options à 3)",
    "QuestForChangingBranchQuest": "Voulez-vous vraiment modifier la question de branchement?",
    "noteBranchQuestLimit": "(Remarque : une seule question par page peut être définie comme question de branchement.)",
    "removeBranching": "Cette action supprimera le branchement de la question sélectionnée.",
    "addBranching": "Cette action ajoutera le branchement à la question sélectionnée.",
    "blankLinkMes": "Un lien vide signifie qu'aucun lien n'est associé",
    "goalDeleteConfirmation": "Voulez-vous vraiment supprimer cet objectif?",
    "viewDetails": "Afficher les détails",
    "goalLiveOn": "Goal en direct",
    "targetCompletion": "Finalisation de la cible",
    "currentStatus": "État actuel",
    "click": "Cliquer",
    "iconForNewGoal": "sur l'icône pour créer un nouvel Goal",
    "clickForEditMessage": "Cliquez sur l'objectif dans la liste pour modifier cet objectif particulier",
    "goalTitle": "Titre du Goal",
    "goalExistsMsg": "L'Goal existe déjà. Sélectionnez un autre titre.",
    "goalDescription": "Description du Goal",
    "startTimeMsg": "La date/heure de début doit être antérieure de 30 minutes par rapport à la date/heure actuelle",
    "endTimeMsg": "La date/heure de fin doit être postérieure de 24 heures à la date/heure de départ",
    "goalStartDate": "Date de début de l'Goal",
    "goalEndDate": "Date de fin de l'Goal",
    "canAddupto": "Peut ajouter jusqu'à",
    "guideList": "Liste de Guide",
    "playCount": "Nombre de lectures",
    "eventSource": "Origine de l'événement",
    "playMode": "Mode de lecture",
    "msgDoitFormeNotselect": "(lorsque seuls les guides didacticiels sont choisis, le mode de lecture\"Do it for me\" ne doit pas être sélectionné)",
    "msgCannotEditGoal": "Impossible de modifier l'objectif une fois publié. Voulez-vous vraiment publier cet objectif?",
    "goalEmailNotification": "Les notifications par e-mail relatives aux Goal sont actuellement désactivées dans vos préférences. Vous risquez de passer à côté de notifications importantes sur les jalons des objectifs.",
    "updatePreference": "Pour mettre à jour vos préférences à tout moment, vous pouvez vous rendre sur",
    "goalNotificationPreference": "Préférences de notification relatives aux Goal",
    "goalDetails": "Détails sur Goal",
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "eventSources": "Origines de l'événement",
    "playModes": "Modes de lecture",
    "targetPlayCount": "Nombre de lectures cibles",
    "oneEmailPublishedGuides": "Un e-mail par jour, contenant les détails de tous les échecs d'étape survenus au cours de la journée pour les guides publiés.",
    "oneEmailonSundays": "Un e-mail par semaine résumant l'utilisation de MyGuide dans toute l'organisation. Les e-mails seront envoyés le dimanche (GMT).",
    "receiveEmailOnMilestone": "Cette option vous permet de recevoir des notifications par e-mail chaque fois qu'un jalon est atteint (50 %, 80 % et 100 %). Considérant le fait que vous anticipez l'objectif,",
    "receiveEmailOnAchievement": "cette option vous permet de recevoir des e-mails chaque fois qu'un objectif est atteint. Considérant le fait que vous anticipez l'objectif,",
    "emailNotificationPreference": "Préférences de notification par e-mail",
    "detail": "Détails",
    "moreDetails": "Plus de détails",
    "lessDetails": "Moins de détails",
    "requestSentOn": "Demande envoyée le",
    "requestCompletedOn": "Date de fin de la demande",
    "requestCompletedvia": "Demande effectuée par",
    "requestSourcedAt": "Demande provenant de",
    "requestGuideType": "Demander un type de guide",
    "appsSelectedForRequest": "Applications sélectionnées pour la demande",
    "dataNotAvailable": "Données non disponibles.",
    "exportViaEmail": "Exporter par e-mail",
    "selectedDateRange": "Votre plage de dates sélectionnée",
    "selectedApps": "Vos applications sélectionnées",
    "selectedDataSources": "Vos sources de données sélectionnées",
    "noteAboutExclusion": "Note :Tooltip exclut Mobile Creator et Web Creator",
    "selecetdEventSource": "Votre source d'événement sélectionnée",
    "placeRequestLimitMsg": "Vous ne pouvez placer une demande que si vous n'avez aucune demande en attente au cours des dernières 24 heures.",
    "textCommentsFormat": "Il affiche toutes les réponses fournies par les utilisateurs au format texte (commentaires).",
    "pieChartRepresentating": "Un graphique à secteurs représentant le pourcentage et le nombre d'options répondues par tous les utilisateurs.",
    "tabularRepresentationOfResponse": "Une représentation tabulaire du nombre de réponses et du pourcentage de chaque option.",
    "vennDiagramCombinationOption": "Un diagramme de Venn présentant une combinaison des options ayant reçu une réponse de la part des utilisateurs.",
    "tableOfCombinationOption": "Une représentation sous forme de tableau de toutes les combinaisons d'options avec leur nombre de réponses et leur pourcentage.",
    "barforRating": "Le graphique à barres représente la notation fournie par les utilisateurs.",
    "tableForCounts": "Le tableau représente les notations en chiffres et en pourcentages.",
    "barForRatings": "Le graphique à barres représente la notation en étoiles fournie par les utilisateurs.",
    "tableForRatings": "Le tableau représente les notations en étoiles en chiffres et en pourcentages.",
    "barChartForYesNo": "Le graphique à barres représente les réponses \"Oui\" et \"Non\".",
    "tableofCountsPercentage": "Le tableau représente les chiffres et les pourcentages.",
    "timeLine": "Échéancier",
    "detailsNotAvailable": "Les détails ne sont pas disponibles pour cette instance.",
    "textType": "Type de texte",
    "selectSingleType": "Type de sélection unique",
    "multiSelectType": "Type de sélection multiple",
    "rangeType": "Type de plage",
    "starRatingType": "Type de notation en étoiles",
    "yesnoType": "Type Oui/Non",
    "responses": "Réponses",
    "excelViewerQn": "Vous n'avez pas d'afficheur Excel?",
    "viewPlayDetails": "Afficher les détails de lecture",
    "feedback": "Feed-Back",
    "noResponseYet": "Pas encore de réponse",
    "responseStats": "Statistiques de réponse",
    "answerChoices": "CHOIX DE RÉPONSES PROPOSÉES",
    "responseCount": "NOMBRE DE RÉPONSES",
    "total": "TOTAL",
    "max5AppsSelected": "5 applications au maximum peuvent être sélectionnées.",
    "player": "Lecteur",
    "webCreator": "Web Creator",
    "webPlayer": "Web Player",
    "desktopCreator": "Desktop Creator",
    "mobileCreator": "Mobile Creator",
    "mobilePlayer": "Mobile Player",
    "dataTooltiptext7Days": "Les données peuvent prendre jusqu'à 7 jours pour apparaître sur le portail à partir de la survenue de l'événement réel.",
    "password1SpecialChar": "Le champ Mot de passe doit contenir au moins un caractère spécial.",
    "password1Numeric": "Le champ Mot de passe doit contenir une valeur numérique.",
    "passwordLowerCase": "Le champ Mot de passe doit contenir au moins un caractère en minuscules.",
    "passwordUpperCase": "Le champ Mot de passe doit contenir au moins un caractère en majuscules.",
    "passwordConfirmationField": "Le champ Confirmation du mot de passe doit être égal au champ Mot de passe.",
    "password8Char": "Le mot de passe de confirmation doit comporter 8 caractères!",
    "newPassword8Char": "Le nouveau mot de passe doit comporter 8 caractères!",
    "passwordChanged": "Le mot de passe a été correctement modifié!",
    "curPassword": "Mot de passe actuel",
    "newPassword": "Nouveau mot de passe",
    "confirmPassword": "Confirmer le mot de passe",
    "admin": "Admin",
    "changePassword": "Changer le mot de passe",
    "logout": "Déconnexion",
    "somethingWentWrong": "Un problème est survenu",
    "showLabels": "Montrer les étiquettes",
    "max9Allowed": "Un maximum de 9 sélections est autorisé.",
    "showEntries": "Montrer les entrées",
    "activity": "Activité",
    "guideMeActivity": "Activité d'GuideMe",
    "showMeActivity": "Activité d'ShowMe",
    "testMeActivity": "Activité d'TestMe",
    "doItForMeActivity": "Activité d'DoItForMe",
    "guideTutorialActivity": "Activité de didacticiel du Guide",
    "activitiesOnGuidesonSelectApps": "Un graphique linéaire représentant les activités des guides sur les applications sélectionnées.",
    "guideCounts": "Nombres de Guide :",
    "guidesSelectedinTimeFrame": "Le nombre total de guides dans le mode sélectionné au cours de la période donnée.",
    "guidesInGuideModeinTimeFrame": "Nombre total de guides en mode GuideMe dans le délai imparti",
    "guidesInShowMeModeinTimeFrame": "Nombre total de guides en mode ShowMe dans le délai imparti.",
    "guidesInTestMeModeinTimeFrame": "Nombre total de guides en mode TestMe dans le délai imparti.",
    "guidesInSDoItForMeModeinTimeFrame": "Nombre total de guides en mode DoItForMe dans le délai imparti.",
    "tutorialGuidesinTimeFrame": "Nombre total de Tutorial Guides lus dans le délai imparti",
    "guideSummary": "Récapitulatif Guide",
    "top10Guides": "10 meilleurs guides Guides",
    "tableTop10GuidesAcrossApps": "Tableau représentant le Top 10 des Guides dans les applications.",
    "titleOfGuide": "Titre du Guide.",
    "appTitle": "Titre de l’application",
    "appToWhichGuideBelong": "Application à laquelle le Guide appartient.",
    "labels": "Libellés",
    "labelsToGuide": "Étiquettes apposées sur le Guide.",
    "noOfResponsesForGuide": "Nombre total de réponses enregistrées pour le Guide.",
    "guideDistribution": "Distribution du Guide",
    "distributionAnalyzedNegNeuPos": "Distribution des réponses du Guide analysées comme négatives, neutres et positives.",
    "lastUpdatedOn": "Date de la dernière mise à jour",
    "dateTimeofGuideModification": "Date et heure auxquelles le Guide a été modifié.",
    "lineGraphonSelectedApps": "Graphique linéaire représentant les activités des workflows sur les applications sélectionnées",
    "workflowSummary": "Récapitulatif du Workflow",
    "top10Workflows": "10 meilleurs flux de travail",
    "tableTop10WorkflowsAcrossApps": "Un tableau représentant les 10 meilleurs flux de travail dans toutes les applications.",
    "workflowTitle": "Titre du Workflow",
    "titleWorkflow": "Le titre du flux de travail.",
    "typeWorkflow": "Le type de flux de travail.",
    "walkthroughCounts": "Nombre de visites",
    "totalCountinWorkflow": "Nombre total dans un Workflow.",
    "avgTimeonWorkflow": "Le temps moyen passé sur le flux de travail.",
    "bestimeAchievedWorkflow": "Le meilleur temps réalisé sur le flux de travail.",
    "lineGraphSurveysApps": "Un graphique linéaire représentant les activités sur les enquêtes dans les applications sélectionnées.",
    "surveySummary": "Récapitulatif du Survey",
    "top10Survey": "10 meilleures enquêtes",
    "tableTop10SurveysApps": "Un tableau représentant les 10 meilleures enquêtes sur l'ensemble des applications.",
    "nameSurvey": "Nom du Survey.",
    "appToWhichSurveyBelong": "Application à laquelle l'enquête appartient.",
    "labelsToSurvey": "Étiquettes apposées sur le Survey.",
    "noOfResponsesForSurvey": "Nombre total de réponses enregistrées pour le Survey.",
    "surveyDistribution": "Distribution du Survey",
    "distributionAnalyzedSurevyNegNeuPos": "Une distribution des réponses à l'enquête analysée comme négative, neutre et positive.",
    "dateTimeofSurveyModification": "Date et heure auxquelles le Survey a été modifié.",
    "tableFilteredBasedOnApps": "Ce tableau est filtré sur la base des applications sélectionnées uniquement.",
    "locationInsights": "Emplacement des Insights",
    "whereUsersAccessFrom": "Où les utilisateurs accèdent-ils à votre application?",
    "pageStats": "Statistiques de la Page",
    "pageAcivity": "Activité d'Page",
    "blockDiagramTop50Pages": "Un diagramme de blocs représentant les 50 premières pages marquées (en fonction du nombre d'occurrences de chaque page).",
    "pageSummaryOverview": "Résumé de la Page",
    "pageDetail": "Détail de la Page",
    "collectionChartsTaggedPages": "Une collection de graphiques représentant les activités sur les pages marquées.",
    "pageName": "Nom d'Page",
    "noOfPages": "Le nom de la page.",
    "labelsFilterTaggedPages": "Les étiquettes sont utilisées pour filtrer les pages activées par des tags.",
    "pageView": "Page vues",
    "numberTimesPageViewed": "Le nombre de fois où la page a été consultée.",
    "uniqueVisits": "Visites uniques",
    "numberUniqueVisitorPage": "Le nombre de visiteurs uniques qui ont consulté la page.",
    "topPages": "Meilleures Pages",
    "topUntaggedVistis": "Les pages non étiquetées les plus visitées par les utilisateurs.",
    "pageDistribution": "Contribution à la Page",
    "chartRepresentingPage": "Graphique représentant la contribution à l'activité de la page.",
    "taggedPages": "Page étiquetée",
    "percentageContribution": "Le pourcentage de pages contribuant à 80 % des consultations.",
    "urlContibution": "Contribution à la URL",
    "chartURLActivity": "Un graphique représentant la contribution de l’activité de l'URL",
    "untaggedURL": "URL non étiquetée",
    "comparisonTaggedUntagged": "La comparaison en pourcentage des URL étiquetées et non étiquetées.",
    "pageUserActivity": "Activité de l’utilisateur sur la Page",
    "graphUserActiviyOnPage": "Graphiques représentant l’activité de l’utilisateur sur la Page.",
    "totalUserVisit": "Total des visites d'utilisateurs",
    "totalUsersOnPageinTime": "Nombre total d'utilisateurs ayant visité la page dans le temps imparti.",
    "minSessionDuration": "Durée minimale de la session",
    "maxSessionDuration": "Durée maximale de la session",
    "avgSessionDuration": "Durée moyenne de la session",
    "minTimeonPageinTime": "Temps minimum passé sur la page dans le temps imparti.",
    "maxTimeonPageinTime": "Temps maximum passé sur la page dans le temps imparti.",
    "avgTimeonPageinTime": "Temps moyen passé sur la page dans le temps imparti.",
    "whenUsersVisit": "Quand vos utilisateurs visitent-ils la page?",
    "heatMapforMaxTraffic": "Carte de chaleur représentant le moment de la journée où le trafic sur la page est le plus important.",
    "pageLoadTime": "Temps de chargement de la Page",
    "averageTime": "Temps moyen",
    "workflowVisualSummary": "Résumé visuel du Workflow",
    "workflowUsageStats": "Statistiques d’utilisation du Workflow",
    "lineGraphWorkflowPlayed": "Un graphique linéaire représente le nombre de flux de travail lus.",
    "workflowPlayCount": "Nombre de lectures du Workflow",
    "workflowPlayedInTime": "Nombre de flux de travail lus dans un laps de temps donné.",
    "uniqueWorkflowInTime": "Nombre unique de flux de travail lus dans un laps de temps donné.",
    "tableWorkflowAvgBestTime": "Tableau représentant la liste de workflows, ainsi que leur temps moyen et le meilleur temps.",
    "titleOfWorkflow": "Titre du flux de travail.",
    "associatedLabelWorkflow": "Le nom de l'étiquette à laquelle le flux de travail est associé.",
    "totalUsersWalkthrough": "Le nombre total d'utilisateurs qui ont lu le flux de travail.",
    "avgTimeWorkflow": "Le temps moyen passé sur le flux de travail.",
    "bestTimeWorkflow": "Le meilleur temps passé sur le workflow.",
    "topWorkflows": "Meilleurs workflows",
    "top5WorkFLowByUsage": "cinq meilleurs workflows par utilisation",
    "barChartTop5Workflows": "Un graphique à barres représentant les 5 meilleurs workflows par ordre décroissant de leur nombre de lectures.",
    "workflowUsageSummary": "Résumé de l’utilisation du Workflow",
    "usageSummaryGraph": "Graphique récapitulatif de l'utilisation",
    "lineChartWorkflowinTime": "Un graphique linéaire représentant le nombre de flux de travail lus par les utilisateurs dans la période donnée.",
    "uniqueUserPlayCount": "Nombre de lectures par utilisateur unique",
    "stepTitle": "Titre de l'étape",
    "stepPlayedCount": "Nombre d'étapes lues",
    "numberTimesWorkflowPlayedbyAll": "Nombre de fois où le flux de travail a été lu par tous les utilisateurs.",
    "numberTimesWorkflowPlayedbyUnique": "Nombre de fois où le flux de travail a été lu par des utilisateurs uniques.",
    "usageHeatMap": "Carte de chaleur de l'utilisation",
    "heatmapOnWorkflow": "Une carte thermique qui représente le temps passé sur le flux de travail.",
    "workflowUsageAnalysis": "Analyse de l’utilisation du Workflow",
    "scatterGraph": "Graphique de dispersion",
    "scatterPlotforBestAvgWorst": "Un graphique en nuage de points représente les meilleurs, les moyens et les pires temps dans la période donnée.",
    "bestTimeonWorkflow": "Le meilleur temps passé sur le flux de travail.",
    "averageTimeonWorkflow": "Le temps moyen passé sur le flux de travail.",
    "worstTimeonWorkflow": "Le pire temps passé sur le flux de travail.",
    "stepsTimeUsageTable": "Tableau d'utilisation du temps des étapes",
    "tableUsageStepsinWorkflow": "Un tableau montrant l'utilisation du temps des étapes du flux de travail sélectionné.",
    "titleStepWorkflow": "Le titre de l'étape dans le flux de travail.",
    "numberStepsPlayed": "Le nombre de fois où l'étape a été lue.",
    "avgTimeSpentonStep": "Le temps moyen passé sur l'étape.",
    "bestTimeSpentonStep": "Le meilleur temps passé sur l'étape.",
    "workflowUsageEngagement": "Engagement de l'utilisation du Workflow",
    "stepsFlowChart": "Diagramme de flux des étapes",
    "pathChartforWorkflow": "Un diagramme de cheminement représentant le flux des étapes dans le flux de travail sélectionné.",
    "stepDropOff": "Déclinaison des étapes",
    "funnelForworkflow": "Une représentation en entonnoir des étapes sélectionnées dans le flux de travail actuel. Vous pouvez sélectionner un minimum de 3 étapes et un maximum de 7 étapes. L'ordre de sélection n'a pas d'importance, car l'entonnoir sera rendu en fonction de l'ordre des étapes dans le flux de travail.",
    "specificUserActivity": "Activité d'un utilisateur spécifique",
    "lineProficiency": "Un graphique linéaire représente le graphique de compétence des utilisateurs individuels. Le suivi des informations sur les utilisateurs doit être activé pour afficher les données sur les compétences des utilisateurs.",
    "overallWorkflowUsage": "Utilisation globale du Workflow",
    "workflowFootfallAnalysis": "Analyse de la fréquentation du Workflow",
    "footfallGraph": "Graphique de fréquentation",
    "graphWalkThroughEntireWorkflow": "Un graphique du pourcentage de visites que chaque étape obtient par rapport au nombre total de visites de l'ensemble du flux de travail.",
    "workflowPathAnalysis": "analyse de chemin d’accès du Workflow",
    "pathChart": "Graphique de cheminement",
    "graphStepsCombinationWorkflow": "Un graphique représentant toutes les combinaisons de séquences d'étapes suivies par les utilisateurs pour un flux de travail particulier.",
    "pathChartLegend": "Légende du diagramme de cheminement",
    "legendsStepsColorCode": "Une légende présentant les étapes correspondant à leur code couleur.",
    "barGraphPosNegNeu": "Un graphique à barres représentant la distribution des réponses à l'enquête (positives, négatives et neutres).",
    "tableActitiesofSurvey": "Un tableau représentant les activités des enquêtes.",
    "titleSurveyCreated": "Titre de l'Survey créée.",
    "stats": "Statistiques",
    "labelsofSurvey": "Les étiquettes de l'enquête.",
    "numberResponsesEachSurvey": "Le nombre de réponses pour chaque enquête.",
    "responseDistribution": "Distribution des réponses",
    "graphPercentageSurevey": "Un graphique représentant le pourcentage de réponses à l'enquête.",
    "countPositiveResponses": "Le nombre de réponses positives dans la période donnée.",
    "countNeutralResponses": "Le nombre de réponses neutres dans la période donnée.",
    "countNegativeResponses": "Le nombre de réponses négatives dans la période donnée.",
    "pieChartPosNegNeu": "Un diagramme circulaire montrant le nombre et le pourcentage de réponses positives, négatives et neutres pour cette enquête.",
    "surveyQuestions": "Questions de l'Survey",
    "verifyAccount": "Vérifier le compte",
    "otpSent": "Le mot de passe à usage unique a été envoyé à votre courriel. Veuillez saisir le mot de passe à usage unique pour vérifier votre compte.",
    "otp": "Mot de passe à usage unique",
    "enterNumber": "Veuillez saisir uniquement des chiffres.",
    "didntReceiveOTP": "Vous n'avez pas reçu de mot de passe à usage unique?",
    "clicktoResendOTP": "Cliquez pour renvoyer le mot de passe à usage unique",
    "backToLogin": "Retour à la page de connexion",
    "manageGoals": "Gérer Goal",
    "goalDeletedSuccessfully": "Le Goal a été supprimé avec succès.",
    "manageSurvey": "Gérer Survey",
    "surevyStats": "Statistiques de l'Survey",
    "surveyStatsDetailed": "Statistiques détaillées de l'Survey",
    "graphActivitiesOnLiveGuide": "Un graphique représentant les activités sur les guides en direct.",
    "totalNumberOfGuidesPlayed": "Le nombre total de guides lus dans une période donnée.",
    "totalNumberOfUniqueGuidesPlayed": "Le nombre total de guides uniques lus (par utilisateur unique) dans la période donnée.",
    "totalNumberOfCompleteGuidesPlayed": "Il s'agit du nombre total de guides lus depuis la toute première étape jusqu'à la dernière étape.",
    "completionRatio": "Taux de complétion",
    "slideshowPlayedInGivenTimeFrame": "Nombre total de diaporamas lus dans la période de temps donnée.",
    "totalVideosPlayedInGivenTimeFrame": "Le nombre total de vidéos lues dans la période donnée.",
    "totalGIFdPlayedInGivenTimeFrame": "Le nombre total de GIFs lus dans la période de temps donnée.",
    "pieActivityPercentageDistribution": "Un diagramme circulaire représentant la répartition en pourcentage des guides téléchargés dans différents formats.",
    "barGraphTestMe": "Un graphique à barres représentant le nombre total d'utilisateurs ayant effectué l'activité TestMe, chaque barre étant divisée en deux parties (le nombre d'utilisateurs ayant réussi le test et le nombre d'utilisateurs ayant échoué au test).",
    "guidePlayedVieDoItForMe": "Graphique représentant les guides lus via l'automatisation DoItForMe.",
    "myGuideSurvey": "MyGuide Survey",
    "totalNuberOfGuideAndExitResponses": "Un graphique représentant le nombre total du Guide et de réponses de sortie fournies par les utilisateurs pour les guides.",
    "displayCount": "Afficher le nombre",
    "guideName": "Nom du Guide",
    "guidePlayed": "Guide lu",
    "showMePlayed": "ShowMe lu",
    "testMePlayed": "TestMe lu",
    "myGuideSurveysPlayed": "Enquêtes MyGuide lues",
    "tableRepresentingGuideActivites": "Un tableau représentant les activités des Guides.",
    "nameOfGuidesPlayed": "Le nom des guides lus.",
    "totalGuidesCompleteCount": "Le nombre total de guides, les guides uniques lus (par utilisateur unique) et le nombre total de guides lus dans la période donnée.",
    "totalSlideshowVideoGIFCount": "Le nombre total de diaporamas, de vidéos et de GIF lus dans un laps de temps donné.",
    "totalTestPassCount": "Le nombre total d'utilisateurs qui ont réussi ou échoué aux tests dans un laps de temps donné.",
    "totalResponsesSubmitted": "Le nombre total de réponses soumises par les utilisateurs pour le guide dans le temps imparti.",
    "guideMePlay": "Lecture du GuideMe",
    "guidePlayedStats": "Statistiques des Guide lus",
    "avgGuidePlayed": "Le nombre moyen de guides lus dans un laps de temps donné par utilisateur unique.",
    "guidePlayedOverview": "Vue d’ensemble des Guide lus",
    "radialChartLiveGuides": "Graphique radial représentant les activités sur les guides en direct.",
    "avgPlayed": "Moyenne des guides lus",
    "guidePlaySources": "Sources de lecture du Guide (notifications poussées, Beacons, panneau & URL)",
    "pieChartGuideSources": "Un diagramme circulaire représentant le Guide lu via les notifications poussées, les Beacons, le panneau & URL.",
    "audioGuidance": "Guidage audio",
    "audioConsumptionGuideMeShowMe": "Graphique représentant la consommation audio dans les modes GuideMe/ShowMe pris en charge.",
    "guideMeDetails": "Détails sur GuideMe",
    "detailedSummary": "Résumé détaillé",
    "tableOfLiveGuideActivities": "Un tableau représentant les activités sur les étapes des guides en direct.",
    "startStep": "Commence à cette étape",
    "endStep": "Terminé à cette étape",
    "failedOnStep": "Échec à cette étape",
    "stepsFlow": "Flux des étapes",
    "titleOfStep": "Le titre de l'étape.",
    "numberOfTimesStepPlayed": "Le nombre de fois que l'étape a été lue.",
    "stepBeganFromCurrentStep": "Combien de fois la lecture guidée a commencé à partir de l'étape actuelle.",
    "stepEndedOnCurrentStep": "Combien de fois la lecture du guide s'est-elle terminée sur l'étape en cours.",
    "stepErrors": "Combien de fois la lecture du guide s'est-elle interrompue sur cette étape en raison d'erreurs.",
    "sankeyPaths": "Diagramme de Sankey représentant les différents chemins empruntés par les utilisateurs au cours de la lecture guidée.",
    "funnelWarningMsg": "Représentation en entonnoir des étapes sélectionnées dans le guide actuel. Vous pouvez sélectionner un minimum de 3 étapes et un maximum de 7 étapes. L'ordre de sélection n'a pas d'importance, car l'entonnoir sera affiché en fonction de l'ordre des étapes du guide.",
    "graphActivitiesOnShowMe": "Un graphique représentant les activités sur ShowMe.",
    "downloadDistribution": "Un graphique représentant la distribution des guides téléchargés dans différents formats.",
    "showMePlaySources": "Sources de lecture du ShowMe (notifications poussées, Beacons, panneau & URL)",
    "pieChartShowMeModes": "Un diagramme circulaire représentant le ShowMe lu via les notifications poussées, les Beacons, le panneau & URL.",
    "guidesPlayedViaDoItForMe": "Un graphique représentant le nombre de fois où ce guide a été lu via l'automatisation DoItForMe.",
    "doItForMeShowMeSources": "Sources de lecture de DoItforMe (notifications poussées, Beacons, panneau & URL)",
    "doItForMeTableActivities": "Un tableau représentant des activités de lecture de guide en mode DoItForMe.",
    "humanInterventionCount": "Nombre d'interventions humaines",
    "humanInterventionCountTimes": "Combien de fois une étape a nécessité une intervention humaine.",
    "skippedStepCount": "Nombre d'étapes sautées",
    "skippedStepCountTimes": "Combien de fois une étape a-t-elle été sautée?",
    "failedOnStepTimes": "Combien de fois la lecture du guide a-t-elle échoué à cette étape en raison d'erreurs.",
    "myGuideSurveys": "Enquêtes MyGuide",
    "guideExitStepSurveys": "Guide, sortie-Guide & Enquêtes par étapes",
    "tutorialGuideStats": "Statistiques des guides Tutorial",
    "lineChartTutorialGuide": "Graphique linéaire représentant les statistiques des guides tutoriels.",
    "activitiesOnTutorialGraph": "Un graphique représentant les activités sur les guides tutoriels.",
    "tutorialGuideName": "Nom du TutorialGuide lu.",
    "totalNoOfTutorialGuidesPlayed": "Nombre total de guides tutoriels lus",
    "guideCompleted": "Guide Terminé",
    "totalNumberOfGuidesCompleted": "Le nombre total de guides tutoriels terminés.",
    "tutorialGuideDetailSummary": "Détails du sommaire du Tutorial Guide",
    "tableOfGuidePlayActivities": "Un tableau représentant des activités de lecture de guide.",
    "tooltipGuidanceAndValidationGraph": "Un graphique linéaire représentant les statistiques d'orientation et de validation des infobulles dans la période donnée.",
    "countOfTooltipHoverClicked": "Le nombre de fois où l'utilisateur a interagi avec l'infobulle d'une page, soit en la survolant, soit en la cliquant, soit lorsque la page a été chargée.",
    "countOfValidationPerformed": "Le nombre de fois que la validation a été effectuée sur la page.",
    "countOfValidationAndInteractionPerformed": "Le nombre de fois que les deux infobulles ont été utilisées et que la validation a été effectuée.",
    "barChartPowerForm": "Un diagramme à barres empilées représentant les statuts lorsque le formulaire de puissance a été invoqué dans le laps de temps donné.",
    "countOfSubmittedPowerForm": "Le nombre de tous les utilisateurs qui ont soumis le Power Form.",
    "countOfCancelledPowerForm": "Le nombre d'utilisateurs qui ont annulé le Power Form.",
    "tableOfPowerFormStats": "Un tableau représentant les statuts du Power Form dans la période donnée.",
    "titleOfPowerForm": "Le titre d'une Power Form contenant l'ordre des étapes et le nom du guide de l'infobulle.",
    "countOfCancelledSpecificPowerForm": "Le nombre de tous les utilisateurs qui ont annulé la Power Form en question.",
    "countOfSubmittedSpecificPowerForm": "Le nombre de tous les utilisateurs qui ont soumis le Power Form en question.",
    "tableOfStatsOfTooltip": "Un tableau représentant les statistiques de l'info-bulle dans la période donnée.",
    "nameOfTooltipContainsMoreTooltips": "Le nom du guide d'infobulles qui contient une ou plusieurs infobulles.",
    "interactionHappenedWithGuidanceType": "Le nombre de fois où l'utilisateur a interagi avec une info-bulle de type à l'intérieur d'un guide d'info-bulle spécifique.",
    "interactionHappenedWithValidationType": "Le nombre de fois où l'utilisateur a interagi avec une info-bulle de type Validation à l'intérieur d'un guide d'infobulle spécifique.",
    "interactionHappenedWithGuidanceValidationType": "Le nombre de fois où l'utilisateur a interagi avec une info-bulle de type & Validation à l'intérieur d'un guide d'info-bulle spécifique.",
    "digitalDuctTapeRenderCount": "Nombre de fois où le Duct Tape numérique a été affiché et a fait l'objet d'une action.",
    "interactionHappenedWithPowerForm": "Nombre de fois où l'utilisateur a interagi avec Power Form",
    "tooltipValidationActivity": "Activité Tooltip & Validation",
    "countOfTooltipClickedAndHovered": "Le nombre de fois où l'infobulle a été affichée sur une page, que ce soit au survol, au clic ou lors du chargement de la page.",
    "countTooltipShownAndValidationPerformed": "Le nombre de fois où les deux infobulles ont été affichées et où la validation a été effectuée.",
    "ductTapeActivity": "Activité d'Duct Tape",
    "statsOfDigitalDuctTape": "Un graphique représentant les statistiques du ruban numérique dans une période donnée.",
    "countDigitalDuctTapeDisplayed": "Le nombre de fois où le ruban numérique a été affiché sur la page.",
    "powerFormActivity": "Activité d'Power Form",
    "newUser": "Nouveaux utilisateurs",
    "totalUsers": "Nombre total d'utilisateurs",
    "engagedUsers": "Utilisateurs ayant réservé",
    "newUserCount": "Le nombre total de nouveaux utilisateurs (n'ayant ouvert aucune session au cours des 30 derniers jours) qui visitent les pages contenant des guides.",
    "numberOfUsersVisitingGuidePages": "Le nombre total d'utilisateurs visitant les pages contenant des guides.",
    "usersPerformedSomeActivity": "Utilisateurs qui ont effectué une activité avec MyGuide.",
    "lineGraphUserEngagement": "Un graphique linéaire représentant les réservations des utilisateurs.",
    "guideMeUniquePlayCount": "Nombre de lectures de Guide moi unique",
    "guideMeTotalPlayCount": "Nombre total de lectures Guide moi",
    "guideMeCompletePlayCount": "Nombre de lectures de Guide moi terminées",
    "numberOfUsersPlayedInGuideMode": "Nombre total d'utilisateurs ayant lu des guides en mode GuideMe dans une période donnée.",
    "numberOfUniqueUsersPlayedInGuideMode": "Nombre d'utilisateurs uniques ayant lu des guides en mode GuideMe dans une période donnée.",
    "numberOfTotalUsersPlayedInGuideMode": "Nombre total d'utilisateurs ayant terminé des guides en mode GuideMe dans une période donnée.",
    "doItForMeUniquePlayCount": "DoItForMe le nombre de lectures uniques",
    "doItForMeTotalPlayCount": "DoItForMe le nombre total de lectures",
    "doItForMeCompleteCount": "Nombre de lectures de DoItForMe terminées",
    "showMeTotalPlayCount": "Nombre total d'utilisateurs ayant lu des guides en mode ShowMe dans une période donnée.",
    "showMeUniquePlayCount": "Nombre d'utilisateurs uniques ayant lu des guides en mode ShowMe dans une période donnée.",
    "testMeUserTestCount": "Nombre total d'utilisateurs ayant réalisé des tests en mode TestMe dans une période donnée.",
    "testMeUniqueUserTestCount": "Nombre d'utilisateurs unique ayant réalisé le test en mode TestMe dans une période donnée.",
    "doItForMeUserPlayCount": "Nombre total d'utilisateurs ayant lu des guides en mode DoItForMe dans une période donnée.",
    "doItForMeUniqueUserPlayCount": "Nombre d'utilisateurs uniques ayant lu des guides en mode DoItForMe dans une période donnée.",
    "doItForMeCompleteUserPlayCount": "Nombre total d'utilisateurs ayant terminé des guides en mode DoItForMe dans une période donnée.",
    "tutorialGuidePlayCount": "Nombre total de lectures de TutorialGuides",
    "tutorialGuideUniquePlayCount": "Nombre de lectures uniques de Tutorial Guides",
    "tutorialGuideCompletePlayCount": "Nombre de lectures de TutorialGuides terminées",
    "tutorialGuideUserPlayCount": "Nombre total d'utilisateurs ayant lu des Tutorial Guides dans une période donnée.",
    "tutorialGuideUniqueUserPlayCount": "Nombre d'utilisateurs uniques ayant lu des Tutorial Guides dans une période donnée.",
    "tutorialGuideCompleteUserPlayCount": "Nombre total d'utilisateurs ayant terminé des Tutorial Guides dans une période donnée.",
    "userEngagementSummaryOverview": "Vue d'ensemble de la réservation des utilisateurs",
    "userEngagementSummary": "Résumé de la réservation des utilisateurs",
    "tableOfUserEngagements": "Un tableau représentant la liste des réservations des utilisateurs.",
    "email": "Courriel",
    "emailIdOfUser": "L'identifiant courriel de l'utilisateur qui a effectué une activité.",
    "nameOfUser": "Le nom de l'utilisateur qui a effectué une activité.",
    "guidePlayedByUser": "Le nombre total de guides lus par l'utilisateur au cours de la période donnée.",
    "guidesPlayedByShowMeMod": "Nombre total de guides lus par l'utilisateur en mode ShowMe dans la période donnée.",
    "guidesPlayedByTestMeMod": "Nombre total de guides lus par l'utilisateur en mode TestMe dans la période donnée.",
    "userEngagementDetailSummary": "Vue d'ensemble détaillée de la réservation des utilisateurs",
    "userEngagementDetailSummaryTable": "Tableau représentant les activités des Guides pour l'utilisateur.",
    "userEngagementDetailGuideName": "Nom des guides lus.",
    "userEngagementDetailTotalGuidePlay": "Le nombre total de guides lus dans la période donnée pour l'utilisateur.",
    "userEngagementDetailShowMePlay": "Le nombre total de diaporamas, de vidéos et de GIF lus dans la période donnée pour l'utilisateur.",
    "userEngagementDetailTestMePlay": "Le nombre total d'utilisateurs qui ont réussi ou échoué aux tests dans la période donnée pour l'utilisateur.",
    "userGuideGraph": "Graphiques des Guides de l'utilisateur",
    "graphOfLiveGuideActivities": "Un graphique représentant les activités sur les guides en direct pour l'utilisateur.",
    "playsByMedia": "Lectures par types de média",
    "donutChartShowMeDistribution": "Graphique circulaire représentant la répartition en pourcentage des activités sur ShowMe.",
    "playsByTime": "Lectures par durée",
    "lineChartOfTestMeActivities": "Un graphique linéaire représentant le nombre total de fois où l'utilisateur a réussi ou échoué à l'activité TestMe.",
    "graphOfDoItForMeMode": "Un graphique représentant les activités sur les guides en direct pour l'utilisateur lu en mode DoItForMe.",
    "totalGuidesPlayedInDoItForMeMode": "Le nombre total de guides lus dans la période donnée en mode DoItForMe.",
    "totalGuidesPlayedFromFirstStep": "Il s'agit du nombre total de guides lus depuis la toute première étape jusqu'à la dernière étape en mode DoItForMe",
    "userEngagementTimeline": "Chronologie de la réservation des utilisateurs",
    "userEngagementPlayDetails": "Détails sur la lecture",
    "tutorialGuideActivity": "Activité Tutorial Guides",
    "userActivitiesTimelineCharts": "Le graphique chronologique représente la série d'activités réalisées par un utilisateur spécifique dans une période donnée. Il indique également le mode dans lequel l'activité de guidage a été effectuée.",
    "listOfSteps": "Il présente la liste détaillée des étapes lues par un utilisateur pour un guide spécifique dans une période donnée.",
    "userActivitiesOnTutorialGuide": "Graphique représentant les activités sur Tutorial Guides pour l'utilisateur.",
    "totalNumberOfGuideInTutorialGuide": "Le nombre total de TutorialGuides lus dans la période donnée.",
    "totalNumberOfGuideInTutorialGuideFromFirstStep": "Il s'agit du nombre total de TutorialGuides lus depuis la toute première étape jusqu'à la dernière étape.",
    "searchOverview": "Aperçu des recherches",
    "searchKeywords": "Rechercher des mots clés",
    "keyword": "Mot clé",
    "numberOfSearches": "Nombre de recherches",
    "avgResultRetured": "Nombre moyen de résultats obtenus",
    "clicks": "Clics",
    "searchSummary": "Résumé de la recherche",
    "tableOfSearchSummary": "Tableau représentant les 50 premiers mots-clés (en fonction du nombre d'occurrences pour chaque mot-clé) dans la période donnée. Vous pouvez rechercher le mot-clé souhaité à l'aide de la fonction de recherche si vous pensez qu'il n'a pas été sélectionné.",
    "searchKeystrokes": "Recherchez les frappes de touches telles qu'elles sont recherchées par divers utilisateurs.",
    "keystrokesHitByUser": "Le nombre de fois où chaque touche a été frappée par différents utilisateurs",
    "avgKeystrokes": "Le nombre moyen de résultats (minimum) renvoyés, sur la période, par rapport au nombre de recherches effectuées pour chaque frappe.",
    "totalClickSearchResult": "Il représente le nombre de fois qu'un clic a été effectué sur un élément particulier du résultat de la recherche",
    "wordClouseTooltipText": "Un diagramme de nuage de mots représentant le volume de recherche pour les 50 premiers mots-clés (en fonction du nombre d'occurrences pour chaque mot-clé) au cours de la période donnée.",
    "ductTapeTable": "Un tableau représentant les statistiques du ruban numérique dans une période donnée.",
    "guideAutomationPlay": "Jeu d’automatisation du Guide",
    "testCasesExecutedGraph": "Un graphique linéaire indiquant le nombre de fois où les cas de test ont été exécutés au cours de la période donnée.",
    "numberOfTimesTestCasesAutoExecuted": "Le nombre de fois où les cas de test ont été exécutés automatiquement à l'heure prévue dans la période donnée.",
    "numberOfTimesTestCasesManuallyExecuted": "Le nombre de fois où les cas de test ont été exécutés manuellement par un utilisateur dans la période donnée.",
    "guideAutomationSummary": "Résumé de l’automatisation du Guide",
    "testCasesTable": "Un tableau représentant le statut et les statistiques de chaque cas de test.",
    "playTime": "Temps de lecture",
    "nameOfTestCase": "Le nom du cas de test.",
    "testCaseTriggeredTime": "L'heure à laquelle le cas de test a été déclenché.",
    "automaticallTriggered": "Indique si le cas de test a été déclenché automatiquement selon le calendrier ou manuellement par l'utilisateur.",
    "testCaseModes": "Il indique si le scénario de test s'est déclenché, s'est arrêté pour une raison quelconque ou s'est terminé avec succès.",
    "guideAutomationDetails": "Détails de l’automatisation du Guide",
    "details": "Détails",
    "testCaseStatus": "Une vue détaillée et imbriquée de tous les cas de test avec leur statuts respectifs.",
    "testCaseTitle": "Le titre d'un guide sur lequel le cas de test a été exécuté.",
    "testCaseResult": "Le cas de test a réussi ou échoué sur un guide spécifique.",
    "testCaseInfo": "Les informations concernant le statut du cas de test qui a été exécuté sur un guide spécifique.",
    "testCaseGuideInfo": "Le texte décrivant certaines informations supplémentaires sur le statut d'exécution du guide.",
    "goalDetailOverview": "Aperçu des détails de l'Goal",
    "goalPlayed": "Goal lu",
    "doItForMePlayed": "DoItForMe lu",
    "goalActivitiesChart": "Un graphique linéaire représentant les activités sur les objectifs fixés.",
    "totalGuidesAndGuidesPlayedCount": "Le nombre total de guides et le nombre total de guides lus dans la période donnée.",
    "totalTooltipsRenderCount": "Le nombre total d'infobulles rendues dans la période donnée.",
    "goalProgress": "Goal Avancement",
    "userGuidesSummary": "Résumé du Guides utilisateur",
    "goalCompletion": "Achèvement de l'Goal",
    "goalCompletionOverhead": "Frais généraux de l'achèvement de l'Goal",
    "goalProgressBarChart": "Un diagramme à barres représentant les progrès accomplis dans la réalisation de l'objectif",
    "targetGoalPercentage": "Le pourcentage des objectifs fixés qui ont été atteints par l'utilisateur.",
    "goalsExceedingTargets": "Le pourcentage d'objectifs dépassant les objectifs fixés atteints par l'utilisateur.",
    "guideModification": "Modification des Guides",
    "guideMeShowMeComparison": "Un graphique linéaire comparant les lectures GuideMe et ShowMe dans la période donnée.",
    "guidesPlayedInGuideMeMode": "Le nombre total de guides lus en mode GuideMe dans la période donnée.",
    "guidesPlayedInShowMeMode": "Le nombre total de guides lus en mode ShowMe dans la période donnée.",
    "userInteractedWithPNBTMode": "Un graphique représentant le nombre total de fois où les utilisateurs ont interagi avec les notifications poussées Beacons& et les info-bulles lors de la visite d'une page.",
    "totalModifiedGuides": "Un graphique à barres représentant le nombre total de guides qui ont été modifiés dans la période donnée.",
    "serviceNotAvailable": "Le service n'est pas disponible",
    "conflictingParameters": "Il existe des paramètres contradictoires dans la demande",
    "insightsLogin": "Connexion à Insights",
    "emailId": "ID de courriel",
    "emailPlaceholder": "name@domain.com",
    "password": "Mot de passe",
    "remember": "N'oubliez pas",
    "pleaseContact": "Veuillez communiquer avec",
    "accountSuspended": "Votre compte a été suspendu.",
    "forgotPassword": "Mot de passe oublié?",
    "login": "Connexion",
    "copyright": "Copyright ©",
    "sessionExpiredMsg": "Votre session est périmée.",
    "reLogin": "Reconnectez-vous.",
    "sessionExpired": "Session expirée",
    "reaTimeDateCondition": "Les données en temps réel sont incluses dans toutes les sections de données prises en charge. Veuillez noter que la sélection de la date ne sera autorisée que jusqu'à un jour avant la date actuelle dans le sélecteur de date.",
    "goToHome": "Aller à la page d’accueil",
    "pageNotFound": "Oups, la page n'a pas été trouvée!",
    "brandName": "MyGuide",
    "buildversion": "Version",
    "supportLinkText": "Si vous avez besoin d'aide, veuillez nous écrire à",
    "mailto": "mailto",
    "addChartinCustomDash": "Aucun graphique n'a encore été ajouté. Veuillez ajouter des graphiques au Dashboard personnalisé.",
    "commentQnAnySuggestion": "Avez-vous des suggestions?",
    "commentQnImprove": "Que pouvons-nous améliorer?",
    "commentQnDescribe": "Décrivez le produit en quelques mots.",
    "multiselectFeaturesLiked": "Quelles sont les fonctionnalités que vous avez le plus aimées?",
    "multiselectFeaturesUsed": "Laquelle de ces fonctionnalités vous avez déjà utilisées?",
    "multiselectPlatform": "Sur quelle plateforme utilisez-vous le site?",
    "rangeSelect": "Sur une échelle de 1 à 10, quelle note nous donneriez-vous?",
    "rangeProvide": "Quelle note nous donneriez-vous?",
    "rateUs": "Notez-nous",
    "satisfiedRate": "Dans quelle mesure êtes-vous satisfait de notre produit?",
    "singleselectLikeGuide": "Avez-vous aimé le guide?",
    "singleselectSuggestGuide": "Recommanderez-vous ce guide à vos amis?",
    "singleselectAccomplishGuide": "Le guide vous a-t-il aidé à atteindre votre objectif?",
    "yesNoLikeGuide": "Avez-vous aimé le guide?",
    "yesNoSuggest": "Recommanderez-vous ce guide à vos amis?",
    "yesNoAccomplish": "Le guide vous a-t-il aidé à atteindre votre objectif?",
    "downloadingOption": "Téléchargement en cours",
    "desktop": "Bureau",
    "mobile": "Mobile",
    "tablet": "Tablette",
    "mayBe": "Peut être",
    "minute": "minute",
    "minutes": "minutes",
    "second": "seconde",
    "responsesTitle": "RÉPONSES",
    "dashboard": "Dashboard",
    "manage": "Gérer",
    "selectType": "Sélectionner un type",
    "linkedPage": "Page liée",
    "linkPage": "Lien Page",
    "branching": "Embranchement",
    "addOptionLink": "Ajouter un lien d'option",
    "iconToCreateNewSurvey": "sur l'icône pour créer un nouvel Survey",
    "clickToEditSurvey": "Cliquez sur l'enquête dans la liste pour modifier cette enquête spécifique",
    "lastUpdated": "Date de la dernière mise à jour",
    "global": "Global",
    "questions": "Questions",
    "turnOnGoalNotif": "Activez cette option pour recevoir des courriels pour chaque étape franchie dans le cadre d'un objectif.",
    "turnOffGoalNotif": "Désactivez cette option pour ne plus recevoir de courriel pour chaque étape franchie dans le cadre d'un objectif.",
    "turnOnEmailNotif": "Activez cette option pour recevoir des courriels lorsque l'objectif est atteint.",
    "turnOffEmailNotif": "Désactivez cette option pour ne plus recevoir de courriel lorsque l'objectif est atteint.",
    "error": "Erreur!",
    "goalAlreadyExists": "L'Goal existe déjà. Sélectionnez un autre titre.",
    "successfullyCreated": "Créé avec succès",
    "enterText": "Saisir le texte",
    "searchGoal": "Rechercher Goal",
    "enterQuestion": "Entrez votre question",
    "addLink": "Ajouter un lien",
    "searchSurvey": "Rechercher Survey",
    "nameTagOnPage": "Donnez un nom à l'étiquette sur Page",
    "describeTagOnPage": "Décrire l’étiquette sur Page",
    "selectUser": "Sélectionner l’utilisateur",
    "pinToDashboard": "Épingler sur le Dashboard",
    "deleteDashboard": "Supprimer le tableau de bord",
    "publishGoal": "Publier le Goal",
    "deleteChart": "Supprimer le graphique",
    "analyzerQuestion": "Question de l’analyseur",
    "branchingQuestion": "Question d'embranchement",
    "allDashboardDataDeletedMsg": "Tous les données du Dashboard seront supprimées.",
    "dashboardDeleteConfirmMsg": "Voulez-vous supprimer ce tableau de bord?",
    "exportDashboardData": "Exporter les données du Dashboard",
    "deleteGoal": "Supprimer Goal",
    "viewPendingJobs": "Afficher les emplois en attente",
    "deleteSurvey": "Supprimer Survey",
    "deleteSurveyConfirmMsg": "Êtes-vous sûr de vouloir supprimer cette enquête?",
    "shareSurvey": "Partager les enquêtes",
    "best": "Meilleur",
    "worst": "Pire",
    "average": "Moyenne",
    "selectRange": "Sélectionnez une catégorie",
    "searchSegment": "Segment de recherche",
    "searches": "Recherches",
    "org": "ORGANISATION",
    "downloads": "Téléchargement(s)",
    "selectApps": "Sélectionner des applications",
    "weeklyUsageStats": "Statistiques d'utilisation hebdomadaire",
    "dailyFailureReport": "Rapport d'échec des étapes quotidiennes",
    "allApps": "Toutes les applications",
    "chooseDahsboard": "Choisissez Dashboard",
    "userEngagementDetail": "Détail de la réservation des utilisateurs",
    "organizationDetail": "Détail de l'organisation",
    "organizationView": "Affichage organisationnel",
    "guideSurveyQuestionsAndResponses": "Questions & réponses du GuideSurvey",
    "exitGuideSurveyQuestionsAndResponses": "Sortie - questions & réponses duGuideSurvey",
    "guideStepSurveyQuestionsAndResponses": "Étape Guide Survey Questions & Réponses",
    "pageDetails": "Détails sur Page",
    "controlCenter": "Centre de contrôle",
    "slideshows": "Diaporama(s)",
    "videos": "Vidéo(s)",
    "docx": "DOCX",
    "html": "HTML",
    "gifs": "GIF(s)",
    "guideDetail": "Guide Détails de l’analyse",
    "tutorialGuideDetail": "Détail du Tutorial Guide",
    "docTitleGuideDetail": "Détails sur Guide Insights - Guide",
    "docTitleTutorialGuideDetail": "Détails sur Guide Insights - Tutorial Guides",
    "docTitleTooltipDetails": "Détails sur Guide Insights - Tooltip",
    "docTitleUserEngagementDetail": "Guide Insights – Détails sur la réservation des utilisateurs",
    "docTitleGuideAutomationDetail": "Automation des Insights - Détails de l'automatisation du Guide",
    "tooltipDetails": "Détails sur Tooltip",
    "userEngagement": "Réservation des utilisateurs",
    "search": "Rechercher",
    "guidanceAndValidation": "Lignes directrices & Validation",
    "pusNotificationAndBeacons": "Notifications poussées & Beacons",
    "guideMeAndShowMe": "GuideMe & ShowMe",
    "of": "de",
    "tooltipAndValidation": "Tooltip & Validation",
    "userActivity": "Activité de l’utilisateur sur la",
    "eventType": "Type d'événement",
    "guideDownloads": "Téléchargements du Guide",
    "time": "temps",
    "combineWorkflowSteps": "Étapes des flux de travail combinés",
    "share": "Partager",
    "generating": "Génération…",
    "never": "Jamais",
    "custom": "Personnalisé",
    "surveyModalErrorMsg": "Un maximum de 250 caractères est autorisé",
    "scheduled": "En temps réel",
    "manual": "Manuel",
    "interrupted": "Interrompu",
    "editGoal": "Éditer Goal",
    "stopWatching": "Cesser de regarder",
    "startWatching": "Commencer à regarder",
    "invalidDate": "Date invalide",
    "invalidStartAndEndDate": "Date de début et date de fin non valides",
    "goalNotificationEmail": "Courriel de notification du Goal",
    "goalCompletionPercent": "Achèvement du Goal en %",
    "goalCompletionOverheadPercent": "Frais généraux de l'achèvement du Goal en %",
    "actualPlays": "Lectures effectives",
    "targetPlays": "Lectures cibles",
    "dataSource": "Source de données",
    "selectIcon": "Sélectionner l’icône",
    "guideSteps": "Étapes du Guide",
    "createFilter": "Créer un filtre",
    "filterList": "Liste de filtres",
    "orgList": "Liste des organisations",
    "allOrganizations": "Toutes les organisations",
    "views": "vues",
    "docTitleUserEngagement": "Guide Insights - Réservation des utilisateurs",
    "docTitleSearches": "Guide Insights - Recherches",
    "orLater": "ou version ultérieure",
    "pageNumber": "Page",
    "live": "En direct",
    "achieved": "Achevé",
    "ended": "Terminé",
    "achievedAndLive": "Réalisé et en direct",
    "saved": "Enregistré",
    "down": "Bas",
    "up": "Haut",
    "urlPath": "Chemin de l'URL",
    "urlParameters": "Paramètres de l'URL",
    "urlHash": "Hash d'URL",
    "errorInvalidURL": "Erreur : URL non valide",
    "publish": "Publier",
    "btnYes": "Oui",
    "btnNo": "Non",
    "select": "Sélectionnez",
    "export": "Exporter au format",
    "apply": "Appliquer",
    "all": "Tout",
    "slideShow": "Diaporama",
    "video": "Vidéo",
    "seconds": "secondes",
    "refresh": "Actualiser",
    "hour": "heure",
    "hours": "heures",
    "inputPlaceHolderSearch": "Rechercher Guide",
    "noRecordFound": "Aucune donnée disponible",
    "avgRating": "Notation moyenne",
    "ratings": "Notations",
    "ranges": "Catégories",
    "option": "Option",
    "nameOfTheApp": "Nom de l'application",
    "guideMePlays": "Lectures GuideMe",
    "guidePlayedInGuideMeMode": "Nombre de fois où le guide a été lu en mode GuideMe",
    "showMePlays": "Lectures ShowMe",
    "guidePlayedInShowMeMode": "Nombre de fois où le guide a été lu en mode ShowMe",
    "testMePlays": "Lectures TestMe",
    "gupidePlayedInTestMeMode": "Nombre de fois où le guide a été lu en mode TestMe",
    "doItForMePlayes": "Lectures DoItForMe",
    "guidePlayedInDoItForMeMode": "Nombre de fois où le guide a été lu en mode DoItForMe",
    "tutorialPlays": "Lectures Tutorial",
    "guidePlayedInTutorialMode": "Nombre de fois où le guide a été lu en mode Tutorial",
    "nameOfWorkflow": "Nom du Workflow",
    "workflowCounts": "Nombre de visites",
    "counts": "Nombres",
    "avgTimeSpentOnWorkflow": "Le temps moyen passé sur le flux de travail",
    "minTimeSpentOnWorkflow": "Le temps minimal passé sur le flux de travail",
    "numberOfGuidesPlayed": "Le nombre de guides lus",
    "numberOfShowMePlayed": "Nombre de ShowMe lus",
    "numberOfTestMePlayed": "Nombre de TestMe lus",
    "numberOfSurveyResponses": "Nombre de réponses à lSurvey",
    "nameOfSTep": "Nom de l'étape",
    "stepPlayeCount": "Nombre d'étapes lues",
    "numberOfTimeStepWasPlayed": "Nombre d'étapes lues",
    "stepStartedFrom": "Commence à cette étape",
    "numberOfTimesSTepStartedFrom": "Le nombre de fois où la lecture a commencé à partir de cette étape",
    "stepEndedOn": "Terminé à cette étape",
    "numberOfTimeStepEndedOn": "Le nombre de fois où la lecture s'est arrêtée à cette étape",
    "failedOnSTep": "Échec à cette étape",
    "numberIfTimesErroreOnStep": "Le nombre de fois où la lecture a rencontré une erreur à cette étape.",
    "humaneInterventionCount": "Nombre d'interventions humaines",
    "numberOfTimesHUmanInterventionPerformed": "Le nombre de fois qu'une intervention humaine a été effectuée",
    "numberOfTimesStepWasSkiepped": "Le nombre de fois où l'étape a été sautée",
    "tutorialGuideTitle": "Titre du Tutorial Guide",
    "nameOfTutorialGuide": "Nom du Tutorial Guide",
    "tutorialGuidePlayed": "Tutorial Guide joué",
    "numberOfTutorialGuidesPlayed": "Nombre de guides tutoriels lus",
    "tutorialGuideCompleted": "Tutorial Guide terminé",
    "numberOfTutorialGuidesCompleted": "Nombre de guides tutoriels lus",
    "nameOfPowerForm": "Nom du Power Form.",
    "numberOfTimePowerFormCancelled": "Nombre de fois ou le Power Form a été annulé",
    "numberOfTimesPowerFormSubmitted": "Nombre de fois ou le Power Form a été soumis",
    "nameOfGuide": "Nom du Guide.",
    "numberOfGuidanceCounts": "Le nombre de comptages d'orientation",
    "numberOfValidationCounts": "Le nombre de comptages de validation",
    "numberOfGuidanceAndValidationCounts": "Nombre de comptages d'orientation & de validation.",
    "numberOfDigitalDuctTapeCount": "Le nombre de rubans numériques",
    "numberOfPowerFormsCount": "Le nombre de Power Forms",
    "guideMePlayCount": "Nombre de lectures du GuideMe",
    "showMePlayCount": "Nombre de lectures du ShowMe",
    "testMePlayCount": "Nombre de lectures du TestMe",
    "doItForMePlayCount": "Nombre de lectures du DoItForMe",
    "tutorialPlayCount": "Nombre de lectures du Tutorial",
    "uniqueKeywordSearched": "Mot clé unique recherché",
    "searchCountTitle": "Nombre de recherches",
    "avgResultReaturned": "Nombre moyen de résultats obtenus",
    "avgNumberOfResultsShown": "Le nombre moyen de résultats affichés",
    "numberOfTimeSearchResultedIntoAction": "Le nombre de fois où la recherche a abouti à une action",
    "nameOfPageTag": "Le nom de l'étiquette de la page",
    "pageViews": "Page vues",
    "numberOfPageViews": "Nombre de pages vues",
    "numberOfUniqueVisitors": "Nombre de visiteurs uniques",
    "totalUniqueUsers": "Nombre total d'utilisateurs uniques",
    "newUsers": "Nouveaux utilisateurs",
    "numberOfSessions": "Nombre de sessions",
    "bouncedUsers": "Utilisateurs rebutés",
    "nameOfStep": "Nom de l'étape",
    "stepPlayedCOunts": "Nombre d'étapes lues",
    "abgTimeSpentOnStep": "Le temps moyen passé sur l'étape",
    "minTimeSpentOnStep": "Le temps minimal passé sur l'étape",
    "nameOfSurvey": "Nom de l'Survey",
    "numberOfResponses": "Nombre de réponses",
    "userSurveyAnalysis": "analyse de l'Survey sur les utilisateurs",
    "lastUpdateTime": "Heure de la dernière mise à jour",
    "receiveGoalEmailNotification": "Recevoir une notification par courriel pour le Goal",
    "receiveGoalMilestoneNotification": "Recevoir une notification d'étape pour le Goal",
    "other": "autre",
    "tooltipPlays": "Lectures Tooltip",
    "watcherUpdatedSuccessfully": "Watcher a été mis à jour avec succès!",
    "tag": "Étiquette",
    "chatbotConversation": "Conversation avec un robot conversationnel",
    "searchChatbot": "Rechercher un robot conversationnel",
    "iconToCreateNewChatbot": "icône pour créer un nouveau robot conversationnel",
    "clickToEditChatbot": "Cliquez sur le robot conversationnel de la liste pour le modifier.",
    "chatBotTitle": "Titre du robot conversationnel",
    "mandatoryForChatBot": "Obligatoire pour le robot conversationnel",
    "validateEmail": "Valider le courriel",
    "manageChatBot": "Gérer le robot conversationnel",
    "geoLocationChartCountryWise1": "Un graphique de géolocalisation représentant l’utilisation des applications par pays à travers le monde. (calculé en fonction du fuseau horaire du système de l'utilisateur)",
    "guidesVsCompletion1": "Le pourcentage de guides joués jusqu’au bout par rapport à ceux qui n’ont pas été terminés.",
    "totalNumbersUsersInteractedWithPNB1": "Un graphique représentant l’initiation de la lecture GuideMe, ShowMe et DoItForMe effectuée à l’aide de la notification Push ou des Beacons.",
    "guideWithAudio1": "Le nombre total de fois qu’un guide a été joué avec une étape avec audio (nombre max. d’étapes jouées avec audio)",
    "guideWithoutAudio1": "Le nombre total de fois qu’un guide a été joué avec une étape sans audio (nombre max. d’étapes jouées sans audio)",
    "done": "Fait",
    "discard": "Rejeter",
    "enableExitSurvey": "Activer la sortie du Survey",
    "selectExitSurvey": "Sélectionner la sortie du Survey",
    "numberUniqueUsersonPages": "Le nombre d’utilisateurs uniques qui ont consulté la page dans l’intervalle de temps imparti.",
    "exitSurveyWarningMsg": "Cette action sera appliquée à toutes les applications.",
    "orgAdmin": "Organisation Admin",
    "deleteChatBot": "Supprimer le robot conversationnel",
    "deleteChatBotConfirmMsg": "Voulez-vous vraiment supprimer ce robot conversationnel?",
    "chatBotValidateEmailMsg": "(Seules les adresses électroniques valides seront acceptées dans la réponse à cette question. Veuillez formuler vos questions en conséquence.)",
    "partnerAdmin": "Partenaire Admin",
    "totalCountSummary": "Récapitulatif du nombre total",
    "PNBandTooltips": "Push-Notifications, Beacons & Tooltips",
    "buttonText": "Connexion",
    "environmentFilterTitle": "Environnement",
    "segmentFilterTitle": "Segment",
    "eventSourceFilterTitle": "Origine de l’événement",
    "guideStatusFilterTitle": "Guide État",
    "tooltipFilterTitle": "Liste de Tooltip",
    "applications": "Applications",
    "none": "Aucun",
    "yes": "Oui",
    "no": "Non",
    "totalGuidesPlayedTitle": "Somme de : Guide Lu + ShowMe + DoItForMe + Tutorial Lectures",
    "tooltipActivity": "Activité de Tooltip",
    "pushnotificationActivity": "Activité de Push-Notification",
    "totalGuidePlayedInDoItForMeMode": "Nombre de guides lus en mode DoItForMe",
    "userActivityGraph": "Un graphique linéaire représentant les activités effectuées par l’utilisateur sur les applications sélectionnées.",
    "conversationStats": "Statistiques de la conversation",
    "chatBot": "Robot conversationnel",
    "conversationsResponse": "Réponse à la conversation",
    "chatBotDetail": "Détails de la conversation",
    "chatBotResponse": "Réponses de la conversation",
    "remarks": "Remarques",
    "noDataFound": "Aucune donnée n’a été trouvée",
    "noRemarkFound": "Aucune remarque n'a été trouvée",
    "viewQuestions": "Afficher les questions",
    "viewRemarks": "Afficher les remarques",
    "remarksPlaceholder": "Ajouter une remarque…",
    "remarkPostSuccessful": "Remarque postée avec succès",
    "remarkPostFailed": "la publication de la remarque a échoué. Réessayez",
    "conversation": "Conversation",
    "conversationSummary": "Résumé de la conversation",
    "tableActitiesofConversation": "Un tableau représentant les activités de la conversation",
    "conversationName": "Nom de la conversation",
    "nameConversationCreated": "Nom de la conversation créée.",
    "labelsofConversation": "Étiquettes de la conversation.",
    "numberResponsesEachConversation": "Nombre de réponses pour chaque conversation.",
    "dateTimeofConversationModification": "Date et heure auxquelles la conversation a été modifiée.",
    "lineChartConversationStatTooltip": "Un graphique linéaire représentant la distribution des réponses aux conversations",
    "featureInteractions": "Interactions des fonctionnalités",
    "featureDetail": "Détail de l'article",
    "heatmapTooltipFeatureDetail": "Carte thermique représentant le moment de la journée où le nombre d'interactions avec les fonctionnalités est le plus élevé",
    "lineChartTooltipFeatureDetail": "Une carte thermique montrant le moment de la journée où le nombre d'interactions avec les fonctionnalités est le plus élevé.",
    "featureSummary": "Résumé des fonctionnalités",
    "tableActivitiesOfFeature": "Un tableau représentant les activités des caractéristiques.",
    "featureSetName": "Nom de l'ensemble de fonctions",
    "nameOfFeatureSet": "Nom de l'ensemble de fonctions",
    "interactions": "Interactions",
    "numOfInteractions": "Nombre d'interactions pour chaque ensemble de fonctions",
    "dateTimeOfFeatureModification": "Date et heure auxquelles la fonction a été modifiée.",
    "rawURLActivities": "Principales activités URL",
    "pageTagInfo": "REMARQUE : Les changements attendus à la suite de la création/suppression d'étiquettes prendront un certain temps avant de prendre effet.",
    "createdBy": "Créé par",
    "createdByText": "Nom de l'utilisateur qui a créé l'étiquette.",
    "lastUpdatedTimespan": "Dernière mise à jour de l'intervalle de temps de l'étiquette.",
    "blockDiagramTop50Pages1": "Un diagramme de blocs représentant les 50 premiers événements bruts de la page (en fonction du nombre d'occurrences de chaque page).",
    "webBrowserTooltip": "Section contenant la répartition de l'utilisation en fonction du navigateur (en fonction du nombre d'accès à chaque page).",
    "osTooltip": "Section contenant la répartition de l'utilisation en fonction du système d'exploitation (en fonction du nombre d'accès à chaque page).",
    "screenResolutionTooltip": "Section contenant la répartition de l'utilisation en fonction de la résolution de l'écran (en fonction du nombre d'accès à chaque page).",
    "completeURL": "Compléter URL",
    "completeURLText": "Complétez le URL de la page sur laquelle l'activité a été enregistrée.",
    "URLVisits": "URL Visites",
    "URLVisitsText": "Nombre de visites sur une URL particulière",
    "uniqueUserVisits": "Visites URL uniques",
    "uniqueUserVisitsText": "Nombre de visites uniques (utilisateurs uniques) sur une URL particulière.",
    "associatedPageTags": "Tags associés à la Page",
    "associatedPageTagsText": "Nombre d'étiquettes de page auxquelles cet URL est associée.",
    "totalUniqueUsersGraph": "Graphique représentant les statistiques des utilisateurs uniques pour une période donnée",
    "noOfSessionsGraph": "Graphique représentant le nombre de sessions (activité continue avec des intervalles de temps ne dépassant pas 30 minutes) pour une période donnée.",
    "avgSessionDurationGraph": "Graphique représentant la durée moyenne d'une session (activité continue avec des intervalles de temps ne dépassant pas 30 minutes) pour une période donnée.",
    "pageViewsGraph": "Graphique représentant le nombre total de pages vues pendant une période donnée",
    "featureList": "Liste des fonctions",
    "userName": "Nom d’utilisateur",
    "userNameTooltip": "Nom de l'utilisateur",
    "userEmail": "Courriel de l’utilisateur",
    "userEmailTooltip": "Adresse courriel de l'utilisateur",
    "numOfInteractionsforUser": "Nombre d'interactions pour chaque utilisateur",
    "lineChartTooltipUserEngagement": "Un graphique linéaire représentant le nombre d'interactions & d'utilisateurs de fonctionnalités au fil du temps.",
    "appEnvSelect": "Sélectionnez l'application & l'environnement",
    "totalCount": "Total",
    "selectedAppsTitle": "Applications sélectionnées",
    "selectDateRangeWithin180Days": "Sélectionnez une plage de dates dans les 180 jours!",
    "pageVisits": "Visites de Page",
    "pageUsers": "Utilisateurs de Page",
    "lineChartTooltipUserEngagementPage": "Un graphique linéaire représentant le nombre de visites et d’utilisateurs de pages au fil du temps.",
    "lineChartTooltipUserEngagementPageModal": "Un graphique linéaire représentant le nombre de visites de pages au fil du temps",
    "numOfPageVisitsforUser": "Nombre de visites de Page pour chaque utilisateur",
    "ytd": "Cumul à ce jour",
    "userActivityGraphDashboard": "Graphique linéaire représentant le nombre total d'utilisateurs pour un ensemble d'applications sélectionnées.",
    "totalUserCount": "Nombre d’utilisateurs total",
    "bestTimePageLoad1": "Le temps optimal de chargement de la page.",
    "averageTimePageLoad1": "Le temps moyen de chargement de la page.",
    "worstTimePageLoad1": "Le temps maximum de chargement de la page.",
    "timeOfPageLoading1": "Le temps nécessaire au chargement de la page.",
    "tooltipSummary": "Récapitulatif du Tooltip",
    "top10Tooltips": "10 principaux Tooltips",
    "totalPlaysLabel": "Nombre total de consultations",
    "guidanceLabel": "Lignes directrices",
    "validationLabel": "Validation",
    "digitalDuctTapeLabel": "Duct Tape numérique",
    "powerFormLabel": "Alimentation à partir de",
    "totalPlays": "Nombre total de tooltips avec interaction",
    "guidanceTooltip": "Nombre de fois où l’interaction de l’utilisateur s’est produite en mode assistance",
    "validationTooltip": "Nombre de fois où l’interaction de l’utilisateur s’est produite en mode Validation",
    "guidanceAndValidationTooltip": "Nombre de fois où l’interaction de l’utilisateur s’est produite en mode assistance et Validation",
    "digitalDuctTapeTooltip": "Nombre de fois où l’interaction de l’utilisateur s’est produite en mode Duct Tape numérique",
    "powerFormTooltip": "Nombre de fois où l’interaction de l’utilisateur s’est produite en mode Power Form",
    "applicationAndPlayType": "Application et type de lecture",
    "selectApplication": "Sélectionner une application :",
    "selectEventSource": "Sélectionner une source d’événement :",
    "selectPlayType": "Sélectionner un type de lecture :",
    "appSelectionNote": "Remarque : Pour une sélection granulaire, veuillez sélectionner une seule application",
    "chartFilteredBasedOnApps": "Ce graphique est filtré en fonction des applications sélectionnées uniquement.",
    "pushNotificationInteractions": "Interaction avec les notifications poussées",
    "pushNotificationDisplayed": "Notifications push affichées",
    "beaconsInteractions": "Interaction Beacons",
    "beaconsDisplayed": "Beacons Affiché",
    "guidesPlayedByPushAndBeacons": "Guides Joué par Notifications poussées & Beacon",
    "emailCCPlaceholder": "Saisissez l’ID de courriel et appuyez sur la touche « Entrée »!",
    "emailCC": "CC du courriel",
    "invalidEmailId": "ID de courriel non valide",
    "duplicateEmailId": "ID de courriel en double",
    "selectedPlayTypes": "Vos types de jeu sélectionnés",
    "selectedTooltip": "Sélectionner Tooltip",
    "newTemplate": "Nouveau modèle",
    "templateTitle": "Titre du modèle",
    "newSchedule": "Nouvel horaire",
    "scheduleTitle": "Titre de l’horaire",
    "useTemplate": "Utiliser le modèle",
    "saveSchedule": "Enregistrer l’horaire",
    "updateSchedule": "Mettre à jour l’horaire",
    "deleteTemplate": "Supprimer le modèle",
    "deleteSchedule": "Supprimer la planification",
    "deleteTemplateConfirmationMsg": "Voulez-vous supprimer ce modèle?",
    "deleteScheduleConfirmationMsg": "Voulez-vous supprimer cette planification?",
    "saveTemplate": "Enregistrer le modèle",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "triggerTime": "Temps de déclenchement",
    "activate": "Activer",
    "deactivate": "Désactiver",
    "templateTitleErrorMsg": "Veuillez ajouter le titre du modèle",
    "scheduleTitleErrorMsg": "Veuillez ajouter le titre de l’horaire",
    "scheduleFutureDateErrorMsg": "Veuillez sélectionner une date future pour l’horaire",
    "scheduleDaySelectionErrorMsg": "Veuillez sélectionner les jours pour lesquels le planificateur fonctionnera",
    "scheduleMaxDaySelectionErrorMsg": "Le nombre maximum de jours que vous pouvez sélectionner est de 7",
    "scheduleMaxActiveErrorMsg": "Le nombre maximum d’horaires pouvant être actifs en même temps est de 3.",
    "activeScheduleNonEditableInfoMsg": "La planification active ne peut'pas être modifiée",
    "selectTime": "Sélectionnez l’heure",
    "oneTime": "Une fois",
    "repetitive": "Répétitif",
    "tutorial": "Tutorial",
    "updatedSuccessfully": "Mis à jour avec succès!",
    "pushNotificationInteractedKey": "PushNotification a interagi",
    "pushNotificationDisplayedKey": "PushNotification affiché",
    "beaconInteractedKey": "Beacon} a interagi",
    "pushNotificationInteractedValue": "Nombre de cas où l’utilisateur a interagi avec la notification poussée.",
    "pushNotificationDisplayedValue": "Nombre de fois où les notifications poussées ont été affichées sur l’écran de l’utilisateur (veuillez noter que les affichages sont capturés une fois pour chaque combinaison poussée de l’utilisateur & par jour).",
    "beaconInteractedValue": "Nombre de cas où l’utilisateur a interagi avec la Beacon.",
    "beaconDisplayedValue": "Nombre de fois où les beacons ont été affichés sur l’écran de l’utilisateur (veuillez noter que les affichages sont capturés une fois pour chaque combinaison beacon de l’utilisateur & par jour).",
    "emailCCWarningMessage": "Le nombre maximal d’adresses courriel autorisé est de 10.",
    "ytdCalendarYear": "YTD (Calendar Year)",
    "activateScheduleMsg": "Souhaitez-vous activer cet horaire?",
    "deactivateScheduleMsg": "Souhaitez-vous désactiver cet horaire?",
    "help": "Aide",
    "updateTemplate": "Mettre à jour le modèle",
    "beaconDisplayedKey1": "Beacon Affiché",
    "taskList": "Liste de tâches",
    "taskListActivity": "Activité de liste de tâches",
    "taskListSummary": "Résumé de la liste des tâches",
    "tasksTotalPlayed": "Nombre total de listes de tâches engagées dans une période donnée.",
    "tasksCompleted": "Nombre total de listes de tâches terminées dans une période donnée.",
    "taskListNameLabel": "Nom de la liste des tâches",
    "taskListEngagementsLabel": "Liste de tâches engagée",
    "taskListCompletedLabel": "Liste de tâches terminée",
    "taskListLastUpdatedLabel": "Date de la dernière mise à jour",
    "taskListName": "Nom de la liste de tâches",
    "taskListEngagements": "Nombre total d’engagements de listes de tâches",
    "taskListCompleted": "Nombre total de listes de tâches terminées",
    "taskListLastUpdated": "Date et heure auxquelles la liste des tâche a été modifiée",
    "taskListDetail": "Détail de la liste des tâches",
    "taskListGuidePlay": "Guide Jouer",
    "completionStatus": "Statut d’achèvement",
    "completionTime": "Temps d’achèvement",
    "totalTaskListPlayedMsgTooltip": "Nombre de fois où la liste des tâches est lue",
    "completedTaskListTooptip": "Nombre de fois où la liste des tâches est terminée",
    "guidePlays": "Lectures de Guide",
    "guidePlayTaskListTooltip": "Nombre de lectures de guides via la liste des tâches",
    "taskListInteracted": "Liste des tâches ayant fait l’objet d’une interaction",
    "taskListInteractedTooltip": "L’utilisateur a lu au moins un guide dans la liste de tâches",
    "taskListCompletedTooltip": "L’utilisateur a terminé tous les guides de la liste de tâches",
    "viewGuides": "Voir Guides",
    "min7DaysSelection": "Le délai minimum sélectionné pour la section Engagement des utilisateurs est de 7 jours.",
    "totalGuidesPlayCount": "Le nombre total de guides lus en mode GuideMe, DoItForMe et ShowMe.",
    "role": "RÔLE",
    "performedSearches": "Recherches effectuées",
    "searchesResultedIntoAction": "Recherches ayant abouti à une action",
    "exportStatus": "Statut d’exportation",
    "myGuideGuidesPlayed": "MyGuide Guides lu",
    "myGuideTutorialGuidesPlayed": "MyGuide Tutorial Guides lu",
    "totalNumberOfTimesGuidePlayedInDoItForMe": "Nombre total de fois où le guide a été lu en mode DoItForMe.",
    "creatorExtensionRequired": "Pour que cette fonctionnalité soit opérationnelle, la dernière version de l’extension MyGuide Creator doit être installée en tant qu’extension dans le navigateur.",
    "guidesInTooltipModeinTimeFrame": "Nombre total de Tooltip affichés dans la période donnée.",
    "guidesInPNModeinTimeFrame": "Nombre total de guides lus à l’aide des Push-Notification dans la période donnée.",
    "guidesInBeaconModeinTimeFrame": "Nombre total de guides lus en utilisant des Beacons dans la période donnée.",
    "guidesInSurveyModeinTimeFrame": "Nombre total de réponses à l’enquête dans la période donnée.",
    "visits": "Visites",
    "desktopPlayer": "Lecteur pour ordinateur de bureau",
    "guideErrors": "Guide Erreurs",
    "guideErrorsDetails": "Détails des erreurs du Guide",
    "guideStepErrors": "Guide Erreurs d’étape",
    "guideStepErrorDetails": "Détails des erreurs d’étape du Guide",
    "guideStepErrorsActivity": "Activité sur les erreurs d’étape du Guide",
    "guideErrorOccurrences": "Guide Occurrences d’erreurs",
    "guideErrorOccurrencesValue": "Nombre total d’erreurs du guide dans la période donnée.",
    "guideErrorSummary": "Guide Liste récapitulative des erreurs",
    "guideErrorSummaryValue": "Résumé de tous les guides comportant des étapes qui ont connu une erreur d’étape dans la période donnée.",
    "stepInGuideWithErrors": "Étapes du guide avec erreurs",
    "totalGuidePlayErrors": "Total des erreurs de lecture du Guide",
    "latestErrorRecorded": "Dernière erreur enregistrée le",
    "stepInGuideWithErrorsValue": "Nombre total d’étapes du guide qui ont rencontré des erreurs.",
    "totalGuidePlayErrorsValue": "Nombre total de fois qu’une erreur a été rencontrée dans le guide de lecture",
    "latestErrorRecordedValue": "Horodatage de la dernière erreur enregistrée",
    "latestStartTimestamp": "Dernier horodatage de début",
    "stepErrorCounts": "Nombre d’erreurs d’étape",
    "latestStartTimestampValue": "Dernier horodatage de l’occurrence de l’erreur à l’étape.",
    "stepErrorCountsValue": "Nombre total de fois qu’une erreur s’est produite sur l’étape",
    "stepErrorSummary": "Résumé des erreurs d’étape",
    "guideError": "Erreur du Guide",
    "actions": "Actions",
    "createTemplate": "Créer un modèle",
    "createSchedule": "Créer une planification",
    "createJob": "Créer un emploi",
    "playType": "Type d’opération",
    "doYouWantToDelete": "Confirmez-vous la suppression?",
    "dateRange": "Tranches de date",
    "triggerType": "Type de déclencheur",
    "triggerFrequency": "Fréquence de déclenchement",
    "rowsPerPage": "Lignes par page",
    "exportToExcel": "Exporter vers Excel",
    "triggerDay": "Jour de déclenchement",
    "triggerDate": "Date de déclenchement",
    "dayOfWeek": "Jour de la semaine",
    "dateOfMonth": "Date du mois",
    "pauseSchedule": "Mettre le programme en pause",
    "playSchedule": "Lire le programme",
    "editSchedule": "Modifier la planification",
    "editTemplate": "Modifier le modèle",
    "businessIntelligenceToolsIntegrations": "Intégration des outils de Business Intelligence",
    "bitiInfoHeading0": "Comment obtenir le connecteur Power BI Desktop pour ’CornerstoneGuide’?",
    "bitiInfo0_1": "Cliquez ici",
    "bitiInfo0_2": "pour télécharger le fichier du connecteur (.mez)",
    "bitiInfo0_3": "Copiez le fichier d’extension dans",
    "bitiInfo0_4": "Cochez l’option",
    "bitiInfo0_5": "Autoriser le chargement de toute extension sans validation ni avertissement",
    "bitiInfo0_6": "Dans Power BI Desktop sous",
    "bitiInfo0_7": "Redémarrez Power BI Desktop",
    "bitiInfoHeading1": "Comment extraire des données de Cornerstone Guide Insights directement vers votre instance PowerBI Desktop?",
    "bitiInfo1_1": "Générez un URL} authentifié en cliquant sur le bouton « Générer » ci-dessous.",
    "bitiInfo1_2": "Copiez le URL} généré",
    "bitiInfo1_3": "Accédez à PowerBI Desktop sur votre ordinateur Windows",
    "bitiInfo1_4": "Cliquez sur l’onglet \"Accueil\" dans la barre de navigation, puis cliquez sur l’option \"Obtenir des données\". Dans la liste déroulante, cliquez sur \"Plus\"",
    "bitiInfo1_5": "Dans la boîte de dialogue qui s’ouvre, cliquez sur « Autre » et recherchez « Cornerstone Guide (Beta) (Custom) », puis cliquez sur « Connecter ».",
    "bitiInfo1_6": "Dans la nouvelle boîte de dialogue, collez l’URL} authentifiée et cliquez sur « Ok ».",
    "bitiInfo1_7": "Il ouvrira une fenêtre \"Navigation\". Naviguez et sélectionnez les données que vous voulez extraire dans PowerBI Desktop et cliquez sur « Charger »",
    "bitiInfoHeading2": "Instructions importantes",
    "bitiInfo2_1": "Chaque URL} authentifiée générée dans ce portail est propre à chaque utilisateur et est liée à son compte utilisateur.",
    "bitiInfo2_2": "Bien que les URL} elles-mêmes n’aient pas d’expiration, elles sont liées à des sessions qui expirent au bout de 60 minutes d’inactivité.",
    "bitiInfo2_3": "Les URL peuvent être réautorisées en cliquant sur le bouton \"Réautoriser\" ci-dessus dans la section.",
    "bitiInfo2_4": "Si vous souhaitez modifier l’URL (à volonté ou en cas de compromission du URL), vous pouvez le faire en cliquant sur le bouton « générer à nouveau ».",
    "bitiInfoNote": "Remarque : Authenticated URL est applicable pendant 60 minutes à partir du moment de la génération",
    "generateUrl": "Générer URL",
    "authenticateUrl": "Authentifier URL",
    "cioDashboardTitle": "CIO Dashboard",
    "myGuideDashboard": "MyGuide Dashboard",
    "guideInsights": "Guide Insights",
    "appInsights": "App Insights",
    survey: "Survey",
    automationInsights: "Automation Insights",
    goal: "Goal",
    applicationAndEnvironment:"Application & Environment",
    appEnvironmentFilter:"App Environment Filter",
    hi: 'Hi',
    yourInsightUpdates: "Your Insights Update",
    tutorialGuide: "Tutorial Guide",
    tooltip: "Tooltip",
    pushNotification: "Push-Notification",
    beacon: "Beacon",
    guidesTab: "Guides",
    pushNotificationsAndBeacons: 'Push-Notifications & Beacons',
    tutorialGuides: "Tutorial Guides",
    guideMeTutorialGuide: "GuideMe",
    pushNotifications: "Push-Notifications",
    beacons: "Beacons",
    tooltipLabel: "Tooltips",
    validation: "Validation",
    powerForm: "Power Form",
    tooltips: "tooltips",
    jobList: "Job List",
    templateList: "Template List",
    schedularList: "Scheduler List",
    guideAutomation: "Guide Automation",
    ductTape: "Duct Tape",
    guide: "Guide",
};

module.exports = GmXt.playerLbls.fr_CA;