// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffects of (props)

import React, { useRef, useCallback, useEffect, useState } from "react";
// import NVD3Chart from 'react-nvd3';
// import * as d3 from "d3";
// import $ from "jquery";
// import { Tooltip } from "react-tooltip";
// import { CommonUtils } from "utils/CommonUtils";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';
import { LineChart as RechartLineChart, CartesianGrid, Legend, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

interface CurrentState {
    languageData: LanguageState
}

const LineChart = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    // let lineChartRef = [];
    // const lineChartRef = useRef<any>(null);
    // let max = 0;
    // let minFlag = 0;

    const [renderData, setRenderData] = useState({
        chart: [],
        options: [],
        flag: false,
        max: 0
    })

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        setRenderData((prevState) => {
            return {
                ...prevState,
                chart: [],
                options: [],
                flag: false
            }
        })
        let tempChartData = [];
        let colorChartData = [];
        if (props.optionEntities) {
            colorChartData = props.optionEntities.map(entry => ({
                color: entry.color,
                associatedDataPoint: entry.associatedDataPoint,
                key: entry.key
            }));
            if (props.chartData) {
                tempChartData = props.chartData.map(entry => ({
                    startTimestamp: entry.startTimestamp,
                    ...Object.keys(entry.counts).filter(key => props.chartEntities.includes(key)).reduce((obj, key) => {
                        obj[key] = entry.counts[key];
                        return obj;
                    }, {})
                }))
            }
            setRenderData((prevState) => {
                return {
                    ...prevState,
                    chart: tempChartData,
                    options: colorChartData,
                    flag: true
                }
            })
        }
    }, [props]);

    const lines = () => {
        if (props.chartEntities && renderData.options.length > 0) {
            let lineData = props.chartEntities.map((entity, index) => {
                let obj = renderData.options.find(obj => obj.associatedDataPoint === entity);
                if (obj) {
                    return <Line key={index} type="monotone" dataKey={entity} stroke={obj.color} activeDot={{ r: 8 }} />
                } else {
                    return null;
                }
            })
            return lineData;
        }
        return null;
    }

    return (
        <div id={props.refId} style={{ width: '100%', height: props.height }}>
            {renderData.flag && (
                <ResponsiveContainer width="100%" height="100%">
                    <RechartLineChart width={500} data={renderData.chart} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="startTimestamp" />
                        <YAxis label={{
                            value: props.yAxisLabel,
                            position: 'insideLeft',
                            angle: -90,
                            offset: 20,
                            style: {
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }} domain={[0, 'auto']} />
                        <Tooltip formatter={(value: number | string, name: string) => {
                            const label = renderData.options.find(obj => obj.associatedDataPoint === name)?.key;
                            return [value, label];
                        }} />
                        <Legend
                            formatter={(value) => renderData.options.find(obj => obj.associatedDataPoint === value)?.key} />
                        {lines()}
                    </RechartLineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};
export default React.memo(LineChart);