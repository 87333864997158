// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (props.data)

import React, { useEffect, ChangeEvent, useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import Pagination from "../Pagination/Pagination"

import { getCall } from "../../utils/ApiCallActions";
import { AppsState, DatesState, LanguageState } from "../../store/Reducers";


import $ from 'jquery';
import { ApiRelativePaths, RetryApi } from '../../utils/GlobalConstants';
import { ChartColors } from "../../utils/GlobalConstants";
import cloneDeep from 'lodash/cloneDeep';

import Loader from "../Loader/Loader";
import "./CommonTable.scss";

import { useErrorBoundary } from 'react-error-boundary';

import { InsightLabels } from 'labels';
import Icon from 'components/Icon/Icon';
// import { pageNumber } from 'utils/stringConstants/en_US';

let sortTitle: any = [];
let indexVal: number;
interface CurrentState {
    apps: AppsState;
    dates: DatesState;
    languageData: LanguageState
}



const CommonExpandableTable = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);


    let labelChipsArr: any = [];
    let labelsArr: any = [];
    const [labelsModalData, setValue] = useState({
        labelModalOpen: false,
        labelChips: labelChipsArr,
        labels: labelsArr,
        chipsArray: [],
    });

    const [exportExcelModal, setExportExcelVal] = useState({
        openExportExcelModal: false,
    });

    const [subHeadingLabel, setSubHeadingLabel] = useState(new Map());

    let forcePage = props.currentPage - 1;
    const paginationVar = props.tablePagination;
    let defaultSort = props.defaultSort;

    const filterBy = [
        { key: "5", value: "5" },
        { key: "10", value: "10" },
        { key: "20", value: "20" },
        { key: "30", value: "30" },
    ];



    const location = useLocation();
    if (props.linkPresent === false) {
        $("tbody tr td:first-child").css("color", "#000000");
        $("tbody tr td:first-child").css("cursor", "default");
    }

    const [tableLoader, setTableLoader] = useState({
        flag: false
    })

    const [sortValue, setSortVal] = useState({
        sortVal: props.defaultSort.sortValue,
        index: props.defaultSort.sortColumn_index,
    });

    const [sortLoader, setSortLoader] = useState({
        sortLoader: false
    });



    if (defaultSort !== undefined) {
        indexVal = props.defaultSort.sortColumn_index;
        sortTitle[indexVal] = sortValue.sortVal;
    }

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
		if (insightsLbls) {
			let subHeadingMap = new Map([
				["Name of Guide", insightsLbls.guideNameSubheadingLabel],
				["No. of guides played", insightsLbls.guidesPlayedSubheadingLabel],
				["No. of guides played in GuideMe Mode", insightsLbls.guidesPlayedInGuideMeModeSubheadingLabel],
				["No. of guides played in Do It For Me Mode", insightsLbls.guidesPlayedInDoItForMeModeSubheadingLabel],
				["No. of ShowMe played", insightsLbls.showMePlayedSubheadingLabel],
				["No. of TestMe played", insightsLbls.testMePlayedSubheadingLabel],
				["No. of Survey Responses", insightsLbls.surveyResponsesSubheadingLabel],
				["No. steps in guide experiencing errors", insightsLbls.stepsWithErrorsSubheadingLabel],
				["No. of times error was encountered", insightsLbls.timesErrorEncounteredSubheadingLabel],
				["Time stamp of the last recorded error", insightsLbls.lastErrorTimestampSubheadingLabel],
				["Name of Step", insightsLbls.stepNameSubheadingLabel],
				["Latest Timestamp", insightsLbls.stepLatestTimestampSubheadingLabel],
				["No. of times error was encountered", insightsLbls.stepErrorCountSubheadingLabel],
				["No. of time step was played", insightsLbls.stepPlayCountSubheadingLabel],
				["No. of times human intervention was performed", insightsLbls.humanInterventionCountSubheadingLabel],
				["No. of times step was skipped", insightsLbls.stepSkippedCountSubheadingLabel],
				["No. of time play errored on this step", insightsLbls.stepPlayErrorCountSubheadingLabel],
				["Name of Tutorial Guide", insightsLbls.tutorialGuideNameSubheadingLabel],
				["No. of tutorial guides played", insightsLbls.tutorialGuidesPlayedSubheadingLabel],
				["No. of tutorial guides completed", insightsLbls.tutorialGuidesCompletedSubheadingLabel],
				["No. of times play started from this step", insightsLbls.stepPlayStartedCountSubheadingLabel],
				["No. of times play ended on this step", insightsLbls.stepPlayEndedCountSubheadingLabel],
				["Name of Power Form", insightsLbls.powerFormNameSubheadingLabel],
				["No. of times Power Form Cancelled", insightsLbls.powerFormCancelledCountSubheadingLabel],
				["No. of times Power Form Submitted", insightsLbls.powerFormSubmittedCountSubheadingLabel],
				["Name of task list", insightsLbls.taskListNameSubheadingLabel],
				["No. of task list engaged", insightsLbls.taskListEngagedCountSubheadingLabel],
				["No. of task list completed", insightsLbls.taskListCompletedCountSubheadingLabel],
				["Task List Last updated on", insightsLbls.taskListLastUpdatedSubheadingLabel],
				["Name of user", insightsLbls.userNameSubheadingLabel],
				["Email address of user", insightsLbls.userEmailAddressSubheadingLabel],
				["User played atleast one task list guide", insightsLbls.userPlayedTaskListGuideSubheadingLabel],
				["User completed all guides in the task list", insightsLbls.userCompletedAllGuidesInTaskListSubheadingLabel],
				["GuideMe Play Count", insightsLbls.guideMePlayCountSubheadingLabel],
				["ShowMe Play Count", insightsLbls.showMePlayCountSubheadingLabel],
				["TestMe Play Count", insightsLbls.testMePlayCountSubheadingLabel],
				["DoItForMe Play Count", insightsLbls.doItForMePlayCountSubheadingLabel],
				["Tutorial Play Count", insightsLbls.tutorialPlayCountSubheadingLabel],
				["Unique Keyword Searched", insightsLbls.uniqueKeywordSearchedSubheadingLabel],
				["No. of Searches", insightsLbls.keywordSearchCountSubheadingLabel],
				["Average No. of results shown", insightsLbls.averageSearchResultsSubheadingLabel],
				["No. of time search resulted into action", insightsLbls.searchActionCountSubheadingLabel],
				["Complete URL of the page", insightsLbls.pageUrlSubheadingLabel],
				["No. of URL visits", insightsLbls.pageVisitCountSubheadingLabel],
				["No. of unique users visits", insightsLbls.uniqueUserVisitsSubheadingLabel],
				["No. of tags associated with this URL", insightsLbls.urlTagCountSubheadingLabel],
				["Name of page tag", insightsLbls.pageTagNameSubheadingLabel],
				["No. of page views", insightsLbls.pageViewCountSubheadingLabel],
				["No. of unique visitors", insightsLbls.uniquePageVisitorsSubheadingLabel],
				["Name of user who tagged the tag", insightsLbls.userWhoTaggedSubheadingLabel],
				["Last updated on", insightsLbls.pageLastUpdatedSubheadingLabel],
				["Name of Survey", insightsLbls.surveyNameSubheadingLabel],
				["No. of responses", insightsLbls.surveyResponseCountSubheadingLabel],
				["User Survey analysis", insightsLbls.userSurveyAnalysisSubheadingLabel],
				["Last update time", insightsLbls.surveyLastUpdatedSubheadingLabel],
				["Name of Conversation", insightsLbls.conversationNameSubheadingLabel],
				["No. of responses", insightsLbls.conversationResponseCountSubheadingLabel],
				["Last update time", insightsLbls.conversationLastUpdatedSubheadingLabel],
				["Name of Goal", insightsLbls.goalNameSubheadingLabel],
				["Goal Status", insightsLbls.goalStatusSubheadingLabel],
				["Goal Live date", insightsLbls.goalLiveDateSubheadingLabel],
				["Goal Completion date", insightsLbls.goalCompletionDateSubheadingLabel],
				["Goal Completion Percentage", insightsLbls.goalCompletionPercentageSubheadingLabel],
				["Actions available", insightsLbls.availableActionsSubheadingLabel]
			]);
			setSubHeadingLabel(subHeadingMap);
		}
	}, [insightsLbls]);

    useEffect(() => {
        setSortLoader({
            ...sortLoader,
            sortLoader: true
        });

        setTableLoader((prevState: any) => {
            return {
                ...prevState,
                flag: true
            }
        })
    }, [props.data])


    const getWidth = (keyField: any, optn: number) => {
        try {
            const keys = Object.keys(keyField);
            const total = keyField[keys[0]] + keyField[keys[1]] + keyField[keys[2]];

            if (
                keyField[keys[0]] === 0 &&
                keyField[keys[1]] === 0 &&
                keyField[keys[2]] === 0
            ) {
                return "33.3%";
            } else {
                return (keyField[optn] * 100) / total + "%";
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const getBarColor = (key: string, value: number) => {
        try {
            if (props.barColors === "positiveColors") {
                if (key === "positive") {
                    if (value > 0) {
                        return ChartColors.lightgreen;
                    } else {
                        return "#a5a5a5";
                    }
                } else if (key === "negative") {
                    if (value > 0) {
                        return ChartColors.brightred;
                    } else {
                        return "#a5a5a5";
                    }
                } else if (key === "neutral") {
                    if (value > 0) {
                        return ChartColors.yellowsandybrown;
                    } else {
                        return "#a5a5a5";
                    }
                }
            } else if (props.barColors === "showmeColors") {
                if (key === "videoPlay") {
                    if (value > 0) {
                        return ChartColors.brightred;
                    } else {
                        return "#a5a5a5";
                    }
                } else if (key === "gifPlay") {
                    if (value > 0) {
                        return ChartColors.mediumpurple;
                    } else {
                        return "#a5a5a5";
                    }
                } else if (key === "slideShowPlay") {
                    if (value > 0) {
                        return ChartColors.darkturquoise;
                    } else {
                        return "#a5a5a5";
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const getBgColors = (lableArr: any, opt: number) => {
        try {
            if (opt === 1) {
                let bgcolor = lableArr[0]?.chipColorCode;
                return bgcolor;
            } else if (opt === 2) {
                let bgcolor = lableArr;
                return bgcolor;
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const getFontColors = (lableArr: any, opt: number) => {
        try {
            if (opt === 1) {
                let color = lableArr[0]?.chipFontColorCode;
                return color;
            } else {
                let color = lableArr;
                return color;
            }
        } catch (error) {
            showBoundary(error)
        }


    };

    const getTooltip = (keys: any, item: any, i: number) => {
        try {
            if (keys !== null && keys !== undefined && keys !== "") {
                let data: any;
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i] === 'pageUrl') {
                        data = <div>
                            <p style={{ textTransform: 'capitalize' }}>{data}</p>
                            <p>({item[keys[i]]})</p>
                        </div>
                    } else {
                        data = data ?? '' + item[keys[i]];
                    }
                }
                return data;
            } else {
                return "";
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const tagThis = (data: any) => {
        props.tableEvent("tagPage", data.pageTitle);
    };

    const openLabelsModal = () => {
        try {
            getLabelsList();

            setValue((prevState: any) => {
                return {
                    ...prevState,
                    labelModalOpen: true,
                    chipsArray: cloneDeep(labelsModalData.labels),
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    };

    const getLabelsList = (apiErrorFlag?: string) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        let params = {};
        let path = ApiRelativePaths.GET_LABEL_LIST;
        let paramObj = {};

        getCall(params, "GET_LABEL_LIST", apiErrorFlag ?? '').then((data: any) => {
            if (data.result === "success") {

                setValue((prevState: any) => {
                    return {
                        ...prevState,
                        labelModalOpen: true,
                        labelChips: data.data,
                    }
                })

                for (let i = 0; i < labelsModalData.labelChips.length; i++) {
                    for (let j = 0; j < labelsModalData.labels.length; j++) {
                        if (
                            labelsModalData.labels[j].toLowerCase() ==
                            labelsModalData.labelChips[i].chipName.toLowerCase()
                        ) {
                            setTimeout(() => {
                                $(".chip_" + i).addClass("chipOutline");
                            }, 100);
                        }
                    }
                }
            } else if (data.result === 'retry') {
                setTimeout(() => {
                    getLabelsList('retry')
                }, RetryApi.TIMEOUT)
            }
        });
    };

    const selectChip = (chipName: any, i: number) => {
        try {
            let arr: any = [];
            arr = labelsModalData.chipsArray;

            if ($(".chip_" + i).hasClass("chipOutline")) {
                $(".chip_" + i).removeClass("chipOutline");
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === chipName) {
                        arr.splice(i, 1);
                    }
                }
            } else {
                if (arr.length < 9) {
                    arr.push(chipName);
                    $(".chip_" + i).addClass("chipOutline");
                }
            }

            setValue((prevState: any) => {
                return {
                    ...prevState,
                    labelModalOpen: true,
                    chipsArray: cloneDeep(arr),
                    labels: []
                }
            })

            if (labelsModalData.chipsArray.length === 0) {
                // $('.ages').prop('disabled', true);
            } else {
                $(".submitLabels").prop("disabled", false);
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const clearLabels = () => {
        try {
            $(".labelList").removeClass("active-i");
            for (let j = 0; j < labelsModalData.labelChips.length; j++) {
                $(".chip_" + j).removeClass("chipOutline");
            }

            setValue((prevState: any) => {
                return {
                    ...prevState,
                    chipsArray: [],
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    };

    const submitLabels = () => {
        try {
            $(".labelList").addClass("active-i");

            setValue((prevState: any) => {
                return {
                    ...prevState,
                    labelModalOpen: false,
                    labels: cloneDeep(labelsModalData.chipsArray),
                }
            })

            props.tableEvent("applyLabels", labelsModalData.chipsArray);
        } catch (error) {
            showBoundary(error)
        }

    };


    const routeToDetail = (index: number, key: any, value: any, arr: any) => {
        try {
            let tabledata = props.data.tableData;

            if (index === 0) {
                for (let i = 0; i < tabledata.length; i++) {

                    if (
                        tabledata[i].tagCode !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].tagCode === arr["tagCode"]
                    ) {
                        props.tableEvent("routeToDetail", { tagCode: tabledata[i].tagCode });
                        // this.childEvent.emit({ tagCode: tabledata[i].tagCode });
                    } else if (
                        tabledata[i].sentimentCode !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].sentimentCode === arr["sentimentCode"]
                    ) {
                        props.tableEvent("routeToDetail", {
                            sentimentCode: tabledata[i].sentimentCode,
                            isDeleted: tabledata[i].isDeleted,
                            sentimentTitle: tabledata[i].sentimentTitle,
                            appCode: tabledata[i].appCode,
                            appTitle: tabledata[i].appTitle,
                            appId: tabledata[i].appID,
                        });
                        // this.childEvent.emit({ sentimentCode: tabledata[i].sentimentCode, isDeleted: tabledata[i].isDeleted, appCode: tabledata[i].appCode, appTitle: tabledata[i].appTitle, appId: tabledata[i].appID });
                    } else if (
                        tabledata[i].workflowID !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].workflowID === arr["workflowID"] &&
                        tabledata[i].appCode !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].appCode === arr["appCode"] &&
                        tabledata[i].appTitle !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].appTitle === arr["appTitle"]
                    ) {
                        props.tableEvent("routeToDetail", {
                            workflowCode: tabledata[i].workflowID,
                            appCode: tabledata[i].appCode,
                            appId: tabledata[i].appID,
                            appTitle: tabledata[i].appTitle,
                            appIconImageURL: tabledata[i].appIconImageURL,
                            workflowTitle: tabledata[i].workflowTitle,
                            workflowType: (tabledata[i].workflowType === 'Guide Workflow' ? 'guide_workflow' : 'dynamic_workflow')
                        });
                        // this.childEvent.emit({ workflowCode: tabledata[i].workflowID, appCode: tabledata[i].appCode, appId: tabledata[i].appID, appTitle: tabledata[i].appTitle, appIconImageURL: tabledata[i].appIconImageURL });
                    } else if (
                        tabledata[i].workflowID !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].workflowID === arr["workflowID"]
                    ) {
                        props.tableEvent("routeToDetail", {
                            workflowCode: tabledata[i].workflowID,
                            appCode: tabledata[i].appCode,
                            appId: tabledata[i].appID,
                            appTitle: tabledata[i].appTitle,
                            workflowTitle: tabledata[i].workflowTitle,
                            workflowType: (tabledata[i].workflowType === 'Guide Workflow' ? 'guide_workflow' : 'dynamic_workflow')
                        });
                        // this.childEvent.emit({ workflowCode: tabledata[i].workflowID, appCode: tabledata[i].appCode, appId: tabledata[i].appID, appTitle: tabledata[i].appTitle });
                    } else if (
                        tabledata[i].guideID !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].guideID === arr["guideID"]
                    ) {
                        props.tableEvent("routeToDetail", {
                            guideID: tabledata[i].guideID,
                            guideTitle: tabledata[i].guideTitle,
                            appCode: tabledata[i].appCode,
                            appId: tabledata[i].appID,
                            appTitle: tabledata[i].appTitle,
                            tutorialGuideFlag: tabledata[i].tutorialPlayCounts ? true : false,
                            tooltipGuideFlag: props.tableComponent === 'tooltip' ? true : false,
                        });
                        // this.childEvent.emit({ guideID: tabledata[i].guideID, appCode: tabledata[i].appCode, appId: tabledata[i].appID, appTitle: tabledata[i].appTitle, appIconImageURL: tabledata[i].appIconImageURL });
                    }
                    else if (
                        tabledata[i].uniqueUserID !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].uniqueUserID === arr["uniqueUserID"]
                    ) {
                        const userEngagementId = btoa(tabledata[i].uniqueUserID);
                        props.tableEvent("routeToDetail", {
                            uniqueUserID: userEngagementId,
                            userDisplayName: tabledata[i].userDisplayName
                            // isDeleted: tabledata[i].isDeleted,
                            // appTitle: tabledata[i].appTitle,
                            // appId: tabledata[i].appID,
                        });
                    }
                    else if (
                        tabledata[i].guide_id !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].guide_id === arr["guide_id"]
                    ) {
                        props.tableEvent("routeToDetail", {
                            guide_id: tabledata[i].guide_id,
                            guide_title: tabledata[i].guide_title
                            // isDeleted: tabledata[i].isDeleted,
                            // appTitle: tabledata[i].appTitle,
                            // appId: tabledata[i].appID,
                        });
                    } else if (
                        tabledata[i].jobID !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].jobID === arr["jobID"]
                    ) {


                        props.tableEvent("routeToDetail", {
                            suiteID: tabledata[i].jobID,
                            suiteTitle: tabledata[i].jobTitle,
                            viewType: "jobs_list",
                            jobRunId: tabledata[i].jobRunID,
                        });
                    } else if (
                        tabledata[i].suiteID !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].suiteID === arr["suiteID"]
                    ) {
                        props.tableEvent("routeToDetail", {
                            suiteID: tabledata[i].suiteID,
                            suiteTitle: tabledata[i].suiteTitle,
                            viewType: tabledata[i].jobRunID ? 'job' : "suite"
                        });
                    }
                    else if (
                        tabledata[i].playCode !== undefined &&
                        tabledata[i][key] === value &&
                        tabledata[i].playCode === arr["playCode"]
                    ) {
                        props.tableEvent("routeToDetail", {
                            playCode: tabledata[i].playCode,
                            title: tabledata[i].title,
                        });
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const sortColumn = (value: string, index: number) => {
        try {
            forcePage = 0;
            indexVal = index;
            if (sortTitle[index] === undefined) {
                sortTitle[index] = "desc";
            } else {
                if (sortTitle[index] === "desc") {
                    sortTitle[index] = "asc";
                } else {
                    sortTitle[index] = "desc";
                }
            }

            setSortLoader({
                ...sortLoader,
                sortLoader: false
            });

            setSortVal({
                ...sortValue,
                sortVal: sortTitle[index],
                index: index,
            });

            const sortData = {
                sortKey: value,
                sortValue: sortTitle[index],
                index: index,
            };



            props.tableEvent("sortColumn", sortData);
        } catch (error) {
            showBoundary(error)
        }


    };

    const changePaginationClick = (e: any) => {
        try {
            props.tableEvent("changePage", e);
            forcePage = e;

            // if (e.selected === Math.floor(props.data.totalCount / paginationVar.perPage)) {
            //     $('.nexBtn' + props.tableId).parent().parent().addClass('disabled');
            // } else {
            //     $('.nexBtn' + props.tableId).parent().parent().removeClass('disabled');
            // }

            setTableLoader((prevState: any) => {
                return {
                    ...prevState,
                    flag: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    };

    const onChangeEntries = (e) => {
        try {
            paginationVar.perPage = e;
            forcePage = 0;

            props.tableEvent("changeEntries", e);
            setSortLoader({
                ...sortLoader,
                sortLoader: false
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const onChangeDynamicEntries = (e: ChangeEvent<HTMLSelectElement>, title: string) => {
        try {
            forcePage = 0;
            props.tableEvent("changeDynamicEntries", { data: e.target.value, title: title });
            setSortLoader({
                ...sortLoader,
                sortLoader: false
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const onHideDeleted = (e: any) => {
        try {
            forcePage = 0;
            props.tableEvent("hideDeleted", e.target.checked ? 1 : 0);
            setSortLoader({
                ...sortLoader,
                sortLoader: false
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            forcePage = 0;
            props.tableEvent("searchTxt", e.target.value);
            setSortLoader({
                ...sortLoader,
                sortLoader: false
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const exportExcel = () => {
        setExportExcelVal({ ...exportExcelModal, openExportExcelModal: true });
    };

    const getCsv = (viewOl: boolean) => {
        props.tableEvent("exportExcel", viewOl);
        setExportExcelVal({ ...exportExcelModal, openExportExcelModal: false });
    };

    const openLinkedModal = (guideId: number, sourceId: number) => {
        interface ids {
            guideId: number;
            sourceId: number;
        }

        const data: ids = {
            guideId: guideId,
            sourceId: sourceId,
        }

        props.tableEvent("linkedModal", data);
    }


    const getDivLabelBg = (label: string) => {
        try {
            switch (label) {
                case 'pass':
                    return '#76c761';
                case 'fail':
                    return '#ef6363';
                case 'incomplete':
                    return '#ffba51';
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getColumnFontColor = (text: string, data: any) => {
        try {
            switch (text) {
                case 'complete':
                case 'pass':
                    return ChartColors.lightgreen;

                case 'fail':
                    return ChartColors.brightred;

                case 'incomplete':
                    return ChartColors.orangesandybrown;

                default:
                    return ChartColors.grey
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    // const getHeaderIcon = (title: string, subTitle: any, sortOptions: any) => {
    //     try {
    //         let icon: any = '';
    //         let titles: any = '';

    //         switch (title) {
    //             case 'Guide Played':
    //                 icon = <Icon img="img/GuideMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'GuideMe Plays':
    //                 icon = <Icon img="img/GuideMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Do It For Me Played':
    //                 icon = <Icon img="img/DoItForMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'DoItForMe Plays':
    //                 icon = <Icon img="img/DoItForMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'ShowMe Played':
    //                 icon = <Icon img="img/ShowMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'ShowMe Plays':
    //                 icon = <Icon img="img/ShowMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'TestMe Played':
    //                 icon = <Icon img="img/TestMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'TestMe Plays':
    //                 icon = <Icon img="img/TestMe.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Survey':
    //                 icon = <Icon img="img/Sentiment.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Tutorial Plays':
    //                 icon = <Icon img="img/searchActivityTable.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Total Played':
    //                 icon = <Icon img="img/searchActivityTable.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Total Plays':
    //                 icon = <Icon img="img/searchActivityTable.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Guidance':
    //                 icon = <Icon img="img/searchActivityTable.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Validation':
    //                 icon = <Icon img="img/tooltip-validation.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Guidance & Validation':
    //                 icon = <Icon img="img/searchActivityTable.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Digital Duct Tape':
    //                 icon = <Icon img="img/tooltip-html.svg" widthSet className={"excel-icon"} />
    //                 break;

    //             case 'Power Form':
    //                 icon = <Icon img="img/tooltip-power-form.svg" widthSet className={"excel-icon"} />
    //                 break;


    //             default:
    //                 icon = <Icon img="img/searchActivityTable.svg" widthSet className={"excel-icon"} />
    //                 break;

    //         }

    //         return <>
    //             <>
    //                 {icon}
    //                 <span className="excel-icon">{sortOptions}</span>
    //             </>

    //             <>
    //                 <span>
    //                     <span className="column-title">{title}{sortOptions}</span>
    //                     <span className="column-title">{subTitle}</span>
    //                 </span>
    //             </>
    //         </>;
    //     } catch (error) {
    //         showBoundary(error)
    //     }

    // }


    // const changeWidth = (e: any, option: string) => {
    //     try {
    //         if (props.source === "guideAnalytics") {
    //             for (let itr0 = 1; itr0 <= 6; itr0++) {
    //                 $('.expandCell_' + itr0).removeClass('cellExpand');
    //                 $('.expandCell_' + itr0 + ' .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_' + itr0 + ' .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_1') || e.target.classList.contains('expandCell_1')) {
    //                 $('.expandCell_1').addClass('cellExpand');
    //                 $('.expandCell_1 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_1 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_2') || e.target.classList.contains('expandCell_2')) {
    //                 $('.expandCell_2').addClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_3') || e.target.classList.contains('expandCell_3')) {
    //                 $('.expandCell_3').addClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_4') || e.target.classList.contains('expandCell_4')) {
    //                 $('.expandCell_4').addClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_5') || e.target.classList.contains('expandCell_5')) {
    //                 $('.expandCell_5').addClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_6') || e.target.classList.contains('expandCell_6')) {
    //                 $('.expandCell_6').addClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').addClass('column-title-show');
    //             }
    //         }
    //         else if (props.source === "userEngagement") {
    //             for (let itr0 = 2; itr0 <= 6; itr0++) {
    //                 $('.expandCell_' + itr0).removeClass('cellExpand');
    //                 $('.expandCell_' + itr0 + ' .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_' + itr0 + ' .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_2') || e.target.classList.contains('expandCell_2')) {
    //                 $('.expandCell_2').addClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_3') || e.target.classList.contains('expandCell_3')) {
    //                 $('.expandCell_3').addClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_4') || e.target.classList.contains('expandCell_4')) {
    //                 $('.expandCell_4').addClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_5') || e.target.classList.contains('expandCell_5')) {
    //                 $('.expandCell_5').addClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_6') || e.target.classList.contains('expandCell_6')) {
    //                 $('.expandCell_6').addClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').addClass('column-title-show');
    //             }

    //         }
    //         else if (props.source === "dashboard") {
    //             for (let itr0 = 2; itr0 <= 7; itr0++) {
    //                 $('.expandCell_' + itr0).removeClass('cellExpand');
    //                 $('.expandCell_' + itr0 + ' .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_' + itr0 + ' .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_2') || e.target.classList.contains('expandCell_2')) {
    //                 $('.expandCell_2').addClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_3') || e.target.classList.contains('expandCell_3')) {
    //                 $('.expandCell_3').addClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_4') || e.target.classList.contains('expandCell_4')) {
    //                 $('.expandCell_4').addClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_5') || e.target.classList.contains('expandCell_5')) {
    //                 $('.expandCell_5').addClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_6') || e.target.classList.contains('expandCell_6')) {
    //                 $('.expandCell_6').addClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').addClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_7') || e.target.classList.contains('expandCell_7')) {
    //                 $('.expandCell_7').addClass('cellExpand');
    //                 $('.expandCell_7 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_7 .column-title').addClass('column-title-show');
    //             }

    //         }
    //     } catch (error) {
    //         showBoundary(error)
    //     }

    // }

    // const resetWidth = (e: any, option: string) => {
    //     try {
    //         if (props.source === "guideAnalytics") {
    //             if ($(e.target).parents().hasClass('expandCell_1') || e.target.classList.contains('expandCell_1')) {
    //                 $('.expandCell_1').removeClass('cellExpand');
    //                 $('.expandCell_1 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_1 .column-title').removeClass('column-title-show');

    //             }

    //             if ($(e.target).parents().hasClass('expandCell_2') || e.target.classList.contains('expandCell_2')) {
    //                 $('.expandCell_2').removeClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_3') || e.target.classList.contains('expandCell_3')) {
    //                 $('.expandCell_3').removeClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_4') || e.target.classList.contains('expandCell_4')) {
    //                 $('.expandCell_4').removeClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_5') || e.target.classList.contains('expandCell_5')) {
    //                 $('.expandCell_5').removeClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_6') || e.target.classList.contains('expandCell_6')) {
    //                 $('.expandCell_6').removeClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').removeClass('column-title-show');
    //             }
    //         }
    //         else if (props.source === "userEngagement") {
    //             if ($(e.target).parents().hasClass('expandCell_2') || e.target.classList.contains('expandCell_2')) {
    //                 $('.expandCell_2').removeClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').removeClass('column-title-show');

    //             }

    //             if ($(e.target).parents().hasClass('expandCell_3') || e.target.classList.contains('expandCell_3')) {
    //                 $('.expandCell_3').removeClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_4') || e.target.classList.contains('expandCell_4')) {
    //                 $('.expandCell_4').removeClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_5') || e.target.classList.contains('expandCell_5')) {
    //                 $('.expandCell_5').removeClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_6') || e.target.classList.contains('expandCell_6')) {
    //                 $('.expandCell_6').removeClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').removeClass('column-title-show');
    //             }

    //         }
    //         else if (props.source === "dashboard") {
    //             if ($(e.target).parents().hasClass('expandCell_2') || e.target.classList.contains('expandCell_2')) {
    //                 $('.expandCell_2').removeClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').removeClass('column-title-show');

    //             }

    //             if ($(e.target).parents().hasClass('expandCell_3') || e.target.classList.contains('expandCell_3')) {
    //                 $('.expandCell_3').removeClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_4') || e.target.classList.contains('expandCell_4')) {
    //                 $('.expandCell_4').removeClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_5') || e.target.classList.contains('expandCell_5')) {
    //                 $('.expandCell_5').removeClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_6') || e.target.classList.contains('expandCell_6')) {
    //                 $('.expandCell_6').removeClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').removeClass('column-title-show');
    //             }

    //             if ($(e.target).parents().hasClass('expandCell_7') || e.target.classList.contains('expandCell_7')) {
    //                 $('.expandCell_7').removeClass('cellExpand');
    //                 $('.expandCell_7 .excel-icon').removeClass('excel-icon-hide');
    //                 $('.expandCell_7 .column-title').removeClass('column-title-show');
    //             }

    //         }
    //     } catch (error) {
    //         showBoundary(error)
    //     }


    // }

    // useEffect(() => {
    // if(props.source === "guideAnalytics")
    // {

    //     if(!props.firstTimeloadFlag) {
    //         for(let itr0 = 1; itr0 <=6; itr0++){
    //             $('.expandCell_'+itr0).removeClass('cellExpand');
    //             $('.expandCell_'+itr0+' .excel-icon').removeClass('excel-icon-hide');
    //             $('.expandCell_'+itr0+' .column-title').removeClass('column-title-show');
    //         }

    //         switch (props.defaultSort['sortColumn_index']) {
    //             case 1: 
    //                 $('.expandCell_1').addClass('cellExpand');
    //                 $('.expandCell_1 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_1 .column-title').addClass('column-title-show');
    //                 break;

    //             case 2: 
    //                 $('.expandCell_2').addClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').addClass('column-title-show');
    //                 break;

    //             case 3: 
    //                 $('.expandCell_3').addClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').addClass('column-title-show');
    //                 break;

    //             case 4: 
    //                 $('.expandCell_4').addClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').addClass('column-title-show');
    //                 break;

    //             case 5: 
    //                 $('.expandCell_5').addClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').addClass('column-title-show');
    //                 break;

    //             case 5: 
    //                 $('.expandCell_6').addClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').addClass('column-title-show');
    //                 break;

    //             default:
    //                 break;
    //         }
    //     }

    // }

    // else if(props.source === "userEngagement")
    // {
    //     if(!props.firstTimeloadFlag) {
    //         for(let itr0 = 2; itr0 <=6; itr0++){
    //             $('.expandCell_'+itr0).removeClass('cellExpand');
    //             $('.expandCell_'+itr0+' .excel-icon').removeClass('excel-icon-hide');
    //             $('.expandCell_'+itr0+' .column-title').removeClass('column-title-show');
    //         }
    //         switch (props.defaultSort['sortColumn_index']) {
    //             case 1: 
    //                 $('.expandCell_2').addClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').addClass('column-title-show');
    //                 break;

    //             case 2: 
    //                 $('.expandCell_3').addClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').addClass('column-title-show');
    //                 break;

    //             case 3: 
    //                 $('.expandCell_4').addClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').addClass('column-title-show');
    //                 break;

    //             case 4: 
    //                 $('.expandCell_5').addClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').addClass('column-title-show');
    //                 break;

    //             case 5: 
    //                 $('.expandCell_6').addClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').addClass('column-title-show');
    //                 break;

    //             default:
    //                 break;
    //         }
    //     }

    // }

    // else if(props.source === "dashboard")
    // {
    //     if(!props.firstTimeloadFlag) {
    //         for(let itr0 = 2; itr0 <=7; itr0++){
    //             $('.expandCell_'+itr0).removeClass('cellExpand');
    //             $('.expandCell_'+itr0+' .excel-icon').removeClass('excel-icon-hide');
    //             $('.expandCell_'+itr0+' .column-title').removeClass('column-title-show');
    //         }
    //         switch (props.defaultSort['sortColumn_index']) {
    //             case 1: 
    //                 $('.expandCell_2').addClass('cellExpand');
    //                 $('.expandCell_2 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_2 .column-title').addClass('column-title-show');
    //                 break;

    //             case 2: 
    //                 $('.expandCell_3').addClass('cellExpand');
    //                 $('.expandCell_3 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_3 .column-title').addClass('column-title-show');
    //                 break;

    //             case 3: 
    //                 $('.expandCell_4').addClass('cellExpand');
    //                 $('.expandCell_4 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_4 .column-title').addClass('column-title-show');
    //                 break;

    //             case 4: 
    //                 $('.expandCell_5').addClass('cellExpand');
    //                 $('.expandCell_5 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_5 .column-title').addClass('column-title-show');
    //                 break;

    //             case 5: 
    //                 $('.expandCell_6').addClass('cellExpand');
    //                 $('.expandCell_6 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_6 .column-title').addClass('column-title-show');
    //                 break;

    //             case 6: 
    //                 $('.expandCell_7').addClass('cellExpand');
    //                 $('.expandCell_7 .excel-icon').addClass('excel-icon-hide');
    //                 $('.expandCell_7 .column-title').addClass('column-title-show');
    //                 break;

    //             default:
    //                 break;
    //         }
    //     }

    // }

    // }, [props])

    const getClasses = (data: any, i: any) => {
        try {
            let cnt = 0;

            if ((data['tagCode'] !== undefined) && data['pageViews'] > 0) {
                cnt = cnt + 1
            } else if ((data['featureCode'] !== undefined) && data['featureInteractions'] > 0) {
                cnt = cnt + 1
            } else if ((data['userID'] !== undefined) && (data['featureInteractions'] > 0 && data['userID'] !== '0')) {
                cnt = cnt + 1
            } else if ((data['sentimentCode'] !== undefined) && (data['sentimentResponses'] > 0 && (data['appCode'] !== "" || data['appID'] !== ""))) {
                cnt = cnt + 1
            } else if ((data['conversationCode'] !== undefined) && (data['conversationResponses'] > 0)) {
                cnt = cnt + 1
            } else if ((data['pageVisits'] !== undefined) && (data['pageVisits'] > 0)) {
                cnt = cnt + 1
            }


            if (i == 0) {
                if (data['tagCode'] == undefined &&
                    data['featureCode'] == undefined &&
                    data['userID'] == undefined &&
                    data['sentimentCode'] == undefined &&
                    data['conversationCode'] == undefined &&
                    data['pageVisits'] == undefined) {
                    cnt = cnt + 1
                }
            }

            if (data['isLinkable'] === false) {
                cnt = 0
            }

            if (data['powerFormCount']) {
                cnt = 0
            }

            return cnt > 0 ? ' showCursor pointer' : ' hideCursor'
        } catch (error) {
            showBoundary(error)
        }

    }

    const getRoutePath = (data: any, i: any, d: any) => {
        try {
            let cnt = 0;
            if ((data['tagCode'] !== undefined) && data['pageViews'] > 0) {
                cnt = cnt + 1
            } else if ((data['featureCode'] !== undefined) && data['featureInteractions'] > 0) {
                cnt = cnt + 1
            } else if ((data['userID'] !== undefined) && (data['featureInteractions'] > 0 && data['userID'] !== '0')) {
                cnt = cnt + 1
            } else if ((data['sentimentCode'] !== undefined) && (data['sentimentResponses'] > 0 && (data['appCode'] !== "" || data['appID'] !== ""))) {
                cnt = cnt + 1
            } else if ((data['conversationCode'] !== undefined) && (data['conversationResponses'] > 0)) {
                cnt = cnt + 1
            } else if ((data['pageVisits'] !== undefined) && (data['pageVisits'] > 0)) {
                cnt = cnt + 1
            }


            if (i == 0) {
                if (data['tagCode'] == undefined &&
                    data['featureCode'] == undefined &&
                    data['userID'] == undefined &&
                    data['sentimentCode'] == undefined &&
                    data['conversationCode'] == undefined &&
                    data['pageVisits'] == undefined) {
                    cnt = cnt + 1
                }
            }

            if (data['isLinkable'] === false) {
                cnt = 0
            }

            if (data['powerFormCount']) {
                cnt = 0
            }

            return cnt > 0 ? routeToDetail(
                i,
                d.associatedDataField,
                data[d.associatedDataField],
                data
            ) : null
        } catch (error) {
            showBoundary(error)
        }

    }


    const headerTitlesMap = new Map<string, string>([
        ['Guide Title', insightsLbls.guideTitle],
        ['App Title', insightsLbls.appTitle],
        ['Total Played', insightsLbls.totalPlayed],
        ['GuideMe Plays', insightsLbls.guidePlayed],
        ['Tutorial Plays', insightsLbls.tutorialGuidePlayed],
        ['DoItForMe Plays', insightsLbls.doItForMePlayes],
        ['ShowMe Plays', insightsLbls.showMePlays],
        ['TestMe Plays', insightsLbls.testMePlays],
        ['Total Plays', insightsLbls.totalPlaysLabel],
        ['Guidance', insightsLbls.guidanceLabel],
        ['Validation', insightsLbls.validation],
        ['Guidance & Validation', insightsLbls.guidanceAndValidation],
        ['Digital Duct Tape', insightsLbls.digitalDuctTapeLabel],
        ['Power Form', insightsLbls.powerFormLabel],
        ['Guide Played', insightsLbls.guidePlayed],
        ['Do It For Me Played', insightsLbls.doItForMePlayed],
        ['ShowMe Played', insightsLbls.showMePlayed],
        ['TestMe Played', insightsLbls.testMePlayed],
        ['Survey', insightsLbls.myGuideSurveysPlayed],
        ['Name', insightsLbls.name],
        ['Email', insightsLbls.email],
    ]);

    const getHeaderTitle = (title: string) => {
        return headerTitlesMap.get(title) || title;
    }

    const getLocalizedSubHeadingLabel = (key: any) => {
		const val = subHeadingLabel.has(key) ? subHeadingLabel.get(key) : key;
		return <span>({val})</span>
	}

    return (
        <>
            {props.data.hasOwnProperty('tableData') && (
                <>
                    <div>
                        {props.data.tableHeaders ?
                            (

                                <React.Fragment>
                                    <div className="displayContents">
                                        <div className="col-sm-12 displayFlex positionSticky padding0">
                                            <div className="col-sm-12 col-12 displayFlex">
                                                <div className=" col-8 col-lg-8 padding0 marginTop-10">
                                                    {props.showSearch ? (
                                                        <p className="search-field-wrapper">
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                className="searchIcon form-control round-input"
                                                                onChange={(event) => onSearchText(event)}
                                                                placeholder={insightsLbls && insightsLbls['search']}
                                                                disabled={props.data.totalCount === 0 && props.firstTimeloadFlag}
                                                            />
                                                        </p>
                                                    ) : (
                                                        <p></p>
                                                    )}
                                                </div>
                                                <div className="col-4 col-lg-4 padding0 marginTop-10">
                                                    {props.showExport ? (
                                                        <p className="export-button marginTB-8 pull-right">
                                                            <button
                                                                className="form-control btn btn-primary"
                                                                data-toggle="modal"
                                                                onClick={() => exportExcel()}
                                                                disabled={props.data.totalCount === 0}

                                                            >
                                                                {/* <img
                                                                src={
                                                                    require("../../assets/images/export-excel.png")
                                                                }
                                                                width="22"
                                                                height="auto"
                                                                alt=""
                                                                className="excel-icon"
                                                            /> */}
                                                                {insightsLbls['exportToExcel']}
                                                            </button>
                                                        </p>
                                                    ) : (
                                                        <p></p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"col-12 padding0 floatRight font13 commonTable alignCenter displayFlex positionSticky left0" + `${props.modalTable ? " marginLeft-20" : ""}`}>
                                            {/* {props.showEntries ? (
                                                <div className="selectCustomColor displayGrid">
                                                    <span className="startAlign">{insightsLbls['showEntries']}</span>
                                                    <select
                                                        className="entries-dropdown"
                                                        onChange={(event) => onChangeEntries(event)}
                                                        value={props.tablePagination.perPage}
                                                        disabled={props.data.totalCount === 0}
                                                    >
                                                        {filterBy.map((option, index) => {
                                                            return <option value={option.key} key={index}>{option.value}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )} */}

                                            {props.dynamicSort ? (
                                                <>
                                                    {props.dynamicSort.map((option: any, index: number) => {
                                                        return (
                                                            <div className="selectCustomColor displayGrid marginLeft-10 width120">
                                                                <span className="startAlign">{option['title']}</span>
                                                                <select
                                                                    className="entries-dropdown"
                                                                    onChange={(event) => onChangeDynamicEntries(event, option['title'])}
                                                                    value={option['defaultValue']}

                                                                >
                                                                    {option['value']?.map((option1: any, index1: number) => {
                                                                        return <option value={option1.key} key={index1}>{option1.value}</option>;
                                                                    })}
                                                                </select>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <div></div>
                                            )}

                                            <>
                                                {props.hideDeleted && (
                                                    <div className="marginLeft-5 marginTop25 custom-control custom-checkbox mr-sm-2 check-container">
                                                        <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                                            <span className="marginLeft-30">{props.checkboxText}</span>
                                                            {/* [checked]="hide_deleted==1" */}
                                                            {/* [disabled]="!((firstTimeLoad === true && powerForm.length!=0) || firstTimeLoad === false)" */}
                                                            <input type="checkbox"
                                                                className="custom-control-input chk checkbox"
                                                                id="customControlAutosizing1"
                                                                onClick={(event) => onHideDeleted(event)}
                                                                name="chk-box"
                                                                value="delete"
                                                                disabled={props.data.totalCount === 0 && props.firstTimeloadFlag} />
                                                            <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>
                                                        </label>
                                                    </div>
                                                )}

                                            </>
                                        </div>
                                    </div>


                                <table
                                    className={"table color_table commonTable tableId" + props.tableId}
                                >
                                    <thead className="bg-secondary sorting-header">
                                        <tr>
                                            {props.data.tableHeaders.map((header: any, index: number) => {
                                                let subTitle;
                                                if (header.subTitle !== "") {
                                                    subTitle = (
                                                        <p className="marginBottom-0 font10 font600" key={index}>
                                                            {getLocalizedSubHeadingLabel(header.subTitle)}
                                                        </p>
                                                    );
                                                }

                                                    let labelFilterIcon;
                                                    // if (
                                                    // 	header.fieldDataType === "chips" &&
                                                    // 	!location.pathname.includes("dashboard")
                                                    // ) {
                                                    // 	let countChips = labelsModalData.labels.length;
                                                    // 	let countTag;
                                                    // 	// onClick="openLabelModal(content)"
                                                    // 	if (countChips > 0) {
                                                    // 		countTag = <span className="chipCount" key={index}>{countChips}</span>;
                                                    // 	}
                                                    // 	labelFilterIcon = (
                                                    // 		<span
                                                    // 			className={
                                                    // 				"hand-pointer " + (countChips > 0 ? "active-i" : "")
                                                    // 			}
                                                    // 			onClick={() => openLabelsModal()}
                                                    // 		>
                                                    // 			<i className="fa fa-filter labelList"></i>
                                                    // 			{countTag}
                                                    // 		</span>
                                                    // 	);
                                                    // }

                                                    let sortIcons;
                                                    if (header.isSortAllowed === true) {
                                                        sortIcons = (
                                                            <span
                                                                className="hand-pointer displayInlineBlock"
                                                                onClick={() => sortColumn(header.sortKey, index)}
                                                                key={index}
                                                            >
                                                                <Icon className="menu-icon-img" img={`img/sortIcon.svg`} />

                                                                {/* <i
                                                                className={
                                                                    "fa fa-sort-asc height0 displayBlock descending " +
                                                                    (sortTitle[index] === "asc" && indexVal === index
                                                                        ? "active-i"
                                                                        : "")
                                                                }
                                                            ></i>
                                                            <i
                                                                className={
                                                                    "fa fa-sort-desc height0 displayBlock ascending " +
                                                                    (sortTitle[index] === "desc" && indexVal === index
                                                                        ? "active-i"
                                                                        : "")
                                                                }
                                                            ></i> */}
                                                            </span>
                                                        );
                                                    }

                                                    return (
                                                        // onMouseOver={(ev) => changeWidth(ev, 'th')}
                                                        // onMouseOut={(ev) => resetWidth(ev, 'th')}
                                                        <th
                                                            className={
                                                                (index === 0 ? "th-left-border" : "") +
                                                                (index === props.data.tableHeaders.length - 1
                                                                    ? "th-right-border"
                                                                    : "")
                                                                + ' expandCell_' + index
                                                            }

                                                            key={index}
                                                        >
                                                            {/* {} */}
                                                            {/* {header.displayTitle === 'Guide Title' || header.displayTitle === 'Email' || header.displayTitle === 'Name' || header.displayTitle === 'App Title' ? (
                                                            <>
                                                                {header.displayTitle}
                                                                {sortIcons}
                                                            </>
                                                        )
                                                            : getHeaderIcon(header.displayTitle, subTitle, sortIcons)}


                                                        {header.displayTitle === 'Guide Title' || header.displayTitle === 'Email' || header.displayTitle === 'Name' || header.displayTitle === 'App Title' && (
                                                            <>
                                                                <span>{subTitle}</span>
                                                            </>
                                                        )} */}


                                                            <p className="marginBottom-0">
                                                                <span>{getHeaderTitle(header.displayTitle)}</span>
                                                                <span className="sortIcons-span">{sortIcons}</span>
                                                            </p>
                                                            <span>{subTitle}</span>


                                                            {/* <span>{subTitle}</span> */}

                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>


                                        {tableLoader.flag ? (
                                            <>
                                                {sortLoader.sortLoader ? (
                                                    props.data.tableData.length !== 0 ? (


                                                        <tbody>
                                                            {props.data.tableData.map((data: any, index: number) => {

                                                                return (
                                                                    <tr>
                                                                        {props.data.tableHeaders.map((d: any, i: number) => {
                                                                            let text;
                                                                            let tooltipText = getTooltip(d.altText, data, i);

                                                                            if (
                                                                                (d.fieldDataType === "string"
                                                                                    || d.fieldDataType === "number"
                                                                                    || d.fieldDataType === "label") &&
                                                                                d.fieldDataType !== "chips" &&
                                                                                d.fieldDataType !== "button" &&
                                                                                d.fieldDataType !== "ratio-bar"
                                                                            ) {
                                                                                if (tooltipText) {
                                                                                    text = (
                                                                                        <div className="displayFlex alignCenter" key={index}>
                                                                                            {data['linkedGuideID'] && (<i className="fa fa-link marginRight-5 top5 positionRelative"></i>)}
                                                                                            <div

                                                                                                data-tip
                                                                                                data-for={
                                                                                                    "txtTooltip_" +
                                                                                                    props.tableId +
                                                                                                    "_" +
                                                                                                    i +
                                                                                                    "_" +
                                                                                                    index
                                                                                                }
                                                                                                data-class="reactTooltipStyle"
                                                                                                className={
                                                                                                    "ellipsistext textCapitalize" +
                                                                                                    (d.altText !== null ? " tableContent displayInlineBlock " : "")
                                                                                                    + (data['linkedGuideID'] ? ' showCursor' : (props.linkPresent ? getClasses(data, i) : ' hideCursor'))}
                                                                                                onClick={() => {
                                                                                                    getRoutePath(data, i, d);
                                                                                                    { data['linkedGuideID'] && openLinkedModal(data['linkedGuideID'], data['guideID']) }
                                                                                                }
                                                                                                }
                                                                                            >
                                                                                                {data[d.associatedDataField]}
                                                                                                <ReactTooltip
                                                                                                    id={
                                                                                                        "txtTooltip_" +
                                                                                                        props.tableId +
                                                                                                        "_" +
                                                                                                        i +
                                                                                                        "_" +
                                                                                                        index
                                                                                                    }
                                                                                                    place="bottom"
                                                                                                >
                                                                                                    {tooltipText}
                                                                                                </ReactTooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                } else {
                                                                                    text = (
                                                                                        <div
                                                                                            className={
                                                                                                "tableContent ellipsistext  textCapitalize " +
                                                                                                ((d.altText !== null ? "displayInlineBlock" : "") + ((props.linkPresent && i === 0) ? ' showCursor' : ' hideCursor'))
                                                                                            }


                                                                                            style={{ color: getColumnFontColor(data[d.associatedDataField], data) ?? '#000000' }}


                                                                                            onClick={() =>
                                                                                                routeToDetail(
                                                                                                    i,
                                                                                                    d.associatedDataField,
                                                                                                    data[d.associatedDataField],
                                                                                                    data
                                                                                                )
                                                                                            }

                                                                                        >
                                                                                            {data[d.associatedDataField]}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            }

                                                                            let deletedTag;
                                                                            if (i === 0 && data.isDeleted === true) {
                                                                                deletedTag = (
                                                                                    <span className="stepDeleted">({insightsLbls['deleted']})</span>
                                                                                );
                                                                            }

                                                                            let ratioBar;
                                                                            if (d.fieldDataType === "ratio-bar") {
                                                                                ratioBar = (
                                                                                    <div
                                                                                        className="ratioBar"
                                                                                        data-tip
                                                                                        data-for={
                                                                                            "ratioBarTooltip_" +
                                                                                            props.tableId +
                                                                                            "_" +
                                                                                            i +
                                                                                            "_" +
                                                                                            index
                                                                                        }
                                                                                        data-class="reactTooltipStyle"
                                                                                    >
                                                                                        {Object.keys(data[d.associatedDataField]).map(
                                                                                            (item: any, index: number) => {
                                                                                                let barDivisionsStyle = {
                                                                                                    width: getWidth(
                                                                                                        data[d.associatedDataField],
                                                                                                        item
                                                                                                    ),
                                                                                                    backgroundColor: getBarColor(
                                                                                                        item,
                                                                                                        data[d.associatedDataField][item]
                                                                                                    ),
                                                                                                };
                                                                                                return (
                                                                                                    <div
                                                                                                        key={index}
                                                                                                        className="ratioBarDivisions"
                                                                                                        style={barDivisionsStyle}
                                                                                                    ></div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                        <ReactTooltip
                                                                                            id={
                                                                                                "ratioBarTooltip_" +
                                                                                                props.tableId +
                                                                                                "_" +
                                                                                                i +
                                                                                                "_" +
                                                                                                index
                                                                                            }
                                                                                            place="bottom"
                                                                                        >
                                                                                            <span>
                                                                                                {Object.keys(data[d.associatedDataField]).map(
                                                                                                    (item: any, index: number) => {
                                                                                                        return (
                                                                                                            <span
                                                                                                                key={index}
                                                                                                                className="ratio-color spacing widthAuto"
                                                                                                                style={{
                                                                                                                    backgroundColor: getBarColor(
                                                                                                                        item,
                                                                                                                        data[d.associatedDataField][item]
                                                                                                                    ),
                                                                                                                }}
                                                                                                            >
                                                                                                                {item}:{" "}
                                                                                                                {data[d.associatedDataField][item]}
                                                                                                            </span>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </span>
                                                                                        </ReactTooltip>
                                                                                    </div>
                                                                                );
                                                                            }

                                                                            let btn;
                                                                            if (d.fieldDataType === "button") {
                                                                                btn = (
                                                                                    <span>
                                                                                        <button
                                                                                            className="tabSwitch-btn1 taggedBtn cursorPointer"
                                                                                            onClick={() => tagThis(data)}
                                                                                        >
                                                                                            {data[d.associatedDataField]}
                                                                                        </button>
                                                                                    </span>
                                                                                );
                                                                            }

                                                                            let label;
                                                                            let additionalLabels;
                                                                            let divLable;
                                                                            if (d.fieldDataType === "chips") {
                                                                                if (data[d.associatedDataField] === "") {
                                                                                    label = <span className="tableContent"> - </span>;
                                                                                } else {
                                                                                    if (data[d.associatedDataField] !== undefined) {
                                                                                        let chipStyle = {
                                                                                            backgroundColor: getBgColors(
                                                                                                data[d.associatedDataField],
                                                                                                1
                                                                                            ),
                                                                                            color: getFontColors(
                                                                                                data[d.associatedDataField],
                                                                                                1
                                                                                            ),
                                                                                        };

                                                                                        if (data[d.associatedDataField].length > 1) {
                                                                                            additionalLabels = (
                                                                                                <div
                                                                                                    className="statusChip statusChipTable marginLeft-5 displayInlineBlock tooltipText"
                                                                                                    data-class="reactTooltipStyle"
                                                                                                    data-tip
                                                                                                    data-for={
                                                                                                        "labelChipsTooltip_" +
                                                                                                        props.tableId +
                                                                                                        "_" +
                                                                                                        i +
                                                                                                        "_" +
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    +{data[d.associatedDataField].length - 1}
                                                                                                    <ReactTooltip
                                                                                                        id={
                                                                                                            "labelChipsTooltip_" +
                                                                                                            props.tableId +
                                                                                                            "_" +
                                                                                                            i +
                                                                                                            "_" +
                                                                                                            index
                                                                                                        }
                                                                                                        place="bottom"
                                                                                                    >
                                                                                                        <span>
                                                                                                            {data[d.associatedDataField].map(
                                                                                                                (chip: any, j: number) => {
                                                                                                                    if (j !== 0) {
                                                                                                                        let chipListStyle = {
                                                                                                                            backgroundColor: getBgColors(
                                                                                                                                chip.chipColorCode,
                                                                                                                                2
                                                                                                                            ),
                                                                                                                            color: getFontColors(
                                                                                                                                chip.chipFontColorCode,
                                                                                                                                2
                                                                                                                            ),
                                                                                                                        };
                                                                                                                        return (
                                                                                                                            <span
                                                                                                                                key={j}
                                                                                                                                style={chipListStyle}
                                                                                                                                className="statusChip statusChipTable spacing widthAuto"
                                                                                                                            >
                                                                                                                                {chip.chipName}
                                                                                                                            </span>
                                                                                                                        );
                                                                                                                    }
                                                                                                                }
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </ReactTooltip>
                                                                                                </div>
                                                                                            );
                                                                                        }


                                                                                        label = (
                                                                                            <span className="statusChip statusChipTable" style={Object.assign({}, chipStyle, data[d.associatedDataField][0]?.chipName?.length > 0 ? { top: '6px' } : { backgroundColor: 'white' })}>
                                                                                                {data[d.associatedDataField][0]?.chipName}
                                                                                            </span>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }

                                                                            if (d.fieldDataType === "label") {
                                                                                divLable = (
                                                                                    <span className={" verticalMiddle statusChip"} style={{ backgroundColor: getDivLabelBg(data[d.associatedDataField]) }}>
                                                                                        {data[d.associatedDataField]}
                                                                                    </span>
                                                                                )
                                                                            }

                                                                            return (
                                                                                // onMouseOver={(ev) => changeWidth(ev, 'tb')}
                                                                                // onMouseOut={(ev) => resetWidth(ev, 'tb')}
                                                                                <td
                                                                                    className={
                                                                                        "tableTooltip " +
                                                                                        (d.fieldDataType === "chips" ? " " : " ") +
                                                                                        (d.fieldDataType === "chips" ? "marginTop--1" : " ")
                                                                                        + " expandCell_" + i
                                                                                    }



                                                                                >
                                                                                    {/* for string and numbers */}
                                                                                    {divLable ?? text}

                                                                                    {/* for deleted tag */}
                                                                                    {deletedTag}

                                                                                    {/* for bar color */}
                                                                                    {ratioBar}

                                                                                    {/* for buttons */}
                                                                                    {btn}

                                                                                    {/* for labels */}
                                                                                    {label}

                                                                                    {additionalLabels}


                                                                                </td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            <tr className="noDataText-fontCommonTable noDataText-font">
                                                                <td
                                                                    className="textCenter noDataText-font"
                                                                    colSpan={props.data.tableHeaders.length}
                                                                >
                                                                    {insightsLbls.noRecordFound}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )) : (
                                                    <tbody>
                                                        <tr className="noDataText-fontCommonTable noDataText-font">
                                                            <td
                                                                className="textCenter noDataText-font"
                                                                colSpan={props.data.tableHeaders.length}
                                                            >
                                                                <Loader></Loader>
                                                            </td>
                                                        </tr>
                                                    </tbody>)
                                                }
                                            </>
                                        ) : (<tr className="noDataText-fontCommonTable noDataText-font">
                                            <td
                                                className="textCenter noDataText-font"
                                                colSpan={props.data.tableHeaders.length}
                                            >
                                                <Loader></Loader>
                                            </td>
                                        </tr>)}
                                    </table>



                                    <Modal
                                        onHide={() => setValue((prevState: any) => {
                                            return {
                                                ...prevState,
                                                labelModalOpen: false
                                            }
                                        })}
                                        show={labelsModalData.labelModalOpen}
                                        bsSize="medium"
                                    >
                                        <Modal.Header>
                                            <Modal.Title>{insightsLbls['showLabels']}</Modal.Title>
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={() =>
                                                    setValue((prevState: any) => {
                                                        return {
                                                            ...prevState,
                                                            labelModalOpen: false
                                                        }
                                                    })
                                                }
                                            >
                                                <span aria-hidden="true">×</span>
                                                <span className="sr-only">{insightsLbls['close']}</span>
                                            </button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="widthAuto labelsDiv">
                                                {labelsModalData.labelChips.map(function (chip: any, index: any) {
                                                    let chipStyle = {
                                                        backgroundColor: chip.chipColorCode,
                                                        color: chip.chipFontColorCode,
                                                    };
                                                    return (
                                                        <span
                                                            key={index}
                                                            style={chipStyle}
                                                            className={"statusChip labelChips font12 floatLeft chip_" + index}
                                                            onClick={() => selectChip(chip.chipName, index)}
                                                        >
                                                            {chip.chipName}
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <div className="col-lg-12">
                                                {labelsModalData.chipsArray.length === 9 && (<span className="font12 warningMsg-text">
                                                    {insightsLbls['max9Allowed']}
                                                </span>)}

                                                <button
                                                    className="btn modal-cancel-btn clearBtn submitLabels floatRight"
                                                    onClick={() => clearLabels()}
                                                >
                                                    {insightsLbls['clearAll']}
                                                </button>
                                                <button
                                                    className="btn modal-save-btn modalSaveBtn submitLabels floatRight"
                                                    onClick={() => submitLabels()}
                                                >
                                                    {insightsLbls['apply']}
                                                </button>
                                            </div>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal
                                        onHide={() =>
                                            setExportExcelVal({
                                                ...exportExcelModal,
                                                openExportExcelModal: false,
                                            })
                                        }
                                        show={exportExcelModal.openExportExcelModal}
                                        bsSize='medium'
                                    >
                                        <Modal.Header>
                                            <Modal.Title>{insightsLbls['exportExcel']}</Modal.Title>
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={() =>
                                                    setExportExcelVal({
                                                        ...exportExcelModal,
                                                        openExportExcelModal: false,
                                                    })
                                                }
                                            >
                                                <span aria-hidden="true">×</span>
                                                <span className="sr-only">{insightsLbls['close']}</span>
                                            </button>
                                        </Modal.Header>
                                        <Modal.Body className="displayFlex height100-percent">
                                            <div className="col-lg-12 paddingTB-20 displayFlex btns-div">
                                                <div className="downloadCsv col-lg-6 padding0">
                                                    {/* (click)="getCsv(false)" */}
                                                    <span
                                                        className="btn btn-primary font13 width100"
                                                        onClick={() => getCsv(false)}
                                                    >
                                                        {" "}
                                                        <i className="fa fa-download" aria-hidden="true"></i> {insightsLbls['download']}
                                                    </span>
                                                </div>
                                                <div className="marginTop-25">
                                                    <div className="vl"></div>
                                                    <div className="or-div">{insightsLbls['or']}</div>
                                                </div>
                                                <p className="text font14 marginLeft-10 font500 excelText">
                                                    {insightsLbls['excelViewerQn']}
                                                </p>

                                                <div className="downloadCsv col-lg-6 padding0">
                                                    {/* (click)="getCsv(true)" */}
                                                    <span
                                                        className="btn btn-primary font13"
                                                        onClick={() => getCsv(true)}>
                                                        <i className="fa fa-cloud" aria-hidden="true"></i> {insightsLbls['viewOnline']}
                                                    </span>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </React.Fragment>
                            )
                            : (<div>{insightsLbls.serviceNotAvailable}</div>)
                        }
                    </div>
                    {
                        props.data.totalCount > 0 ?
                            (
                                <div className="positionSticky">
                                    {/* <div className="countContainer">
                                    <span className="paginations-showingOf-text marginLeft-5">{insightsLbls['showing']} </span>
                                    <span className="paginations-count-text">{((forcePage + 1) * paginationVar.perPage) - (paginationVar.perPage - 1)}</span>
                                    <span className="paginations-showingOf-text"> - </span>
                                    {
                                        ((forcePage + 1) * paginationVar.perPage < props.data.totalCount) ?
                                            (<span className="paginations-count-text">{(forcePage + 1) * paginationVar.perPage}</span>)
                                            : (<span className="paginations-count-text">{props.data.totalCount}</span>)
                                    }
                                    <span className="paginations-showingOf-text marginLeft-5"> {insightsLbls['of']} </span>
                                    <span className="paginations-count-text">{props.data.totalCount}</span>
                                </div> */}
                                    {/* <div className="paginationContainer positionSticky">
                                    <ReactPaginate
                                        previousLabel={<span aria-hidden="true">«</span>}
                                        nextLabel={<span className={"nexBtn" + props.tableId} aria-hidden="true">»</span>}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={props.data.totalCount / paginationVar.perPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={(event) => changePaginationClick(event)}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        forcePage={forcePage}
                                    />
                                </div> */}
                                    <Pagination
                                        pageNumber={forcePage}
                                        showEntries={true}
                                        totalCount={props.data.totalCount}
                                        pageLimit={paginationVar.perPage}
                                        handleChange={(event) => changePaginationClick(event)}
                                        changeLimit={(event) => onChangeEntries(event)}
                                        rowsLabel={insightsLbls.rowsPerPage}
                                    />
                                </div>
                            ) : (null)
                    }
                </>



            )
            }

        </>
    )
};

export default React.memo(CommonExpandableTable);