import * as ActionTypes from '../ActionTypes';
import axiosInstance, {getInstance} from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths } from '../../../utils/GlobalConstants';
import axios from 'axios';


let ApiPaths: any = ApiRelativePaths;

export const setDashboardGuideMeChart = (dashboardGuideMeChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDEME_CHART_DATA,
        dashboardGuideMeChartData: dashboardGuideMeChartData
    }
};

export const setDashboardShowMeChart = (dashboardShowMeChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_SHOWME_CHART_DATA,
        dashboardShowMeChartData: dashboardShowMeChartData
    }
};

export const setDashboardTestMeChart = (dashboardTestMeChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_TESTME_CHART_DATA,
        dashboardTestMeChartData: dashboardTestMeChartData
    }
};

export const setDashboardDoItForMeChart = (dashboardDoItForMeChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_DO_IT_FOR_ME_CHART_DATA,
        dashboardDoItForMeChartData: dashboardDoItForMeChartData
    }
};

export const setDashboardTooltipChart = (dashboardTooltipChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_TOOLTIP_CHART_DATA,
        dashboardTooltipChartData: dashboardTooltipChartData
    }
};

export const setDashboardPushnotificationChart = (dashboardPushnotificationChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_PUSHNOTIFICATION_CHART_DATA,
        dashboardPushnotificationChartData: dashboardPushnotificationChartData
    }
};

export const setDashboardBeaconChart = (dashboardBeaconChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_BEACON_CHART_DATA,
        dashboardBeaconChartData: dashboardBeaconChartData
    }
};

export const setDashboardSurveyChart = (dashboardSurveyChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_SURVEY_CHART_DATA,
        dashboardSurveyChartData: dashboardSurveyChartData
    }
};

export const setDashboardPowerhtmlChart = (dashboardPowerhtmlChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_POWERHTML_CHART_DATA,
        dashboardPowerhtmlChartData: dashboardPowerhtmlChartData
    }
};

export const setDashboardDucttapeChart = (dashboardDucttapeChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_DUCTTAPE_CHART_DATA,
        dashboardDucttapeChartData: dashboardDucttapeChartData
    }
};

export const setDashboardGuidancevalidationChart = (dashboardGuidancevalidationChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDANCEVALIDATION_CHART_DATA,
        dashboardGuidancevalidationChartData: dashboardGuidancevalidationChartData
    }
};

export const setDashboardGuidanceChart = (dashboardGuidanceChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDANCE_CHART_DATA,
        dashboardGuidanceChartData: dashboardGuidanceChartData
    }
};



export const setDashboardGuideTutorialsChart = (dashboardGuideTutorialsChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDE_TUTORIALS_CHART_DATA,
        dashboardGuideTutorialsChartData: dashboardGuideTutorialsChartData
    }
};

export const setDashboardValidationChart = (dashboardValidationChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_VALIDATION_CHART_DATA,
        dashboardValidationChartData: dashboardValidationChartData
    }
};

export const setDashboardGuidesTable = (dashboardGuidesTableData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDES_TABLE_DATA,
        dashboardGuidesTableData: dashboardGuidesTableData
    }
};


export const setDashboardGuideSummaryTable = (dashboardGuideSummaryTableData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDE_SUMMARY_TABLE_DATA,
        dashboardGuideSummaryTableData: dashboardGuideSummaryTableData
    }
};


export const setDashboardTooltipSummaryTable = (dashboardTooltipSummaryTableData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_TOOLTIP_SUMMARY_TABLE_DATA,
        dashboardTooltipSummaryTableData: dashboardTooltipSummaryTableData
    }
};

export const setDashboardWorkflowChart = (dashboardWorkflowChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_WORKFLOWS_CHART_DATA,
        dashboardWorkflowChartData: dashboardWorkflowChartData
    }
};

export const setDashboardWorkflowsTable = (dashboardWorkflowsTableData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_WORKFLOWS_TABLE_DATA,
        dashboardWorkflowsTableData: dashboardWorkflowsTableData
    }
};

export const setDashboardSurveysChart = (dashboardSurveysChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_SURVEY_DATA,
        dashboardSurveysChartData: dashboardSurveysChartData
    }
};

export const setDashboardSurveysTable = (dashboardSurveysTableData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_SURVEYS_TABLE_DATA,
        dashboardSurveysTableData: dashboardSurveysTableData
    }
};


export const setDashboardCountriesChart = (dashboardCountriesChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_CONTRIES_CHART_DATA,
        dashboardCountriesChartData: dashboardCountriesChartData
    }
};
export const setDashboardGuidesChart = (dashboardGuidesChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDES_CHART_DATA,
        dashboardGuidesChartData: dashboardGuidesChartData
    }
};
export const setDashboardGuidesDonutChart = (dashboardGuidesDonutChartData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDES_DONUT_CHART_DATA,
        dashboardGuidesDonutChartData: dashboardGuidesDonutChartData
    }
};
export const setDashboardUserEngagementData = (dashboardUserEngagementData: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_USER_ENGAGEMENT,
        dashboardUserEngagementData: dashboardUserEngagementData
    }
};

export const setDashboardAppsSummary = (dashboardAppsSummary: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_APPS_SUMMARY,
        dashboardAppsSummary: dashboardAppsSummary
    }
};

export const setDashboardTotalGuideCount = (dashboardTotalGuideCount: any) => {
    return {
        type: ActionTypes.SET_DASHBOARD_GUIDE_COUNT,
        dashboardTotalGuideCount: dashboardTotalGuideCount
    }
};



export const getDashboardData = (params: any, url_path: any, caseType: any,  apiErrorFlag?: string, firstTimeload?: boolean, optionSelection?:number ) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('dashboard/');
        
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axiosInstance.get(generatedURL, { cancelToken: source.token })
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "optionSelection": optionSelection ?? -1,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {

                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                        "errorCode":err?.response?.status ?? '',
                    };
                }
            }).then(() => {
                switch (caseType) {
                    case ActionTypes.SET_DASHBOARD_GUIDEME_CHART_DATA:
                        resolve(setDashboardGuideMeChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_SHOWME_CHART_DATA:
                        resolve(setDashboardShowMeChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_TESTME_CHART_DATA:
                        resolve(setDashboardTestMeChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_DO_IT_FOR_ME_CHART_DATA:
                        resolve(setDashboardDoItForMeChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_GUIDE_TUTORIALS_CHART_DATA:
                        resolve(setDashboardGuideTutorialsChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_TOOLTIP_CHART_DATA:
                        resolve(setDashboardTooltipChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_PUSHNOTIFICATION_CHART_DATA:
                        resolve(setDashboardPushnotificationChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_BEACON_CHART_DATA:
                        resolve(setDashboardBeaconChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_SURVEY_CHART_DATA:
                        resolve(setDashboardSurveyChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_VALIDATION_CHART_DATA:
                        resolve(setDashboardValidationChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_POWERHTML_CHART_DATA:
                        resolve(setDashboardPowerhtmlChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_DUCTTAPE_CHART_DATA:
                        resolve(setDashboardDucttapeChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_GUIDANCEVALIDATION_CHART_DATA:
                        resolve(setDashboardGuidancevalidationChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_GUIDANCE_CHART_DATA:
                        resolve(setDashboardGuidanceChart(data));
                        break;

                
                    case ActionTypes.SET_DASHBOARD_GUIDE_SUMMARY_TABLE_DATA:
                        resolve(setDashboardGuideSummaryTable(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_TOOLTIP_SUMMARY_TABLE_DATA:
                        resolve(setDashboardTooltipSummaryTable(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_GUIDES_TABLE_DATA:
                        resolve(setDashboardGuidesTable(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_WORKFLOWS_CHART_DATA:
                        resolve(setDashboardWorkflowChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_WORKFLOWS_TABLE_DATA:
                        resolve(setDashboardWorkflowsTable(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_SURVEYS_TABLE_DATA:
                        resolve(setDashboardSurveysTable(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_CONTRIES_CHART_DATA:
                        resolve(setDashboardCountriesChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_GUIDES_CHART_DATA:
                        resolve(setDashboardGuidesChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_GUIDES_DONUT_CHART_DATA:
                        resolve(setDashboardGuidesDonutChart(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_USER_ENGAGEMENT:
                        resolve(setDashboardUserEngagementData(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_APPS_SUMMARY:
                        resolve(setDashboardAppsSummary(data));
                        break;

                    case ActionTypes.SET_DASHBOARD_GUIDE_COUNT:
                        resolve(setDashboardTotalGuideCount(data));
                        break;
                    

                    default:
                        break;
                }
            });
    });
}